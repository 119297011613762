<template>
  <app-wrapper>
    <subpage-layout :title="'Ad Insights'">
      <div class="w-full flex flex-col space-y-3" v-if="SingleAdvert">
        <div class="w-full flex flex-col space-y-3">
          <!-- Ad media -->

          <div
            class="w-full flex flex-col items-center justify-center space-y-2 px-4"
          >
            <template v-if="SingleAdvert.type == 'banner'">
              <app-image-loader
                :photoUrl="SingleAdvert?.media?.metadata?.image_url || ''"
                class="w-full !h-[60px] rounded-[10px]"
              />
            </template>
            <app-image-loader
              v-else
              :photo-url="
                SingleAdvert.type == 'shoppable'
                  ? SingleAdvert.media?.metadata?.shoppable_type != 'product'
                    ? (SingleAdvert.media?.metadata?.image_url
                        ? SingleAdvert.media?.metadata?.image_url
                        : SingleAdvert.media?.shoplist?.base_image_url) || ''
                    : SingleAdvert.media?.metadata?.image_url || ''
                  : SingleAdvert?.media?.metadata?.image_url || ''
              "
              class="h-[200px] w-[130px] !rounded-[10px]"
            />

            <app-normal-text class="text-grey-500 w-full text-center">
              {{
                Logic.Common.fomartDate(
                  SingleAdvert.ad_insights.last_updated,
                  "MMMM D [at] h:mma"
                )
              }}
            </app-normal-text>
          </div>
        </div>

        <!-- Summary -->
        <div
          class="w-full flex flex-row space-x-5 justify-around items-center py-4 px-4 pt-4"
        >
          <div class="flex flex-col items-center space-y-1 justify-center">
            <app-icon :name="'black-heart'" class="!h-[15px]" />
            <app-normal-text class="!font-normal">{{
              Logic.Common.convertToMoney(
                SingleAdvert.ad_insights?.engagements.likes,
                false,
                ""
              )
            }}</app-normal-text>
          </div>

          <div class="flex flex-col items-center space-y-1 justify-center">
            <app-icon :name="'black-send'" class="!h-[15px]" />
            <app-normal-text class="!font-normal">{{
              Logic.Common.convertToMoney(
                SingleAdvert.ad_insights?.engagements.shares,
                false,
                ""
              )
            }}</app-normal-text>
          </div>

          <div
            class="flex flex-col items-center space-y-1 justify-center"
            v-if="SingleAdvert.type == 'shoppable'"
          >
            <app-icon :name="'black-shop'" class="!h-[16px]" />
            <app-normal-text class="!font-normal">{{
              Logic.Common.convertToMoney(
                (SingleAdvert.ad_insights?.engagements.purchases || 0) +
                  (SingleAdvert.ad_insights?.engagements.saves || 0),
                false,
                ""
              )
            }}</app-normal-text>
          </div>
        </div>

        <!-- Spacer -->
        <div class="w-full h-[4px] bg-gray-50 dark:!bg-gray-400"></div>

        <!-- Reach -->
        <div
          class="w-full flex flex-col items-center justify-center space-y-3 px-4"
        >
          <div class="w-full flex flex-row items-center justify-between">
            <app-header-text
              class="flex flex-row items-center space-x-2 !text-sm"
            >
              Reach
              <app-icon name="info-circle" class="h-[17px] ml-2"></app-icon>
            </app-header-text>
          </div>

          <div class="w-full flex flex-col items-center justify-center">
            <app-header-text class="text-center">
              {{
                Logic.Common.convertToMoney(
                  SingleAdvert.ad_insights?.reach.total,
                  false,
                  ""
                )
              }}
            </app-header-text>
            <app-normal-text class="text-center !text-gray-400 !text-sm">
              Total accounts reached so far
            </app-normal-text>
          </div>

          <div class="w-full flex flex-row item-center justify-between pt-1">
            <app-normal-text
              class="!font-semibold !text-grey-700 dark:!text-gray-400"
            >
              Impressions
            </app-normal-text>

            <app-normal-text class="">
              {{
                Logic.Common.convertToMoney(
                  SingleAdvert.ad_insights.reach.impressions,
                  false,
                  ""
                )
              }}
            </app-normal-text>
          </div>
        </div>

        <!-- Spacer -->
        <div class="w-full h-[4px] bg-gray-50 dark:!bg-gray-400"></div>

        <!-- Engagements -->
        <div
          class="w-full flex flex-col items-center justify-center space-y-3 px-4 pt-1"
        >
          <div class="w-full flex flex-row items-center justify-between">
            <app-header-text
              class="flex flex-row items-center space-x-2 !text-sm"
            >
              Engagements
              <app-icon name="info-circle" class="h-[17px] ml-2"></app-icon>
            </app-header-text>

            <app-header-text class="!text-sm">
              {{
                Logic.Common.convertToMoney(
                  SingleAdvert.ad_insights.engagements.total,
                  false,
                  ""
                )
              }}
            </app-header-text>
          </div>

          <div class="w-full flex flex-col space-y-3">
            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Clicks
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.clicks,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>
            <div
              class="w-full flex flex-row item-center justify-between pt-1"
              v-if="
                SingleAdvert.type == 'website_visit' ||
                SingleAdvert.type == 'banner'
              "
            >
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Website Visits
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.website_visits,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>
            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Likes
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.likes,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>

            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Shares
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.shares,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>

            <div
              class="w-full flex flex-row item-center justify-between pt-1"
              v-if="SingleAdvert.type == 'shoppable'"
            >
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Saved
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.saves,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>

            <div
              class="w-full flex flex-row item-center justify-between pt-1"
              v-if="SingleAdvert.type == 'shoppable'"
            >
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Purchases
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.purchases,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>

            <div
              class="w-full flex flex-row item-center justify-between pt-1"
              v-if="SingleAdvert.type == 'polls'"
            >
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Polls Completed
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights.engagements.polls_completed,
                    false,
                    ""
                  )
                }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="w-full h-[4px] bg-gray-50 dark:!bg-gray-400"></div>

        <!-- Ad -->
        <div
          class="w-full flex flex-col items-center justify-center space-y-3 px-4 pt-1"
        >
          <div class="w-full flex flex-row items-center justify-between">
            <app-header-text
              class="flex flex-row items-center space-x-2 !text-sm"
            >
              Ad
              <app-icon name="info-circle" class="h-[17px] ml-2"></app-icon>
            </app-header-text>
          </div>

          <div class="w-full flex flex-col items-center justify-center">
            <app-header-text class="text-center">
              {{
                Logic.Common.convertToMoney(
                  totalAdSummary.total_count,
                  false,
                  ""
                )
              }}
            </app-header-text>
            <app-normal-text class="text-center !text-gray-400 !text-sm">
              {{ totalAdSummary.comment }}
            </app-normal-text>
          </div>
          <div class="w-full flex flex-col space-y-3">
            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Amount Spent
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.ad_insights?.ad?.amount_spent,
                    false,
                    "ngn"
                  )
                }}
              </app-normal-text>
            </div>

            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Daily Ad Budget
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    SingleAdvert.adset?.maximum_cost,
                    false,
                    "ngn"
                  )
                }}
              </app-normal-text>
            </div>

            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Cost per
                {{
                  SingleAdvert?.adset?.bidding_strategy == "cpm"
                    ? "Impression"
                    : "Conversion"
                }}
              </app-normal-text>

              <app-normal-text class="">
                {{
                  Logic.Common.convertToMoney(
                    (SingleAdvert.ad_insights?.ad?.amount_spent || 0) /
                      (totalAdSummary.total_count > 0
                        ? totalAdSummary.total_count
                        : 1),
                    false,
                    "ngn"
                  )
                }}
              </app-normal-text>
            </div>

            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400"
              >
                Ad Type
              </app-normal-text>

              <app-normal-text class="">
                {{ capitalize(SingleAdvert.type?.split("_").join(" ") || "") }}
              </app-normal-text>
            </div>

            <div class="w-full flex flex-row item-center justify-between pt-1">
              <app-normal-text
                class="!font-semibold !text-grey-700 dark:!text-gray-400 !leading-tight"
              >
                Status <br />
                <span
                  class="!text-[10px] !text-grey-400 !font-normal"
                  v-if="SingleAdvert.adset?.run_until_paused"
                  >{{
                    Logic.Common.momentInstance(SingleAdvert.created_at).diff(
                      new Date(),
                      "hours"
                    )
                  }}
                  hours elapsed</span
                >
              </app-normal-text>

              <app-normal-text>
                {{
                  SingleAdvert.adset?.run_until_paused
                    ? "Run until paused"
                    : `Run until ${Logic.Common.fomartDate(
                        SingleAdvert.adset?.ad_end_date || "",
                        "MMMM D, YYYY"
                      )}`
                }}
              </app-normal-text>
            </div>
          </div>

          <div class="w-full flex flex-row items-center justify-between pt-4">
            <app-header-text
              class="flex flex-row items-center space-x-2 !text-xs !text-grey-800 !font-semibold"
            >
              Ad audience
              <app-icon name="info-circle" class="h-[15px] ml-2"></app-icon>
            </app-header-text>
          </div>

          <div class="w-full flex flex-col">
            <app-swiper
              :show-pagination="true"
              :free-mode="false"
              :space-between="5"
              :slide-per-view="1"
              :currentSlidePosition="currentSlidePosition"
              :swiperClass="''"
              v-model="slidePosition"
              id="swiperContainer"
            >
              <swiper-slide class="!h-[300px]">
                <div
                  class="w-full flex flex-col items-center space-y-3 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-3 py-4"
                >
                  <app-normal-text
                    class="w-full text-center !text-grey-800 dark:!text-gray-200"
                  >
                    Gender
                  </app-normal-text>
                  <div
                    class="w-full flex flex-row space-x-2 items-center justify-center h-full"
                  >
                    <div
                      class="w-full flex flex-col items-center justify-center"
                    >
                      <app-normal-text class="!font-bold">
                        {{ adAnalytics.gender.female }}%
                      </app-normal-text>
                      <app-normal-text
                        class="!text-grey-400 !text-[10px] flex flex-row space-x-1 items-center"
                      >
                        <span>Female</span>
                        <span
                          class="h-[5px] w-[5px] bg-secondary-500 rounded-full"
                        ></span>
                      </app-normal-text>
                    </div>
                    <div class="flex flex-col relative">
                      <div class="donut-chart" id="donutChart"></div>
                    </div>

                    <div
                      class="w-full flex flex-col items-center justify-center"
                    >
                      <app-normal-text class="!font-bold">
                        {{ adAnalytics.gender.male }}%
                      </app-normal-text>
                      <app-normal-text
                        class="!text-grey-400 !text-[10px] flex flex-row space-x-1 items-center"
                      >
                        <span>Male</span>
                        <span
                          class="h-[5px] w-[5px] bg-primary-400 rounded-full"
                        ></span>
                      </app-normal-text>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide class="!h-[300px]">
                <div
                  class="w-full flex flex-col items-center justify-center space-y-3 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-4 py-4"
                >
                  <app-normal-text
                    class="w-full text-center !text-grey-800 dark:!text-gray-200"
                  >
                    Age Range
                  </app-normal-text>

                  <div
                    class="w-full flex flex-col space-y-1 items-center h-full"
                  >
                    <div
                      class="w-full flex flex-col"
                      v-for="(item, index) in adAnalytics.age_range"
                      :key="index"
                    >
                      <app-normal-text
                        class="!text-left !text-[11px] !text-grey-800 dark:!text-gray-400"
                      >
                        {{ item.title }}
                      </app-normal-text>
                      <div
                        class="w-full flex flex-row items-center justify-between"
                      >
                        <div class="w-[80%] h-[7px] relative">
                          <div
                            class="absolute top-0 left-0 h-full bg-secondary-500 rounded-r-full"
                            :style="`width: ${item.percentage}%;`"
                          ></div>
                        </div>
                        <app-normal-text
                          class="!text-right !text-[12px] !font-semibold"
                        >
                          {{ item.percentage }}%
                        </app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide class="!h-[300px]">
                <div
                  class="w-full flex flex-col items-center justify-center space-y-2 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-4 py-4"
                >
                  <app-normal-text
                    class="w-full text-center !text-grey-800 dark:!text-gray-200"
                  >
                    Top Locations
                  </app-normal-text>

                  <div
                    class="w-full flex flex-col space-y-1 items-center h-full"
                  >
                    <div
                      class="w-full flex flex-col"
                      v-for="(item, index) in adAnalytics.locations"
                      :key="index"
                    >
                      <app-normal-text
                        class="!text-left !text-[11px] !text-grey-800 dark:!text-gray-400"
                      >
                        {{ item.title }}
                      </app-normal-text>
                      <div
                        class="w-full flex flex-row items-center justify-between"
                      >
                        <div class="w-[80%] h-[7px] relative">
                          <div
                            class="absolute top-0 left-0 h-full bg-secondary-500 rounded-r-full"
                            :style="`width: ${item.percentage}%;`"
                          ></div>
                        </div>
                        <app-normal-text
                          class="!text-right !text-[12px] !font-semibold"
                        >
                          {{ item.percentage }}%
                        </app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </app-swiper>
          </div>
        </div>

        <!-- Large Spacer -->
        <div class="h-[50px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppHeaderText,
  AppImageLoader,
  AppIcon,
  AppSwiper,
} from "@shpt/ui-components";
import { SwiperSlide } from "swiper/vue";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    SwiperSlide,
    AppNormalText,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
    AppSwiper,
    AppWrapper,
  },
  name: "AdInsightsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Ad",
        property: "SingleAdvert",
        method: "GetSingleAdvert",
        params: [],
        useRouteId: true,
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Ad Insights",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Ad Insights",
    });

    const SingleAdvert = ref(Logic.Ad.SingleAdvert);

    const currentSlidePosition = ref(0);
    const slidePosition = ref(0);

    const totalAdSummary = computed(() => {
      let totalValueCount = 0;
      let comment = "";

      if (SingleAdvert.value?.adset?.bidding_strategy == "cpm") {
        totalValueCount =
          SingleAdvert.value?.ad_insights?.reach.impressions || 0;
        comment = "Total ad impressions";
      } else {
        const adType = SingleAdvert.value?.type;

        if (adType == "shoppable") {
          totalValueCount =
            (SingleAdvert.value?.ad_insights.engagements.saves || 0) +
            (SingleAdvert.value?.ad_insights.engagements?.purchases || 0);
          comment = "Total shoplist saves and purchases";
        }

        if (adType == "website_visit") {
          totalValueCount =
            SingleAdvert.value?.ad_insights.engagements.clicks || 0;
          comment = "Total clicks to view website";
        }

        if (adType == "polls") {
          totalValueCount =
            SingleAdvert.value?.ad_insights.engagements.polls_completed || 0;
          comment = "Total polls completed";
        }

        if (adType == "banner") {
          totalValueCount =
            SingleAdvert.value?.ad_insights.engagements.clicks || 0;
          comment = "Total clicks to view website";
        }
      }

      return {
        total_count: totalValueCount,
        comment,
      };
    });

    const chartColor = reactive({
      male: "#8333B3",
      female: "#FFA700",
    });

    const adAnalytics = reactive<{
      gender: {
        male: number;
        female: number;
      };
      age_range: Array<{
        title: string;
        percentage: number;
      }>;
      locations: Array<{
        title: string;
        percentage: number;
      }>;
    }>({
      gender: {
        male: 0,
        female: 0,
      },
      age_range: [
        {
          title: "18 to 24",
          percentage: 0,
        },
        {
          title: "25 to 34",
          percentage: 0,
        },
        {
          title: "35 to 44",
          percentage: 0,
        },
        {
          title: "45 to 54",
          percentage: 0,
        },
        {
          title: "55 to 64",
          percentage: 0,
        },
        {
          title: "65 and above",
          percentage: 0,
        },
      ],
      locations: [],
    });

    const setAdStats = () => {
      // Gender
      const allUsers =
        (SingleAdvert.value?.ad_insights.ad.audience.gender.female || 0) +
        (SingleAdvert.value?.ad_insights.ad.audience.gender.male || 0);

      adAnalytics.gender = {
        male: parseFloat(
          (allUsers > 0
            ? Math.round(
                ((SingleAdvert.value?.ad_insights.ad.audience.gender.male ||
                  0) /
                  allUsers) *
                  100
              )
            : 0
          ).toFixed(2)
        ),
        female: parseFloat(
          (allUsers > 0
            ? Math.round(
                ((SingleAdvert.value?.ad_insights.ad.audience.gender.female ||
                  0) /
                  allUsers) *
                  100
              )
            : 0
          ).toFixed(2)
        ),
      };

      // Age ranges
      const totalUserCounts =
        SingleAdvert.value?.ad_insights.ad.audience.age_ranges.reduce(
          (sum, range) => sum + (range.count || 0),
          0
        ) || 0;
      SingleAdvert.value?.ad_insights.ad.audience.age_ranges.forEach((item) => {
        const ageRange = adAnalytics.age_range.find(
          (range) => range.title === item.title
        );
        if (ageRange) {
          ageRange.percentage = parseFloat(
            (totalUserCounts > 0
              ? Math.round(((item.count || 0) / totalUserCounts) * 100)
              : 0
            ).toFixed(2)
          );
        }
      });

      const totalLocationCounts =
        SingleAdvert.value?.ad_insights.ad.audience.locations.reduce(
          (sum, range) => sum + (range.count || 0),
          0
        ) || 0;
      // Locations
      adAnalytics.locations.length = 0;
      SingleAdvert.value?.ad_insights.ad.audience.locations.forEach((item) => {
        adAnalytics.locations.push({
          title: item.title,
          percentage: parseFloat(
            (totalLocationCounts > 0
              ? Math.round(((item.count || 0) / totalLocationCounts) * 100)
              : 0
            ).toFixed(2)
          ),
        });
      });
    };

    const setDonutChart = () => {
      document
        .getElementById("donutChart")
        ?.style.setProperty("--percentage1", adAnalytics.gender.male + "%");
      document
        .getElementById("donutChart")
        ?.style.setProperty("--color1", chartColor.male);
      document
        .getElementById("donutChart")
        ?.style.setProperty("--color2", chartColor.female);
      document
        .getElementById("donutChart")
        ?.style.setProperty(
          "--pieColor",
          Logic.Common.currentAppTheme() == "light" ? "#ffffff" : "#000000"
        );
    };

    const setPageSettings = () => {
      setAdStats();
      setTimeout(() => {
        setDonutChart();
      }, 500);
    };

    onIonViewWillEnter(() => {
      setPageSettings();
    });

    onMounted(() => {
      Logic.Ad.watchProperty("SingleAdvert", SingleAdvert);
      setPageSettings();

      if (Logic.Common.currentBuildType() == "web") {
        window.scrollTo({
          top: 0,
        });
      }
    });

    return {
      Logic,
      currentSlidePosition,
      slidePosition,
      adAnalytics,
      SingleAdvert,
      totalAdSummary,
      capitalize,
    };
  },
});
</script>
<style scoped>
.donut-chart {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: conic-gradient(
    var(--color1) var(--percentage1),
    var(--color2) var(--percentage1)
  );
  position: relative;
}
.donut-chart::before {
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--pieColor);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.legend {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.legend-item {
  display: flex;
  align-items: center;
}
.color-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
</style>
