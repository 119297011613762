<template>
  <app-wrapper>
    <subpage-layout :title="title" :goBackHandler="goBackHandler">
      <template v-slot:extra-topbar>
        <app-normal-text :custom-class="'!text-primary-400 '"
          >{{
            "Step " + `(${onboardingStep.current + "/" + onboardingStep.total})`
          }}
        </app-normal-text>
      </template>
      <template v-slot:extra-topbar-row>
        <div
          :class="`${
            onboardingStep.current == onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
        >
          <div
            v-for="(item, index) in onboardingStep.total"
            :key="item"
            :class="`${
              onboardingStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>
      <app-form-wrapper
        :parentRefs="parentRefs"
        class="w-full flex flex-col space-y-2 px-4"
        ref="formComp"
      >
        <!-- Step 1 -->
        <div
          class="w-full flex flex-col space-y-3 pt-4"
          v-if="onboardingStep.current == 1"
        >
          <AppTextField
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Business Name'"
            :focus-border="'border-primary-400'"
            :rules="[FormValidations.RequiredRule]"
            required
            ref="business_name"
            name="Business name"
            v-model="formData.name"
          >
            <template v-slot:title>Business Name</template>
            <template v-slot:inner-prefix>
              <span class="pr-1">
                <app-icon :name="'shopping-bag'" :customClass="'h-[16px]'" />
              </span>
            </template>
          </AppTextField>

          <!-- User name -->
          <div class="w-full flex flex-col space-y-2">
            <app-text-field
              :placeholder="'Enter handle'"
              :hasTitle="true"
              :type="'text'"
              ref="usernameRef"
              v-model="username"
              :name="'Username'"
              :rules="[
                FormValidations.RequiredRule,
                FormValidations.customValidator(
                  username.indexOf(' ') <= 0,
                  'Handle cannot contain white space'
                ),
                FormValidations.Alphanumeric,
              ]"
              :update-value="username"
              :content-rule="{
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              }"
            >
              <template v-slot:inner-prefix>
                <app-normal-text> @ </app-normal-text>
              </template>
              <template v-slot:title> Business Handle </template>
            </app-text-field>
            <div class="w-full flex flex-row justify-end">
              <app-normal-text
                :color="'text-primary-main'"
                v-if="resolvingUsername"
              >
                Resolving handle...
              </app-normal-text>
              <template
                v-if="
                  !resolvingUsername &&
                  username != AuthUser?.profile?.business?.business_tag &&
                  username
                "
              >
                <app-normal-text
                  :color="'text-success-main'"
                  v-if="!usernameExist"
                >
                  <span class="px-1">Available</span> ✅
                </app-normal-text>
                <app-normal-text :color="'text-error-main'" v-else>
                  <span class="px-1">Not available</span> ❌
                </app-normal-text>
              </template>
            </div>
          </div>

          <AppSelect
            :has-title="true"
            :placeholder="'Select account type'"
            :paddings="'px-3 py-3'"
            :options="accountType"
            ref="account_type"
            v-model="formData.account_type"
          >
            <template v-slot:title> Account Type </template>
            <template v-slot:inner-prefix>
              <span class="pr-1">
                <app-icon :name="'grid'" :customClass="'h-[16px]'" />
              </span>
            </template>
          </AppSelect>

          <!-- <div
            class="w-full flex flex-col pt-1"
            v-if="formData.account_type == 'individual'"
          >
            <div class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-purple-50">
              <app-normal-text class="!flex !flex-row !text-center !space-x-2">
                <span class="text-left !text-gray-700">
                  <span class="!font-extrabold !text-black">Individual Shop Account</span>
                  can only process a limited sales volume of
                  <span class="font-bold !text-black">NGN 100,000 </span> a day. Choose
                  <span class="font-bold !text-black"> Business Shop Account</span>
                  if you want to be able to process unlimited sales volume.
                </span>
              </app-normal-text>
            </div>
          </div> -->
        </div>

        <!-- Step 2 -->
        <div
          class="w-full flex flex-col space-y-3 pt-4"
          v-if="onboardingStep.current == 2"
        >
          <div class="w-full flex flex-col space-y-1">
            <app-normal-text class="!font-semibold text-left">
              {{
                formData.account_type == "individual"
                  ? "Display Picture"
                  : "Business Logo"
              }}
            </app-normal-text>
            <div class="flex flex-col space-y-2 items-center justify-center">
              <div
                :style="`background-image: url(${photoUrl});  background-size: cover;
             background-repeat: no-repeat;
             background-position: center;`"
                class="h-[85px] w-[85px] rounded-full bg-gray-100"
              ></div>

              <app-file-attachment
                :is-wrapper="true"
                v-model="formData.business_logo"
                v-model:local-file-url="photoUrl"
                :accept="`image/png, image/gif, image/jpeg`"
              >
                <template v-slot:content>
                  <div
                    class="w-full flex flex-row items-center justify-center px-3 py-3 space-x-2 border-[1px] border-[#5A189A] rounded-full cursor-pointer"
                  >
                    <app-icon
                      :name="'image-purple'"
                      :customClass="'h-[15px]'"
                    />
                    <app-normal-text :color="'text-[#5A189A]'">
                      Upload
                    </app-normal-text>
                  </div>
                </template>
              </app-file-attachment>
            </div>
          </div>

          <app-text-field
            :has-title="true"
            is-textarea
            :custom-class="''"
            :placeholder="
              formData.account_type == 'individual'
                ? 'Enter Bio'
                : 'About Business'
            "
            :focus-border="'border-primary-400'"
            :name="
              formData.account_type == 'individual' ? 'Bio' : 'About Business'
            "
            ref="about_business"
            v-model="formData.about_business"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                formData.about_business.length <= 250,
                'Maximum of 250 Characters'
              ),
            ]"
            :max-character="250"
            required
          >
            <template v-slot:title>
              {{
                formData.account_type == "individual" ? "Bio" : "About Business"
              }}
            </template>
          </app-text-field>

          <app-text-field
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Enter Phone Number'"
            ref="phone_number"
            :focus-border="'border-primary-400'"
            v-model="formData.business_phone_number"
            :rules="[FormValidations.RequiredRule]"
            name="Phone number"
            type="tel"
            required
          >
            <template v-slot:title>
              {{
                formData.account_type == "individual"
                  ? "Phone Number"
                  : "Business Phone Number"
              }}</template
            >
          </app-text-field>

          <div class="w-full flex flex-col space-y-3 pt-3">
            <app-normal-text
              class="!font-semibold w-full border-b-[1px] border-gray-200 pb-2"
            >
              Social Media
            </app-normal-text>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'username'"
              ref="facebook"
              name="Facebook"
              :focus-border="'border-primary-400'"
              v-model="formData.facebook"
            >
              <template v-slot:title> Facebook </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'">
                  facebook.com/
                </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'username'"
              ref="instagram"
              name="Instagram"
              :focus-border="'border-primary-400'"
              v-model="formData.instagram"
            >
              <template v-slot:title> Instagram </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'">
                  instagram.com/
                </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'0000000000'"
              :focus-border="'border-primary-400'"
              ref="whatsapp"
              name="whatsapp"
              type="tel"
              v-model="formData.whatsapp"
            >
              <template v-slot:title> Whatsapp </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'">
                  wa.me/
                </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'https://'"
              ref="website"
              name="website"
              :focus-border="'border-primary-400'"
              :rules="[FormValidations.WebsiteRule]"
              v-model="formData.website"
            >
              <template v-slot:title> Website </template>
            </AppTextField>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>

        <!-- Bottom section -->
        <fixed-container>
          <div class="w-full flex flex-col pt-4 bg-white dark:!bg-black">
            <AppButton
              :padding="'py-3'"
              :bg-color="'bg-primary-400'"
              :text-color="'text-white'"
              :class="`w-full ${formItemIsValid ? '' : 'opacity-[50%]'}`"
              @click.prevent="moveForward()"
            >
              Continue
            </AppButton>
          </div>
        </fixed-container>
      </app-form-wrapper>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppTextField,
  AppSelect,
  AppIcon,
  AppButton,
  AppFileAttachment,
  AppFormWrapper,
} from "@shpt/ui-components";
import { SelectOption } from "@shpt/ui-components/src/types";
import { FormValidations } from "@shpt/ui-components/src/composable";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { authMoveForward } from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppTextField,
    AppSelect,
    AppIcon,
    AppButton,
    AppFileAttachment,
    AppFormWrapper,
    AppWrapper,
    FixedContainer,
  },
  name: "MerchantOnboardingPage",
  layout: "Subpage",
  middlewares: {
    tracking_data: {
      lable: "Merchant Onboarding Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [],
  },
  setup() {
    useMeta({
      title: "Merchant Onboarding",
    });

    const photoUrl = ref("");

    const onboardingStep = reactive({
      current: 1,
      total: 2,
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const username = ref("");

    const usernameExist = ref(false);

    const resolvingUsername = ref(false);

    const formComp = ref();

    const documentName = ref("");

    const title = ref("Setup Account");

    const accountName = ref("");
    const accountResolved = ref(true);

    const aboutBusiness = ref("");

    const settlementData = reactive({
      account_no: "",
      bank_code: "",
    });

    const formData = reactive({
      name: "",
      handle: "",
      account_type: "business",
      verification_type: "",
      business_verification_documents: [],
      document_type: "business_name",
      settlement_bank_code: "",
      settlement_account_number: "",
      facebook: "",
      instagram: "",
      whatsapp: "",
      website: "",
      account_manager: {
        first_name: "",
        last_name: "",
        verification_number: "",
        verification_type: "nin",
      },
      business_logo: "",
      about_business: "",
      business_phone_number: "",
    });

    const formItemIsValid = computed(() => {
      if (onboardingStep.current == 1) {
        return (
          formData.name != "" &&
          username.value != "" &&
          usernameExist.value == false &&
          formData.account_type != ""
        );
      } else if (onboardingStep.current == 2) {
        return (
          formData.business_logo != "" &&
          formData.business_phone_number != "" &&
          formData.about_business != ""
        );
      }

      return false;
    });

    const accountType = reactive<SelectOption[]>([
      {
        key: "business",
        value: "Business",
      },
      {
        key: "individual",
        value: "Creator",
      },
    ]);

    const goBackHandler = () => {
      if (onboardingStep.current <= 1) {
        Logic.Common.goBack();
      } else {
        onboardingStep.current--;
      }
    };

    const next = () => {
      if (onboardingStep.current < onboardingStep.total) {
        onboardingStep.current += 1;
        return;
      }
    };

    const saveProfileDetails = () => {
      // save profile info
      Logic.User.UpdateBusinessProfileForm = {
        account_type: formData.account_type,
        business_logo: formData.business_logo,
        phone_number: formData.business_phone_number,
        description: formData.about_business,
        business_name: formData.name,
        business_tag: username.value,
        business_social_links: {
          facebook: formData.facebook,
          instagram: formData.instagram,
          whatsapp: formData.whatsapp,
          website: formData.website,
        },
      };
      Logic.User.UpdateBusinessProfile()?.then(async (data) => {
        if (data?.UpdateBusinessProfile) {
          Logic.Common.showLoader({
            show: true,
            loading: true,
          });
          Logic.Auth.GetAuthUser()?.then(async (data) => {
            if (data) {
              Logic.Common.hideLoader();
              authMoveForward(data?.GetAuthUser);
            }
          });
        }
      });
    };

    const askForNotification = () => {
      localStorage.setItem("onboarding_notification_prompted", "true");
      Logic.Common.showModal({
        show: true,
        title: "Get Notified",
        type: "ask_for_permission",
        extraData: {
          info: "Be the first to know about top cashback deals and rewards",
          cta_copy: "Enable Notification",
        },
        action: async () => {
          try {
            await Logic.Notification.registerNotifications();
          } catch (error) {
            //
          }

          Logic.Common.showModal({ show: false });
          saveProfileDetails();
        },
      });
    };

    const moveForward = async () => {
      formComp.value.validate();
      if (formItemIsValid.value) {
        if (onboardingStep.current == 2) {
          if (Logic.Common.currentBuildType() == "mobile") {
            askForNotification();
          } else {
            saveProfileDetails();
          }
          return;
        }

        next();
      }
    };

    watch(onboardingStep, () => {
      if (onboardingStep.current == 1) {
        title.value = "Setup Account";
      }
      if (onboardingStep.current == 2) {
        title.value =
          formData.account_type == "individual"
            ? "Creator Profile"
            : "Business Profile";
      }
    });

    watch(username, () => {
      Logic.Common.debounce(() => {
        if (AuthUser.value?.profile?.business?.business_tag != username.value) {
          if (username.value.length < 4) {
            usernameExist.value = true;
            return;
          }
          resolvingUsername.value = true;
          usernameExist.value = true;
          Logic.Auth.CheckIfUsernameExist(username.value).then((data) => {
            if (data) {
              usernameExist.value = data.data?.CheckIfUsernameExist || false;
            }
            resolvingUsername.value = false;
          });
        } else {
          usernameExist.value = false;
        }
      });
    });

    const setDefault = () => {
      if (AuthUser.value?.profile?.business?.account_type) {
        onboardingStep.current = 2;
      }
    };

    const handleBusinessSelection = (type: "existing" | "new") => {
      if (type == "existing") {
        Logic.Common.showModal({
          show: true,
          title: "Join Shop",
          type: "join_business",
          action: (data: any) => {
            Logic.User.AcceptBusinessInvitation(data.code)?.then((data) => {
              if (data) {
                Logic.Auth.GetAuthUser()?.then(async (data) => {
                  if (data) {
                    Logic.Common.hideLoader();
                    window.location.href = "/";
                  }
                });
              }
            });
          },
        });
      } else {
        //
      }
    };

    onIonViewWillEnter(() => {
      setDefault();
    });

    onMounted(() => {
      setDefault();
    });

    return {
      onboardingStep,
      Logic,
      accountType,
      formData,
      title,
      documentName,
      FormValidations,
      photoUrl,
      aboutBusiness,
      formComp,
      formItemIsValid,
      username,
      usernameExist,
      resolvingUsername,
      AuthUser,
      settlementData,
      accountName,
      accountResolved,
      goBackHandler,
      moveForward,
      handleBusinessSelection,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
