import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row justify-center items-center h-[100px]"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex justify-between w-full" }
const _hoisted_6 = { class: "flex justify-between w-full" }
const _hoisted_7 = { class: "flex justify-between w-full" }
const _hoisted_8 = { class: "h-[20px] w-[20px] rounded-full bg-grey-50 flex items-center justify-center" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[200px] px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Users' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAdAdmin())),
                class: "w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
              }, [
                _createVNode(_component_app_normal_text, { class: "text-center text-gray-700" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" + New User Invitation ")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.merchantAdminIsLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_app_loading_state)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.merchantAdminIsLoading)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.merchantsAdmins, (data, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer",
                      onClick: ($event: any) => (_ctx.showAdminInfo(data.data))
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_AppNormalText, { class: "!font-semibold !line-clamp-1" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_app_badge, {
                          color: data.status == 'active' ? 'green' : 'red',
                          class: _normalizeClass('capitalize')
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.status), 1)
                          ]),
                          _: 2
                        }, 1032, ["color"])
                      ]),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_AppNormalText, {
                          color: 'text-grey-700',
                          class: "!text-[12px]",
                          "custom-class": 'gap-2'
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" Joined on ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_AppNormalText, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.joined_on), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_AppNormalText, {
                          color: 'text-grey-700',
                          class: "!text-[12px]",
                          "custom-class": 'gap-2'
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" Permissions ")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_8, [
                          _createVNode(_component_app_icon, {
                            name: 'show-more',
                            class: "!h-[5px]"
                          })
                        ])
                      ])
                    ], 8, _hoisted_4))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            (_ctx.merchantsAdmins.length == 0 && !_ctx.merchantAdminIsLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_app_empty_state, {
                    title: 'No Users Found',
                    "sub-title": 'You can invite your users by clicking the button above.'
                  })
                ]))
              : _createCommentVNode("", true),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}