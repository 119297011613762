<template>
  <app-wrapper>
    <subpage-layout :title="'Accept Payment'">
      <div class="w-full flex flex-col space-y-4 h-full">
        <template v-if="showQrCode">
          <div
            class="w-full flex flex-col h-full items-center min-h-[500px] justify-center px-4 space-y-2"
          >
            <app-header-text class="text-center !text-2xl !text-primary-700">
              Scan To Pay
            </app-header-text>

            <div
              class="w-[300px] h-[300px] max-w-full bg-primary-main rounded-[10px] px-3 py-3"
            >
              <div class="w-full flex flex-col h-full">
                <app-qrcode
                  :qrcodeValue="JSON.stringify(businessPaymentInfo())"
                  class="h-full w-full"
                />
              </div>
            </div>

            <app-normal-text
              class="w-[70%] text-center !text-gray-600 dark:!text-gray-400"
            >
              Scan this code with your Shoppoint App to pay
            </app-normal-text>
          </div>
        </template>

        <template v-else>
          <div
            class="w-full flex flex-col h-full items-center min-h-[500px] justify-center space-y-4"
          >
            <app-normal-text
              class="text-center w-full !text-gray-600 dark:!text-gray-300"
            >
              Enter amount
            </app-normal-text>
            <app-header-text class="text-center !text-xl !text-primary-700">
              {{
                Logic.Common.convertToMoney(
                  parseFloat(amountToPay).toFixed(2),
                  false,
                  "ngn"
                )
              }}
            </app-header-text>

            <div
              class="w-full flex flex-col items-center justify-center px-4 space-y-2 pt-6"
            >
              <app-keyboard v-model="amountToPay" :isNumber="true" />
            </div>

            <div class="w-full flex flex-col space-y-2 px-4 pt-5">
              <app-button
                @click="handleConfirm"
                customClass="w-full"
                :class="`py-3 ${
                  parseFloat(amountToPay) > 50 ? 'opacity-100' : 'opacity-50'
                }`"
              >
                Confirm
              </app-button>
            </div>
          </div>
        </template>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppHeaderText,
  AppQrcode,
  AppKeyboard,
  AppButton,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";
import { onIonViewWillEnter } from "@ionic/vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppQrcode,
    AppHeaderText,
    AppWrapper,
    AppKeyboard,
    AppButton,
  },
  name: "MerchantAcceptPaymentPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Merchant Scan To Pay Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Scan To Pay",
    });

    const amountToPay = ref("0");
    const showQrCode = ref(false);

    const businessPaymentInfo = (): {
      name: string;
      id: string;
      photo_url: string;
      user_uuid: string;
      username: string;
      type: "business";
      amount: string;
    } => {
      const business = Logic.Auth.GetCurrentBusiness();
      return {
        id: business?.business_tag || "",
        name: business?.business_name || "",
        photo_url: business?.photo_url || "",
        user_uuid: business?.uuid || "",
        username: business?.business_name || "",
        type: "business",
        amount: amountToPay.value,
      };
    };

    const handleConfirm = () => {
      if (parseFloat(amountToPay.value) > 50) {
        showQrCode.value = true;
      }
    };

    onIonViewWillEnter(() => {
      amountToPay.value = "0";
      showQrCode.value = false;
    });

    onMounted(() => {
      amountToPay.value = "0";
      showQrCode.value = false;
    });

    return {
      Logic,
      amountToPay,
      showQrCode,
      businessPaymentInfo,
      handleConfirm,
    };
  },
});
</script>
