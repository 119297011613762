<template>
  <app-wrapper>
    <subpage-layout :title="'Edit Profile'">
      <div class="w-full flex flex-col space-y-2 flex-grow px-4">
        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-4 pt-3"
        >
          <div class="w-full flex flex-col space-y-1">
            <app-normal-text class="!font-semibold text-left">
              Business Logo
            </app-normal-text>
            <div class="flex flex-col space-y-2 items-center justify-center w-full">
              <app-file-attachment
                :is-wrapper="true"
                v-model="profileUpdateData.business_logo"
                v-model:local-file-url="photoUrl"
                :accept="`image/png, image/gif, image/jpeg`"
                class="flex flex-row items-center justify-center !w-auto cursor-pointer"
              >
                <template v-slot:content>
                  <div
                    :style="`background-image: url(${photoUrl});  background-size: cover;
               background-repeat: no-repeat;
               background-position: center;`"
                    class="h-[85px] w-[85px] rounded-full bg-gray-100 flex flex-row items-center justify-center"
                  >
                    <app-icon :name="'image-purple'" :customClass="'h-[15px]'" />
                  </div>
                </template>
              </app-file-attachment>
            </div>
          </div>

          <AppTextField
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Business Name'"
            :focus-border="'border-primary-400'"
            :rules="[FormValidations.RequiredRule]"
            required
            name="Business name"
            v-model="profileUpdateData.name"
            :update-value="profileUpdateData.name"
          >
            <template v-slot:title>Business Name</template>
            <template v-slot:inner-prefix>
              <span class="pr-1">
                <app-icon :name="'shopping-bag'" :customClass="'h-[16px]'" />
              </span>
            </template>
          </AppTextField>

          <!-- Business handle -->
          <div class="w-full flex flex-col space-y-2">
            <app-text-field
              :placeholder="'Business Handle'"
              :hasTitle="true"
              :type="'text'"
              ref="usernameRef"
              v-model="username"
              :name="'Handle'"
              :rules="[
                FormValidations.RequiredRule,
                FormValidations.customValidator(
                  username.indexOf(' ') <= 0,
                  'Handle cannot contain white space'
                ),
                FormValidations.Alphanumeric,
              ]"
              :update-value="username"
              :content-rule="{
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              }"
              required
            >
              <template v-slot:inner-prefix>
                <app-normal-text> @ </app-normal-text>
              </template>
              <template v-slot:title> Business Handle </template>
            </app-text-field>
            <div class="w-full flex flex-row justify-end">
              <app-normal-text :color="'text-primary-main'" v-if="resolvingUsername">
                Resolving handle...
              </app-normal-text>
              <template
                v-if="!resolvingUsername && username != AuthUser?.username && username"
              >
                <app-normal-text :color="'text-success-main'" v-if="!usernameExist">
                  <span class="px-1">Available</span> ✅
                </app-normal-text>
                <app-normal-text :color="'text-error-main'" v-else>
                  <span class="px-1">Not available</span> ❌
                </app-normal-text>
              </template>
            </div>
          </div>

          <AppTextField
            :has-title="true"
            is-textarea
            :custom-class="''"
            :placeholder="'About this business'"
            :focus-border="'border-primary-400'"
            v-model="profileUpdateData.about_business"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                profileUpdateData.about_business.length <= 160,
                'Maximum of 160 Characters'
              ),
            ]"
            :max-character="160"
            required
            :update-value="profileUpdateData.about_business"
          >
            <template v-slot:title> About Business </template>
          </AppTextField>

          <AppTextField
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Enter Phone Number'"
            :focus-border="'border-primary-400'"
            v-model="profileUpdateData.business_phone_number"
            :rules="[FormValidations.RequiredRule]"
            name="Phone number"
            type="tel"
            required
            :update-value="profileUpdateData.business_phone_number"
          >
            <template v-slot:title>Business Phone Number</template>
          </AppTextField>

          <div class="w-full flex flex-col space-y-3 pt-3">
            <app-normal-text
              class="!font-semibold w-full border-b-[1px] border-gray-200 pb-2"
            >
              Social Media
            </app-normal-text>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'username'"
              :focus-border="'border-primary-400'"
              v-model="profileUpdateData.facebook"
              :update-value="profileUpdateData.facebook"
            >
              <template v-slot:title> Facebook </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'">
                  facebook.com/
                </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'username'"
              :focus-border="'border-primary-400'"
              v-model="profileUpdateData.instagram"
              :update-value="profileUpdateData.instagram"
            >
              <template v-slot:title> Instagram </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'">
                  instagram.com/
                </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'0000000000'"
              :focus-border="'border-primary-400'"
              v-model="profileUpdateData.whatsapp"
              typw="tel"
              :update-value="profileUpdateData.whatsapp"
            >
              <template v-slot:title> Whatsapp </template>
              <template v-slot:inner-prefix>
                <AppNormalText :custom-class="'text-grey-700'"> wa.me/ </AppNormalText>
              </template>
            </AppTextField>

            <AppTextField
              :has-title="true"
              :custom-class="''"
              :placeholder="'https://'"
              :focus-border="'border-primary-400'"
              :rules="[FormValidations.WebsiteRule]"
              v-model="profileUpdateData.website"
              :update-value="profileUpdateData.website"
            >
              <template v-slot:title> Website </template>
            </AppTextField>
          </div>
        </app-form-wrapper>

        <div class="!h-[100px]"></div>
      </div>

      <!-- Continue button -->
      <fixed-container>
        <app-button
          :customClass="`w-full ${usernameExist ? '!opacity-60' : ''}`"
          :padding="'py-3 xs:py-3'"
          @click="saveProfile()"
          :loading="loaderSetup.loading"
        >
          Save
        </app-button>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { FormValidations, tabTitle } from "@shpt/ui-components/src/composable";
import { onIonViewWillEnter } from "@ionic/vue";
import {
  AppTextField,
  AppIcon,
  AppButton,
  AppNormalText,
  AppFileAttachment,
  AppFormWrapper,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppIcon,
    AppButton,
    AppNormalText,
    AppFileAttachment,
    AppFormWrapper,
    AppWrapper,
    FixedContainer,
  },
  name: "EditBusinessProfilePage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Edit Business Profile Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Edit Business Profile",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const photoUrl = ref("");

    const username = ref("");

    const formComp = ref();

    const usernameExist = ref(false);

    const resolvingUsername = ref(false);

    const profileUpdateData = reactive({
      business_logo: "",
      name: "",
      about_business: "",
      username: "",
      business_phone_number: "",
      facebook: "",
      instagram: "",
      whatsapp: "",
      website: "",
    });

    const setDefault = () => {
      profileUpdateData.facebook =
        AuthUser.value?.profile?.business?.business_social_links?.facebook || "";
      profileUpdateData.instagram =
        AuthUser.value?.profile?.business?.business_social_links?.instagram || "";
      profileUpdateData.whatsapp =
        AuthUser.value?.profile?.business?.business_social_links?.whatsapp || "";
      profileUpdateData.website =
        AuthUser.value?.profile?.business?.business_social_links?.website || "";

      profileUpdateData.name = AuthUser.value?.profile?.business?.business_name || "";
      profileUpdateData.about_business =
        AuthUser.value?.profile?.business?.description || "";
      profileUpdateData.business_phone_number =
        AuthUser.value?.profile?.business?.phone_number || "";
      profileUpdateData.username = AuthUser.value?.profile?.business?.business_tag || "";
      photoUrl.value = AuthUser.value?.profile?.business?.photo_url || "";

      username.value = AuthUser.value?.profile?.business?.business_tag || "";
    };

    const saveProfile = () => {
      if (!usernameExist.value) {
        Logic.User.UpdateBusinessProfileForm = {
          business_name: profileUpdateData.name,
          business_tag: username.value,
          description: profileUpdateData.about_business,
          phone_number: profileUpdateData.business_phone_number,
          business_social_links: {
            facebook: profileUpdateData.facebook,
            instagram: profileUpdateData.instagram,
            whatsapp: profileUpdateData.whatsapp,
            website: profileUpdateData.website,
          },
          business_logo: profileUpdateData.business_logo
            ? profileUpdateData.business_logo
            : undefined,
        };
        Logic.User.UpdateBusinessProfile()?.then(async (data) => {
          if (data?.UpdateBusinessProfile) {
            await Logic.Wallet.GetBusinessWallet();
            Logic.Common.showAlert({
              show: true,
              message: "Profile updated successfully",
              type: "success",
            });
            Logic.Auth.GetAuthUser();
            Logic.Common.goBack();
          }
        });
      }
    };

    watch(username, () => {
      Logic.Common.debounce(() => {
        if (AuthUser.value?.username != username.value) {
          if (username.value.length < 4) {
            usernameExist.value = true;
            return;
          }
          resolvingUsername.value = true;
          usernameExist.value = true;
          Logic.Auth.CheckIfUsernameExist(username.value).then((data) => {
            if (data) {
              usernameExist.value = data.data?.CheckIfUsernameExist || false;
            }
            resolvingUsername.value = false;
          });
        } else {
          usernameExist.value = false;
        }
      });
    });

    onIonViewWillEnter(() => {
      setDefault();
    });

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      setDefault();
    });

    return {
      FormValidations,
      profileUpdateData,
      photoUrl,
      username,
      resolvingUsername,
      usernameExist,
      formComp,
      AuthUser,
      loaderSetup,
      saveProfile,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
