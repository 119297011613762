import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
}
const _hoisted_6 = { class: "w-full flex flex-col sticky bottom-0 left-0 pb-4 md:!pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_form_wrapper, {
      ref: "formComp",
      parentRefs: _ctx.parentRefs,
      class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0 relative"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_app_text_field, {
          placeholder: 'Enter email',
          rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.EmailRule],
          modelValue: _ctx.formData.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
          hasTitle: true,
          type: "email",
          padding: 'py-3 px-4',
          name: "Email Address",
          ref: "friend_email"
        }, {
          title: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Email Address ")
          ])),
          _: 1
        }, 8, ["rules", "modelValue"]),
        _createVNode(_component_app_select, {
          placeholder: 'Select user type',
          hasTitle: true,
          rules: [_ctx.FormValidations.RequiredRule],
          paddings: '!py-3 px-4',
          modelValue: _ctx.formData.user_role,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.user_role) = $event)),
          name: "User role",
          options: _ctx.adminRoleOption,
          ref: "user_role"
        }, {
          title: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" User Role ")
          ])),
          _: 1
        }, 8, ["rules", "modelValue", "options"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_app_normal_text, { class: "w-full text-left !font-semibold" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Permissions ")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_normal_text, { class: "text-left !text-gray-500" }, {
                default: _withCtx(() => [
                  _createTextVNode("Manage " + _toDisplayString(_ctx.formData.user_role == "admin" ? "All" : "Assigned"), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.user_role == 'admin'
                ? _ctx.formData.permissions_admin
                : _ctx.formData.permissions_store_admin, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (_ctx.showCurrentPermission(_ctx.formData.user_role, index))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_app_checkbox, {
                            standard: true,
                            modelValue: 
                    (_ctx.formData.user_role == 'admin'
                      ? _ctx.formData.permissions_admin
                      : _ctx.formData.permissions_store_admin)[index]
                  ,
                            "onUpdate:modelValue": ($event: any) => ((
                    (_ctx.formData.user_role == 'admin'
                      ? _ctx.formData.permissions_admin
                      : _ctx.formData.permissions_store_admin)[index]
                  ) = $event),
                            class: "w-full"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_app_normal_text, { class: "text-left" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(index == "manage_all_orders" ? "Orders" : "") + " " + _toDisplayString(index == "manage_assigned_branch_orders" ? "Orders" : "") + " " + _toDisplayString(index == "manage_offline_payments"
                        ? "Offline payment"
                        : "") + " " + _toDisplayString(index == "manage_products" ? "Products" : "") + " " + _toDisplayString(index == "manage_profile" ? "Store profile" : "") + " " + _toDisplayString(index == "manage_shoplists" ? "Offers" : "") + " " + _toDisplayString(index == "manage_promotions" ? "Promotions" : "") + " " + _toDisplayString(index == "manage_store_branches" ? "Store branches" : "") + " " + _toDisplayString(index == "manage_settlement_accounts"
                        ? "Settlement accounts"
                        : ""), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[50px]" }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["parentRefs"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_app_button, {
        class: "w-full py-3",
        onClick: _withModifiers(_ctx.validateForm, ["prevent"])
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Send ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 64))
}