<template>
  <app-wrapper>
    <subpage-layout :title="'Settlement Accounts'">
      <div class="w-full flex flex-col space-y-4">
        <div class="w-full flex flex-col space-y-2 px-4">
          <div
            class="w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
            @click="showAddBankAccountModal"
          >
            <app-normal-text class="text-center text-gray-700">
              + New Account
            </app-normal-text>
          </div>

          <div
            v-for="(data, index) in settlementAccounts"
            :key="index"
            class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
          >
            <div class="flex justify-between w-full">
              <AppNormalText class="!font-semibold"
                >{{ data.name }}
              </AppNormalText>

              <app-badge
                v-if="data.is_default"
                :color="'purple'"
                :class="'capitalize'"
              >
                Default
              </app-badge>

              <app-badge
                v-else
                :color="'outline-black'"
                :class="' !whitespace-nowrap cursor-pointer'"
                @click="setDefaultBank(data.uuid)"
              >
                {{ capitalize("set as default") }}
              </app-badge>
            </div>

            <div class="h-[1px] bg-grey-50 w-full"></div>

            <div class="flex justify-between w-full">
              <AppNormalText
                :color="'text-grey-700'"
                class="!text-[12px]"
                :custom-class="'gap-2'"
              >
                Bank
              </AppNormalText>
              <AppNormalText>
                {{ data.bank }}
              </AppNormalText>
            </div>

            <div class="flex justify-between w-full">
              <AppNormalText
                :color="'text-grey-700'"
                class="!text-[12px]"
                :custom-class="'gap-2'"
              >
                Account No
              </AppNormalText>
              <AppNormalText>
                {{ data.account_no }}
              </AppNormalText>
            </div>
          </div>
        </div>

        <div
          v-if="settlementAccounts.length == 0"
          class="w-full flex flex-col items-center justify-center h-[200px] px-4"
        >
          <app-empty-state
            :title="'No Settlement Accounts Found'"
            :sub-title="'You can add your settlement accounts by clicking the button above.'"
          />
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import { AppNormalText, AppBadge, AppEmptyState } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppBadge,
    AppWrapper,
    AppEmptyState,
  },
  name: "MerchantSettlementAccountIndexPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "BusinessWallet",
        method: "GetBusinessWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Wallet",
        property: "CommercialBanks",
        method: "GetCommercialBanks",
        params: [],
        requireAuth: false,
      },
    ],
    tracking_data: {
      lable: "Settlement Accounts Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Settlement Accounts",
    });

    const BusinessWallet = ref(Logic.Wallet.BusinessWallet);

    const settlementAccounts = reactive<
      {
        uuid: string;
        name: string;
        account_no: string;
        bank: string;
        is_default: boolean;
      }[]
    >([]);

    const setSettlementAccounts = () => {
      settlementAccounts.length = 0;

      let hasOneDefault = false;

      BusinessWallet.value?.banks?.forEach((bank) => {
        let moveForeach = false;

        if (!Logic.Auth.CurrentBusinessIsSuperAdmin()) {
          moveForeach = false;
          if (
            bank.sub_business_id?.toString() ==
            Logic.Auth.AuthUser?.business_admin?.id
          ) {
            moveForeach = true;
          }
        }

        if (moveForeach) {
          settlementAccounts.push({
            uuid: bank.uuid,
            name: bank.meta_data?.name || "",
            account_no: bank.account_no,
            bank: bank.bank_name,
            is_default: bank.is_default,
          });

          if (bank.is_default) {
            hasOneDefault = true;
          }
        }
      });

      if (!hasOneDefault && settlementAccounts.length > 0) {
        settlementAccounts[0].is_default = true;
      }
    };

    const setDefaultBank = (bank_uuid: string) => {
      Logic.Common.showAlert({
        show: true,
        message: "Default bank set successfully",
        type: "success",
      });
      Logic.Wallet.SetDefaultBank(bank_uuid)?.then((response) => {
        if (response) {
          Logic.Wallet.GetBusinessWallet();
        }
      });
    };

    const showAddBankAccountModal = () => {
      Logic.Common.showModal({
        show: true,
        type: "add_bank_account",
        title: "Add Settlement Account",
        action: async (data: any) => {
          Logic.Wallet.AddUserBankForm = {
            account_no: data.bank_account,
            bank_code: data.bank_code,
            account_type: "business",
            sub_business_id: Logic.Auth.AuthUser?.business_admin
              ? parseInt(Logic.Auth.AuthUser?.business_admin?.id || "0")
              : undefined,
          };
          await Logic.Wallet.AddBank(true, true);
          await Logic.Wallet.GetBusinessWallet();

          Logic.Common.showAlert({
            show: true,
            message: "Bank added successfully",
            type: "success",
          });
          Logic.Common.showModal({
            show: false,
          });
        },
      });
    };

    watch(BusinessWallet, () => {
      setSettlementAccounts();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("BusinessWallet", BusinessWallet);
      setSettlementAccounts();
    });

    return {
      Logic,
      settlementAccounts,
      setDefaultBank,
      showAddBankAccountModal,
      capitalize,
    };
  },
});
</script>
