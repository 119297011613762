<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0 relative"
  >
    <!-- Enter Email -->
    <app-text-field
      :placeholder="'Enter email'"
      :rules="[FormValidations.RequiredRule, FormValidations.EmailRule]"
      v-model="formData.email"
      :hasTitle="true"
      type="email"
      :padding="'py-3 px-4'"
      name="Email Address"
      ref="friend_email"
    >
      <template v-slot:title> Email Address </template>
    </app-text-field>

    <!-- Select user type -->
    <app-select
      :placeholder="'Select user type'"
      :hasTitle="true"
      :rules="[FormValidations.RequiredRule]"
      :paddings="'!py-3 px-4'"
      v-model="formData.user_role"
      name="User role"
      :options="adminRoleOption"
      ref="user_role"
    >
      <template v-slot:title> User Role </template>
    </app-select>

    <div class="w-full flex flex-col space-y-2">
      <app-normal-text class="w-full text-left !font-semibold">
        Permissions
      </app-normal-text>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-col space-y-3">
          <app-normal-text class="text-left !text-gray-500"
            >Manage
            {{
              formData.user_role == "admin" ? "All" : "Assigned"
            }}</app-normal-text
          >

          <div class="w-full flex flex-col space-y-2">
            <template
              v-for="(item, index) in formData.user_role == 'admin'
                ? formData.permissions_admin
                : formData.permissions_store_admin"
              :key="index"
            >
              <div
                v-if="showCurrentPermission(formData.user_role, index)"
                class="w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
              >
                <app-checkbox
                  :standard="true"
                  v-model="
                    (formData.user_role == 'admin'
                      ? formData.permissions_admin
                      : formData.permissions_store_admin)[index]
                  "
                  class="w-full"
                >
                  <app-normal-text class="text-left">
                    {{ index == "manage_all_orders" ? "Orders" : "" }}
                    {{
                      index == "manage_assigned_branch_orders" ? "Orders" : ""
                    }}
                    {{
                      index == "manage_offline_payments"
                        ? "Offline payment"
                        : ""
                    }}
                    {{ index == "manage_products" ? "Products" : "" }}
                    {{ index == "manage_profile" ? "Store profile" : "" }}
                    {{ index == "manage_shoplists" ? "Offers" : "" }}
                    {{ index == "manage_promotions" ? "Promotions" : "" }}
                    {{
                      index == "manage_store_branches" ? "Store branches" : ""
                    }}
                    {{
                      index == "manage_settlement_accounts"
                        ? "Settlement accounts"
                        : ""
                    }}
                  </app-normal-text>
                </app-checkbox>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Spacer -->
      <div class="h-[50px]"></div>
    </div>
  </app-form-wrapper>
  <div class="w-full flex flex-col sticky bottom-0 left-0 pb-4 md:!pb-0">
    <app-button class="w-full py-3" @click.prevent="validateForm">
      Send
    </app-button>
  </div>
</template>
<script lang="ts">
import { StorePermissions } from "../../gql/graphql";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import {
  AppFormWrapper,
  AppTextField,
  AppCheckbox,
  AppSelect,
} from "../AppForm";
import { AppNormalText } from "../AppTypography";
import { SelectOption } from "@shpt/logic/src/logic/types/common";
import { showCurrentPermission } from "../../composable/common";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
    AppButton,
    AppNormalText,
    AppCheckbox,
    AppSelect,
  },
  name: "AppModalSendInvitation",
  setup(props) {
    const FormValidations = Logic.Form;

    const isAdmin = ref(false);

    const adminRoleOption = reactive<SelectOption[]>([
      {
        key: "store_admin",
        value: "Store Admin",
      },
      {
        key: "admin",
        value: "Admin",
      },
    ]);

    const formData = reactive<{
      email: string;
      user_role: string;
      permissions_admin: StorePermissions;
      permissions_store_admin: StorePermissions;
      permissions?: StorePermissions;
    }>({
      email: "",
      user_role: "store_admin",
      permissions_admin: {
        manage_assigned_branch_orders: true,
        manage_products: true,
        manage_shoplists: true,
        manage_offline_payments: true,
        manage_all_orders: false,
        manage_profile: false,
        manage_promotions: false,
        manage_settlement_accounts: false,
        manage_store_branches: false,
      },
      permissions_store_admin: {
        manage_assigned_branch_orders: true,
        manage_products: true,
        manage_shoplists: true,
        manage_offline_payments: true,
        manage_all_orders: false,
        manage_profile: false,
        manage_promotions: false,
        manage_settlement_accounts: false,
        manage_store_branches: false,
      },
    });

    const admin_email = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(admin_email.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          const finalPermissions =
            formData.user_role == "admin"
              ? formData.permissions_admin
              : formData.permissions_store_admin;
          finalPermissions.user_role = formData.user_role;
          formData.permissions = finalPermissions;
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      formData,
      isAdmin,
      adminRoleOption,
      showCurrentPermission,
      validateForm,
    };
  },
});
</script>
