import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row justify-center items-center h-[100px]"
}
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[200px] px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_business_pick_area = _resolveComponent("app-business-pick-area")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Store Branches' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddArea(null, -1))),
                class: "w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
              }, [
                _createVNode(_component_app_normal_text, { class: "text-center text-gray-700" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" + New Store Branch ")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.pickupAreaIsLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_app_loading_state)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryAreas, (data, index) => {
                return (_openBlock(), _createBlock(_component_app_business_pick_area, {
                  key: index,
                  data: data,
                  onClick: ($event: any) => (_ctx.showAddArea(data, index)),
                  class: "cursor-pointer"
                }, null, 8, ["data", "onClick"]))
              }), 128))
            ]),
            (_ctx.deliveryAreas.length == 0 && !_ctx.pickupAreaIsLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_app_empty_state, {
                    title: 'No Store Branches Found',
                    "sub-title": 'You can add your store branches by clicking the button above.'
                  })
                ]))
              : _createCommentVNode("", true),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}