import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 pt-4"
}
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 py-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 pt-1" }
const _hoisted_4 = {
  key: 2,
  class: "w-full flex flex-col space-y-2 pb-3"
}
const _hoisted_5 = { class: "w-full flex flex-col space-y-3 pt-1" }
const _hoisted_6 = { class: "w-full flex flex-col" }
const _hoisted_7 = { class: "w-full flex flex-col px-6 py-6 space-y-2 items-center justify-center h-full" }
const _hoisted_8 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 pt-4"
}
const _hoisted_9 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_10 = { class: "w-full flex flex-row justify-end" }
const _hoisted_11 = { class: "w-full flex flex-col pt-4 bg-white dark:!bg-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.title,
        goBackHandler: _ctx.goBackHandler
      }, {
        "extra-topbar": _withCtx(() => [
          _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 ' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString("Step " + `(${_ctx.onboardingStep.current + "/" + _ctx.onboardingStep.total})`), 1)
            ]),
            _: 1
          })
        ]),
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`${
            _ctx.onboardingStep.current == _ctx.onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onboardingStep.total, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass(`${
              _ctx.onboardingStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
              }, null, 2))
            }), 128))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_app_form_wrapper, {
            parentRefs: _ctx.parentRefs,
            class: "w-full flex flex-col space-y-2 px-4",
            ref: "formComp"
          }, {
            default: _withCtx(() => [
              (_ctx.onboardingStep.current == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_ctx.formData.account_type == 'individual')
                      ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 0 }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode(" For "),
                            _createElementVNode("span", { class: "!font-extrabold !text-black" }, "Creator Shop Account", -1),
                            _createTextVNode(" verification, provide your names and verification documents ")
                          ])),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_app_normal_text, { key: 1 }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" For "),
                            _createElementVNode("span", { class: "!font-extrabold !text-black" }, "Business Shop Account", -1),
                            _createTextVNode(" verification, provide your account manager's names, verification documents and business documents ")
                          ])),
                          _: 1
                        })),
                    _createVNode(_component_AppTextField, {
                      "has-title": true,
                      "custom-class": '',
                      padding: "px-3 py-3",
                      placeholder: 'Enter first name',
                      ref: "manager_first_name",
                      name: 'First name',
                      "focus-border": 'border-primary-400',
                      modelValue: _ctx.formData.account_manager.first_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.account_manager.first_name) = $event)),
                      rules: [_ctx.FormValidations.RequiredRule],
                      required: ""
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formData.account_type == "individual" ? "Your" : "Account Manager") + " First Name", 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"]),
                    _createVNode(_component_AppTextField, {
                      "has-title": true,
                      "custom-class": '',
                      padding: "px-3 py-3",
                      placeholder: 'Enter last name',
                      "focus-border": 'border-primary-400',
                      ref: "manager_last_name",
                      name: 'Last name',
                      modelValue: _ctx.formData.account_manager.last_name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.account_manager.last_name) = $event)),
                      rules: [_ctx.FormValidations.RequiredRule],
                      required: ""
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formData.account_type == "individual" ? "Your" : "Account Manager") + " Last Name", 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold w-full border-b-[1px] border-gray-200 pb-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Personal Verification " + _toDisplayString(_ctx.formData.account_type == "individual" ? "" : "(Account Manager)"), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_AppSelect, {
                          "has-title": true,
                          placeholder: 'Select document type',
                          paddings: 'px-3 py-3',
                          ref: "manager_id_type",
                          options: _ctx.individualVerificationOptions,
                          modelValue: _ctx.formData.account_manager.verification_type,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.account_manager.verification_type) = $event))
                        }, {
                          title: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode(" ID Document Type ")
                          ])),
                          _: 1
                        }, 8, ["options", "modelValue"]),
                        _createVNode(_component_AppTextField, {
                          "has-title": true,
                          "custom-class": '',
                          padding: "px-3 py-3",
                          placeholder: 'Enter ID Number',
                          name: "ID Number",
                          ref: "manager_id_number",
                          "focus-border": 'border-primary-400',
                          modelValue: _ctx.formData.account_manager.verification_number,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.account_manager.verification_number) = $event)),
                          rules: [
                  _ctx.FormValidations.RequiredRule,
                  _ctx.FormValidations.customValidator(
                    _ctx.formData.account_manager.verification_number.length == 11,
                    'ID number must be valid'
                  ),
                ],
                          "content-rule": {
                  addAfterCount: 0,
                  characterToAdd: ``,
                  max: 11,
                },
                          "max-character": 11,
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("ID Number ")
                          ])),
                          _: 1
                        }, 8, ["modelValue", "rules"])
                      ])
                    ]),
                    (_ctx.formData.account_type == 'business')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold w-full border-b-[1px] border-gray-200 pb-2" }, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode(" Business Verification ")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_5, [
                            (_ctx.formData.account_type == 'business')
                              ? (_openBlock(), _createBlock(_component_AppSelect, {
                                  key: 0,
                                  "has-title": true,
                                  placeholder: 'Select document type',
                                  paddings: 'px-3 py-3',
                                  options: _ctx.businessVerificationOptions,
                                  ref: "business_document",
                                  modelValue: _ctx.formData.document_type,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.document_type) = $event)),
                                  onOnOptionSelected: _cache[5] || (_cache[5] = 
                  (data) => {
                    _ctx.documentName = data.value;
                  }
                )
                                }, {
                                  title: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode(" Document Type ")
                                  ])),
                                  _: 1
                                }, 8, ["options", "modelValue"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_app_file_attachment, {
                                accept: 'image/jpeg,image/gif,image/png,application/pdf',
                                isWrapper: true,
                                class: "w-full border-[1px] border-gray-200 bg-gray-50 rounded-[10px] h-[90px]",
                                "local-file-url": _ctx.uploadedDocument.url,
                                "onUpdate:localFileUrl": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.uploadedDocument.url) = $event)),
                                "model-value": _ctx.uploadedDocument.blob,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.uploadedDocument.blob) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_app_normal_text, { class: "text-center !text-gray-600" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Click to Upload " + _toDisplayString(_ctx.documentName), 1)
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.uploadedDocument.blob)
                                      ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                          key: 0,
                                          class: "!font-semibold text-center !text-gray-600"
                                        }, {
                                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                                            _createElementVNode("span", { class: "!text-black" }, "1 File Selected", -1)
                                          ])),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["local-file-url", "model-value"]),
                              (_ctx.uploadedDocument.blob)
                                ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                    key: 0,
                                    class: "text-left !line-clamp-2 pt-2 whitespace-pre-wrap !text-gray-600"
                                  }, {
                                    default: _withCtx(() => [
                                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "font-semibold !text-black" }, "Selected File:", -1)),
                                      _createTextVNode(" " + _toDisplayString(_ctx.uploadedDocument.blob?.name), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.onboardingStep.current == 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_app_normal_text, null, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode(" Your earnings will be sent into your settlement account. ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_AppSelect, {
                      "has-title": true,
                      placeholder: 'Select Bank',
                      paddings: 'px-3 py-3',
                      options: _ctx.CommercialBanksOptions,
                      "auto-complete": "",
                      ref: "bank",
                      modelValue: _ctx.settlementData.bank_code,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.settlementData.bank_code) = $event))
                    }, {
                      title: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode(" Bank ")
                      ])),
                      _: 1
                    }, 8, ["options", "modelValue"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_AppTextField, {
                        "has-title": true,
                        "custom-class": '',
                        padding: "px-3 py-3",
                        placeholder: 'Enter Account Number',
                        "focus-border": 'border-primary-400',
                        modelValue: _ctx.settlementData.account_no,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.settlementData.account_no) = $event)),
                        rules: [_ctx.FormValidations.RequiredRule],
                        name: "Account number",
                        ref: "account_number",
                        type: "tel",
                        required: ""
                      }, {
                        title: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode("Account Number")
                        ])),
                        _: 1
                      }, 8, ["modelValue", "rules"]),
                      _createElementVNode("div", _hoisted_10, [
                        (!_ctx.accountResolved && _ctx.settlementData.account_no.length >= 10)
                          ? (_openBlock(), _createBlock(_component_app_normal_text, {
                              key: 0,
                              color: 'text-primary-main'
                            }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode(" Resolving account... ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.accountResolved && _ctx.accountName)
                          ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.accountName), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "h-[120px]" }, null, -1)),
              _createVNode(_component_fixed_container, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_AppButton, {
                      padding: 'py-3',
                      "bg-color": 'bg-primary-400',
                      "text-color": 'text-white',
                      class: _normalizeClass(`w-full ${_ctx.formItemIsValid ? '' : 'opacity-[50%]'}`),
                      onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.moveForward()), ["prevent"]))
                    }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode(" Continue ")
                      ])),
                      _: 1
                    }, 8, ["class"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["parentRefs"])
        ]),
        _: 1
      }, 8, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}