import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_3 = { class: "flex justify-between w-full" }
const _hoisted_4 = { class: "flex justify-between w-full" }
const _hoisted_5 = { class: "flex justify-between w-full" }
const _hoisted_6 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[200px] px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Settlement Accounts' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAddBankAccountModal && _ctx.showAddBankAccountModal(...args)))
              }, [
                _createVNode(_component_app_normal_text, { class: "text-center text-gray-700" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" + New Account ")
                  ])),
                  _: 1
                })
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settlementAccounts, (data, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_AppNormalText, { class: "!font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    (data.is_default)
                      ? (_openBlock(), _createBlock(_component_app_badge, {
                          key: 0,
                          color: 'purple',
                          class: _normalizeClass('capitalize')
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" Default ")
                          ])),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_app_badge, {
                          key: 1,
                          color: 'outline-black',
                          class: _normalizeClass(' !whitespace-nowrap cursor-pointer'),
                          onClick: ($event: any) => (_ctx.setDefaultBank(data.uuid))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.capitalize("set as default")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_AppNormalText, {
                      color: 'text-grey-700',
                      class: "!text-[12px]",
                      "custom-class": 'gap-2'
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Bank ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_AppNormalText, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.bank), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_AppNormalText, {
                      color: 'text-grey-700',
                      class: "!text-[12px]",
                      "custom-class": 'gap-2'
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Account No ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_AppNormalText, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.account_no), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              }), 128))
            ]),
            (_ctx.settlementAccounts.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_app_empty_state, {
                    title: 'No Settlement Accounts Found',
                    "sub-title": 'You can add your settlement accounts by clicking the button above.'
                  })
                ]))
              : _createCommentVNode("", true),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}