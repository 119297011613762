<template>
  <app-wrapper>
    <dashboard-layout
      :title="'Sales'"
      topBarType="plain"
      :pageKey="'merchant-wallet'"
      :hasNotify="true"
    >
      <div class="w-full flex flex-col space-y-3 pt-4">
        <!-- Wallets -->
        <div class="w-full flex flex-col space-y-2 px-4">
          <div class="w-full flex flex-row item-center">
            <div
              class="px-[2px] py-[2px] rounded-[40px] bg-gray-100 dark:bg-gray-900 flex flex-row space-x-1"
            >
              <div
                v-for="(item, index) in walletTypes"
                :key="index"
                :class="`px-4 py-2  rounded-[40px] flex items-center justify-center cursor-pointer ${
                  item.id == selectedWallet ? 'bg-white' : 'bg-transparent'
                }`"
                @click="selectedWallet = item.id"
              >
                <app-normal-text
                  :class="`!font-semibold ${
                    item.id == selectedWallet
                      ? '!text-black'
                      : '!text-gray-400 dark:!text-gray-400'
                  }`"
                >
                  {{ item.name }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <app-image-loader
            :custom-class="'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500 relative'"
            :photo-url="'/images/card-bg.svg'"
          >
            <div class="w-full flex flex-row space-x-2">
              <AppNormalText :color="'text-white'">
                {{ selectedWalletItem.title }}</AppNormalText
              >
            </div>

            <div class="flex flex-col space-y-1">
              <div class="flex flex-row space-x-2 items-center">
                <app-header-text :color="'text-white'">{{
                  canView
                    ? `${Logic.Common.convertToMoney(selectedWalletItem.balance.amount)}`
                    : "*********"
                }}</app-header-text>
                <app-icon
                  v-if="canView"
                  @click="canView = false"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'show-white'"
                ></app-icon>
                <app-icon
                  @click="canView = true"
                  v-if="!canView"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'hide-white'"
                ></app-icon>
              </div>

              <div
                class="flex space-x-2 items-center"
                v-if="selectedWalletItem.balance.this_week_earnings"
              >
                <app-normal-text
                  :size="'small'"
                  :custom-class="'!font-extralight'"
                  class="font-extralight"
                  :color="'text-white'"
                >
                  +
                  {{
                    Logic.Common.convertToMoney(
                      selectedWalletItem.balance.this_week_earnings,
                      false,
                      "ngn"
                    )
                  }}
                  This week
                </app-normal-text>
              </div>
            </div>

            <span
              v-if="selectedWalletItem.id == 'ad_credit'"
              class="absolute top-[4%] right-[4%] px-4 py-1 bg-secondary-main rounded-[20px] cursor-pointer"
              @click="Logic.Common.GoToRoute('/wallet/fund')"
            >
              <app-normal-text class="!text-white"> + Topup </app-normal-text>
            </span>
          </app-image-loader>
        </div>

        <!-- Transactions -->

        <div
          class="w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[10px] px-4 pb-4 pt-2"
        >
          <div class="w-full flex flex-row items-center justify-between">
            <app-normal-text class="!font-semibold !text-sm text-left">
              Recent transactions
            </app-normal-text>

            <!-- <app-normal-text class="!text-[#9D4EDD] underline !text-[12px]">
            View all
          </app-normal-text> -->
          </div>

          <template v-if="transactionIsLoading">
            <div class="w-full flex flex-col justify-center items-center h-[250px]">
              <app-loading-state class="text-primary-500" />
            </div>
          </template>
          <template v-else>
            <div
              class="w-full flex flex-col justify-center items-center h-[250px]"
              v-if="transactions.length == 0"
            >
              <app-empty-state
                :title="'No transactions yet'"
                :sub-title="'Your transactions will appear here'"
              />
            </div>

            <div class="w-full flex flex-col space-y-5" v-else>
              <app-transaction
                v-for="(item, index) in transactions"
                :key="index"
                :data="item"
              />
            </div>
          </template>
        </div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppImageLoader,
  AppIcon,
  AppHeaderText,
  AppTransaction,
  AppEmptyState,
  AppLoadingState,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppIcon,
    AppHeaderText,
    AppTransaction,
    AppEmptyState,
    AppLoadingState,
    AppWrapper,
  },
  name: "MerchantWalletPage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "BusinessWallet",
        method: "GetBusinessWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Wallet",
        property: "AdWallet",
        method: "GetAdWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shop",
        property: "BusinessDashboardSummary",
        method: "GetBusinessDashboardSummary",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Wallet Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Merchant Wallet",
    });

    const canView = ref(true);
    const BusinessWallet = ref(Logic.Wallet.BusinessWallet);
    const AdWallet = ref(Logic.Wallet.AdWallet);
    const BusinessDashboardSummary = ref(Logic.Shop.BusinessDashboardSummary);
    const BusinessWalletTransactions = ref(Logic.Wallet.BusinessWalletTransactions);
    const AdWalletTransactions = ref(Logic.Wallet.AdWalletTransactions);

    const selectedWallet = ref("earnings");

    const transactionIsLoading = ref(false);

    const selectedWalletItem = computed(() => {
      return walletTypes.filter((eachItem) => eachItem.id == selectedWallet.value)[0];
    });

    const walletTypes = reactive([
      {
        id: "earnings",
        name: "Sales",
        title: "Your Sales",
        balance: {
          amount: 30200020,
          this_week_earnings: 10000,
        },
      },
      {
        id: "ad_credit",
        name: "Ad Credit",
        title: "Your Ad Credit",
        balance: {
          amount: 450000,
        },
      },
    ]);

    const transactions = reactive<
      {
        id: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
      }[]
    >([]);

    const setWallets = () => {
      walletTypes[0].balance.amount = BusinessWallet.value?.total_balance || 0;
      walletTypes[1].balance.amount = AdWallet.value?.total_balance || 0;
      walletTypes[0].balance.this_week_earnings =
        BusinessDashboardSummary.value?.this_week_earnings || 0;
    };

    const setTransactions = () => {
      transactions.length = 0;
      if (selectedWallet.value == "earnings") {
        BusinessWalletTransactions.value?.data.forEach((item) => {
          transactions.push({
            id: item.id,
            description: item.description,
            type: item.dr_or_cr,
            time: Logic.Common.fomartDate(item.created_at, "MMM D, YYYY | h:mma"),
            amount: item.amount,
            category: "Sales",
          });
        });
      } else {
        AdWalletTransactions.value?.data.forEach((item) => {
          transactions.push({
            id: item.id,
            description: item.description,
            type: item.dr_or_cr,
            time: Logic.Common.fomartDate(item.created_at, "MMM D, YYYY | h:mma"),
            amount: item.amount,
            category: item.dr_or_cr == "debit" ? "Ad" : "Top up",
          });
        });
      }
    };

    const getTransactions = async (type: "business" | "ad") => {
      transactionIsLoading.value = true;
      if (type == "business") {
        await Logic.Wallet.GetTransactionsByWalletId(
          "business",
          parseInt(BusinessWallet.value?.id || "0"),
          1,
          50,
          "UPDATED_AT",
          "DESC"
        );
      } else {
        await Logic.Wallet.GetTransactionsByWalletId(
          "advert",
          parseInt(AdWallet.value?.id || "0"),
          1,
          50,
          "UPDATED_AT",
          "DESC"
        );
      }
      transactionIsLoading.value = false;
    };

    watch([BusinessWalletTransactions, AdWalletTransactions], () => {
      setTransactions();
    });

    watch([BusinessDashboardSummary, BusinessWallet, AdWallet], () => {
      setWallets();
    });

    watch(selectedWallet, () => {
      getTransactions(selectedWallet.value == "earnings" ? "business" : "ad");
    });

    onIonViewWillEnter(() => {
      setWallets();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("BusinessWallet", BusinessWallet);
      Logic.Wallet.watchProperty("AdWallet", AdWallet);
      Logic.Wallet.watchProperty(
        "BusinessWalletTransactions",
        BusinessWalletTransactions
      );
      Logic.Wallet.watchProperty("AdWalletTransactions", AdWalletTransactions);

      Logic.Shop.watchProperty("BusinessDashboardSummary", BusinessDashboardSummary);
      setWallets();
      getTransactions("business");
    });

    return {
      Logic,
      walletTypes,
      selectedWallet,
      canView,
      selectedWalletItem,
      transactions,
      BusinessWallet,
      AdWallet,
      transactionIsLoading,
    };
  },
});
</script>
