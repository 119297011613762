<template>
  <app-wrapper>
    <subpage-layout
      :title="isEdit ? 'Update Advert' : pageTitle"
      :goBackHandler="goBackHandler"
    >
      <template v-slot:extra-topbar v-if="!isEdit">
        <app-normal-text
          :custom-class="'!text-primary-400 dark:!text-primary-300'"
          >{{
            "Step " +
            `(${
              promotionCreationStep.current + "/" + promotionCreationStep.total
            })`
          }}
        </app-normal-text>
      </template>
      <template v-slot:extra-topbar-row>
        <div
          :class="`${
            promotionCreationStep.current == promotionCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
        >
          <div
            v-for="(item, index) in promotionCreationStep.total"
            :key="item"
            :class="`${
              promotionCreationStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == promotionCreationStep.total - 1
                ? 'rounded-r-[999px]'
                : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-4 px-4 pt-3">
        <app-form-wrapper
          ref="formComp"
          :parentRefs="parentRefs"
          class="w-full flex flex-col space-y-4"
        >
          <!-- Advert Media -->
          <template v-if="promotionCreationStep.current == 1">
            <app-select
              :has-title="true"
              :placeholder-color="'placeholder:text-black'"
              :default-size="'w-full'"
              :placeholder="'Select'"
              :custom-class="'!bg-grey-50'"
              :paddings="'px-4 py-3'"
              :ref="'product_size'"
              :theme="Logic.Common.currentAppTheme()"
              :options="advertTypes"
              v-model="newPromotion.basic.type"
              required
            >
              <template v-slot:title> What is your objective? </template>
            </app-select>

            <template
              v-if="
                newPromotion.basic.type == 'shoppable' &&
                singleStory &&
                (newPromotion.basic.shoppable_type == 'shoplist' ||
                  newPromotion.basic.shoppable_type == 'product')
              "
            >
              <div class="flex flex-col">
                <app-file-attachment
                  class="w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer"
                  @update:modelValue="handleFileSelected"
                  @update:localFileUrl="handleFileURLReady"
                  is-wrapper
                  :accept="'image/jpeg,image/gif,image/png,video/mp4'"
                >
                  <template v-slot:content>
                    <div
                      class="w-full flex flex-row space-x-2 justify-center items-center"
                    >
                      <app-normal-text
                        class="!text-gray-700 dark:!text-gray-200"
                      >
                        Choose new media for offer (image/video)
                      </app-normal-text>
                    </div>
                  </template>
                </app-file-attachment>
              </div>
            </template>
            <div
              class="w-full mdlg:!w-[480px] lg:!w-[480px] min-h-[calc(100vh-160px)] relative flex flex-col"
              v-if="
                !isEdit &&
                ((newPromotionMedia.file &&
                  newPromotion.basic.type != 'shoppable') ||
                  (newPromotion.basic.shoplist &&
                    newPromotion.basic.type == 'shoppable' &&
                    singleStory))
              "
            >
              <template
                v-if="newPromotion.basic.type == 'shoppable' && singleStory"
              >
                <template v-if="!useCustomMediaForShoppable">
                  <app-story-view
                    v-if="singleStory"
                    class="!relative !h-full"
                    ref="storyCompRef"
                    :data="[singleStory]"
                    :showSettings="true"
                    :has-edit="true"
                    :isFullScreen="false"
                    :listenToSpaceBar="false"
                  >
                  </app-story-view>
                </template>
                <template v-else>
                  <div class="w-full flex flex-col space-y-2">
                    <app-normal-text class="!font-semibold !text-left">
                      Selected
                      {{
                        newPromotion.basic.shoppable_type == "shoplist"
                          ? "offer"
                          : "product"
                      }}
                    </app-normal-text>

                    <div class="w-full grid grid-cols-2 gap-3 pb-2">
                      <div
                        v-if="
                          newPromotion.basic.shoppable_data &&
                          newPromotion.basic.shoppable_type == 'product'
                        "
                        class="col-span-1 flex flex-col"
                      >
                        <app-product
                          :data="newPromotion.basic.shoppable_data"
                          :class="`rounded-[6px]`"
                        />
                      </div>
                      <div
                        v-if="
                          newPromotion.basic.shoppable_data &&
                          newPromotion.basic.shoppable_type == 'shoplist'
                        "
                        class="col-span-1 flex flex-col"
                      >
                        <app-shoplist
                          :item="newPromotion.basic.shoppable_data"
                          :class="`rounded-[6px]`"
                        />
                      </div>
                    </div>

                    <!-- Media editor -->
                    <media-editor
                      v-if="newPromotionMedia.file_url"
                      ref="mediaEditorRef"
                      :file="newPromotionMedia.file"
                      :file_url="newPromotionMedia.file_url"
                      :type="newPromotionMedia.type"
                      v-model:base-image="newPromotionMedia.base_image"
                      v-model:base-video="newPromotionMedia.base_video"
                    >
                    </media-editor>
                  </div>
                </template>
              </template>
              <template v-if="newPromotion.basic.type == 'polls'">
                <media-editor
                  ref="mediaEditorRef"
                  :file="newPromotionMedia.file"
                  :file_url="newPromotionMedia.file_url"
                  :type="newPromotionMedia.type"
                  v-model:base-image="newPromotionMedia.base_image"
                  v-model:base-video="newPromotionMedia.base_video"
                  withPolls
                >
                </media-editor>
              </template>
              <template v-if="newPromotion.basic.type == 'website_visit'">
                <media-editor
                  ref="mediaEditorRef"
                  :file="newPromotionMedia.file"
                  :file_url="newPromotionMedia.file_url"
                  :type="newPromotionMedia.type"
                  v-model:base-image="newPromotionMedia.base_image"
                  v-model:base-video="newPromotionMedia.base_video"
                  withCta
                >
                </media-editor>
              </template>
              <template v-if="newPromotion.basic.type == 'banner'">
                <media-editor
                  ref="mediaEditorRef"
                  :file="newPromotionMedia.file"
                  :file_url="newPromotionMedia.file_url"
                  :type="newPromotionMedia.type"
                  v-model:base-image="newPromotionMedia.base_image"
                  v-model:base-video="newPromotionMedia.base_video"
                  imageCroppable
                >
                </media-editor>
              </template>
            </div>

            <div class="flex flex-col">
              <template v-if="newPromotion.basic.type != 'shoppable'">
                <app-file-attachment
                  class="w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer"
                  @update:modelValue="handleFileSelected"
                  @update:localFileUrl="handleFileURLReady"
                  is-wrapper
                  :accept="'image/jpeg,image/gif,image/png,video/mp4'"
                >
                  <template v-slot:content>
                    <div
                      class="w-full flex flex-row space-x-2 justify-center items-center"
                    >
                      <app-normal-text
                        class="!text-gray-700 dark:!text-gray-200"
                      >
                        Choose media (image/video)
                      </app-normal-text>
                    </div>
                  </template>
                </app-file-attachment>
              </template>

              <template v-if="newPromotion.basic.type == 'shoppable'">
                <div
                  class="w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer"
                  @click="showAddShoplist()"
                >
                  <div
                    class="w-full flex flex-row space-x-2 h-full justify-center items-center"
                  >
                    <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                      Choose offer or product
                    </app-normal-text>
                  </div>
                </div>
              </template>
            </div>

            <!-- Promotion title -->
            <div class="w-full flex flex-col">
              <app-text-field
                :has-title="true"
                :placeholder="'Enter title'"
                padding="px-3 py-2"
                name="Advert title"
                :rules="[FormValidations.RequiredRule]"
                v-model="newPromotion.basic.name"
                :customClass="'bg-grey-50'"
                :update-value="newPromotion.basic.name"
                required
              >
                <template v-slot:title> Advert title </template>
              </app-text-field>
            </div>

            <!-- For shoppable -->

            <!-- For website visit -->
            <template v-if="newPromotion.basic.type == 'website_visit'">
              <!-- CTA -->
              <div class="w-full flex flex-col">
                <app-select
                  :has-title="true"
                  :options="ctaOptions"
                  paddings="px-3 py-2"
                  v-model="newPromotion.basic.cta"
                  autoComplete
                  :placeholder="'Select CTA'"
                  :customClass="'!bg-grey-50'"
                  :theme="Logic.Common.currentAppTheme()"
                >
                  <template v-slot:title> Call To Action (CTA) </template>
                </app-select>
              </div>

              <!-- Landing page url -->
              <div class="w-full flex flex-col">
                <app-text-field
                  :has-title="true"
                  :placeholder="'https://example.com'"
                  padding="px-3 py-2"
                  name="Landing Page URL"
                  :rules="[
                    FormValidations.RequiredRule,
                    FormValidations.WebsiteRule,
                  ]"
                  v-model="newPromotion.basic.landing_page_url"
                  :customClass="'!bg-grey-50'"
                >
                  <template v-slot:title> Landing Page URL </template>
                </app-text-field>
              </div>
            </template>

            <!-- For polls -->
            <template v-if="newPromotion.basic.type == 'polls'">
              <!-- Question -->
              <div class="w-full flex flex-col">
                <app-text-field
                  :has-title="true"
                  :placeholder="'Enter question'"
                  padding="px-3 py-2"
                  name="Question"
                  is-textarea
                  :rules="[FormValidations.RequiredRule]"
                  :max-character="50"
                  :textAreaRow="'1'"
                  v-model="newPromotion.basic.polls.question"
                  :customClass="'!bg-grey-50'"
                >
                  <template v-slot:title> Question </template>
                </app-text-field>
              </div>

              <!-- Options -->
              <div class="w-full flex flex-col">
                <div class="w-full flex flex-row items-center justify-between">
                  <app-normal-text class="!font-semibold">
                    Options
                  </app-normal-text>

                  <app-normal-text
                    class="!text-grey-500 dark:!text-grey-400"
                    @click="addOption()"
                    v-if="newPromotion.basic.polls.options.length < 4"
                  >
                    <span class="!text-primary-200">+ Add New</span>
                  </app-normal-text>
                </div>
                <div class="w-full flex flex-col space-y-2">
                  <div
                    v-for="(item, index) in newPromotion.basic.polls.options"
                    :key="index"
                    class="w-full flex flex-row justify-between items-center border-b-[1px] border-gray-100 py-2 pr-2"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <app-file-attachment
                        isWrapper
                        :accept="'image/jpeg, image/png, image/gif'"
                        v-model:local-file-url="item.image_url"
                        v-model:model-value="item.image_blob"
                        class="!w-auto"
                      >
                        <app-image-loader
                          :photo-url="item.image_url"
                          class="h-[40px] w-[40px] rounded-[7px] bg-gray-100 flex items-center justify-center"
                        >
                          <app-icon :name="'upload-image'" class="!h-[13px]">
                          </app-icon>
                        </app-image-loader>
                      </app-file-attachment>

                      <app-content-editable
                        :defaultValue="item.value"
                        v-model="item.value"
                        @contentChanged="
                          item.slug = Logic.Common.convertToSlug(item.value)
                        "
                        class="dark:!text-white !text-black !text-xs !outline-none focus:outline-none min-w-[40px] !whitespace-nowrap !bg-grey-100 dark:!bg-gray-900 px-2"
                      />
                    </div>

                    <div class="flex flex-row items-center space-x-3">
                      <app-normal-text
                        class="!text-red-500 !text-[11px] px-2 py-[2px] bg-red-50 rounded-[2px]"
                        v-if="item.image_url"
                        @click="item.image_url = ''"
                      >
                        Remove Image
                      </app-normal-text>
                      <app-icon
                        v-if="newPromotion.basic.polls.options.length > 2"
                        :name="'cross-black'"
                        :customClass="'h-[10px]'"
                        @click="removeOption(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- For banner -->
            <template v-if="newPromotion.basic.type == 'banner'">
              <!-- Landing page url -->
              <div class="w-full flex flex-col">
                <app-text-field
                  :has-title="true"
                  :placeholder="'https://example.com'"
                  padding="px-3 py-2"
                  name="Landing Page URL"
                  :rules="[
                    FormValidations.RequiredRule,
                    FormValidations.WebsiteRule,
                  ]"
                  v-model="newPromotion.basic.landing_page_url"
                  :customClass="'!bg-grey-50'"
                >
                  <template v-slot:title> Landing Page URL </template>
                </app-text-field>
              </div>
            </template>
          </template>

          <!-- Step 2 - Ad Targeting -->
          <template v-if="promotionCreationStep.current == 2">
            <!-- Age range -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="ageRangeOptions"
                paddings="px-3 py-3"
                v-model="newPromotion.targeting.age_ranges"
                :isMultiple="true"
                autoComplete
                :placeholder="'Select age ranges'"
                :customClass="'!bg-grey-50'"
                :theme="Logic.Common.currentAppTheme()"
                required
              >
                <template v-slot:title> Age ranges </template>
              </app-select>
            </div>

            <!-- Gender -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="genderOptions"
                paddings="px-3 py-3"
                v-model="newPromotion.targeting.gender"
                :placeholder="'Select gender'"
                :customClass="'bg-grey-50'"
                :theme="Logic.Common.currentAppTheme()"
                required
              >
                <template v-slot:title> Gender </template>
              </app-select>
            </div>

            <!-- Location -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="locationOptions"
                paddings="px-3 py-3"
                v-model="newPromotion.targeting.locations"
                :isMultiple="true"
                autoComplete
                :placeholder="'Select locations'"
                :customClass="'bg-grey-50'"
                ref="locationSelect"
                :theme="Logic.Common.currentAppTheme()"
                required
              >
                <template v-slot:title> Locations </template>
              </app-select>
            </div>

            <!-- Behavioural Demography -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="behaviouralDemographiesOptions"
                paddings="px-3 py-3"
                v-model="newPromotion.targeting.behavioural_demographies"
                :isMultiple="true"
                autoComplete
                :placeholder="'Choose behavioural demographies'"
                :customClass="'!bg-grey-50'"
                ref="behaviouralDemographySelect"
                :theme="Logic.Common.currentAppTheme()"
                required
              >
                <template v-slot:title> Focus demographies </template>
              </app-select>
            </div>
          </template>

          <!-- Step 3 - Bidding and budgeting -->
          <template v-if="promotionCreationStep.current == 3">
            <!-- Total Budget -->
            <div class="w-full flex flex-col space-y-1">
              <app-text-field
                :has-title="true"
                :placeholder="'Enter Ad budget'"
                padding="px-3 py-2"
                name="Total Ad Budget"
                is-formatted
                type="tel"
                :rules="[
                  FormValidations.customValidator(
                    parseInt(newPromotion.budgeting.total.replace(/,/g, '')) >
                      4999,
                    'Budget must be greater than or equals to ₦5,000'
                  ),
                ]"
                v-model="newPromotion.budgeting.total"
                :customClass="'!bg-grey-50'"
                required
              >
                <template v-slot:inner-prefix>
                  <app-normal-text> ₦ </app-normal-text>
                </template>
                <template v-slot:title>Ad budget </template>
              </app-text-field>
            </div>

            <!-- Set daily limit -->
            <div class="w-full flex flex-col space-y-1">
              <app-text-field
                :has-title="true"
                :placeholder="`Daily limit`"
                padding="px-3 py-2"
                :name="`Set daily limit`"
                is-formatted
                type="tel"
                :rules="[
                  FormValidations.customValidator(
                    parseInt(
                      newPromotion.budgeting.max_amount.replace(/,/g, '')
                    ) > 4999,
                    'Daily limit must be greater than or equals to ₦5,000'
                  ),
                ]"
                v-model="newPromotion.budgeting.max_amount"
                :customClass="'!bg-grey-50'"
                required
              >
                <template v-slot:title> Daily limit </template>
                <template v-slot:inner-prefix>
                  <app-normal-text> ₦ </app-normal-text>
                </template>
              </app-text-field>
            </div>

            <!-- Start date -->
            <app-text-field
              :has-title="true"
              :placeholder="'Start date'"
              padding="px-3 py-2"
              name="Start date"
              type="date"
              prevent-back-date
              :miminum-date="new Date().toString()"
              :rules="[FormValidations.RequiredRule]"
              v-model="newPromotion.scheduling.start"
              :customClass="'!bg-grey-50'"
              required
            >
              <template v-slot:title> Start date </template>
            </app-text-field>

            <div class="w-full flex flex-col">
              <app-checkbox
                :standard="true"
                v-model="newPromotion.run_until_paused"
              >
                <app-normal-text> Run until paused </app-normal-text>
              </app-checkbox>
            </div>

            <!-- End date -->
            <app-text-field
              v-if="!newPromotion.run_until_paused"
              :has-title="true"
              :placeholder="'End date'"
              padding="px-3 py-2"
              name="End date"
              type="date"
              prevent-back-date
              :miminum-date="new Date(newPromotion.scheduling.start).toString()"
              :rules="[FormValidations.RequiredRule]"
              v-model="newPromotion.scheduling.end"
              :customClass="'!bg-grey-50'"
              required
            >
              <template v-slot:title> End date </template>
            </app-text-field>

            <div class="w-full grid grid-cols-2 gap-3" v-if="false">
              <!-- Start time -->
              <div class="w-full flex flex-col">
                <app-select
                  :has-title="true"
                  :options="timeOfTheDayOptions"
                  paddings="px-3 py-2"
                  v-model="newPromotion.scheduling.ad_start_time"
                  :placeholder="'Start time'"
                  :customClass="'!bg-grey-50'"
                  :theme="Logic.Common.currentAppTheme()"
                  required
                >
                  <template v-slot:title> Ad Start Time </template>
                </app-select>
              </div>

              <!-- End time -->
              <div class="w-full flex flex-col">
                <app-select
                  :has-title="true"
                  :options="timeOfTheDayOptions"
                  paddings="px-3 py-2"
                  :placeholder="'End time'"
                  v-model="newPromotion.scheduling.ad_end_time"
                  :customClass="'!bg-grey-50'"
                  :theme="'dark'"
                  required
                >
                  <template v-slot:title> Ad End Time </template>
                </app-select>
              </div>
            </div>

            <div
              class="w-full flex flex-row"
              v-if="!endTimeMustBeMoreThanStartTime()"
            >
              <app-normal-text class="!text-error-main !text-left">
                End time must be more than start time
              </app-normal-text>
            </div>
          </template>

          <!-- Step 5 - Legal -->
          <template v-if="promotionCreationStep.current == 4">
            <!-- Terms -->
            <div class="w-full flex flex-col">
              <app-checkbox
                :standard="true"
                v-model="newPromotion.legal.merchant_terms"
              >
                <app-normal-text>
                  I have read and agree to the Shoppoint Merchant Platform’s
                  <span
                    class="!text-primary-main dark:!text-primary-200 underline"
                    >Terms and Conditions</span
                  >
                </app-normal-text>
              </app-checkbox>
            </div>

            <!-- Privacy -->
            <div class="w-full flex flex-col">
              <app-checkbox
                :standard="true"
                v-model="newPromotion.legal.privacy"
              >
                <app-normal-text>
                  I understand and agree to the
                  <span
                    class="!text-primary-main dark:!text-primary-200 underline"
                    >Privacy Policy</span
                  >
                  regarding user data collected through my promotion
                </app-normal-text>
              </app-checkbox>
            </div>

            <!-- Guidelines -->
            <div class="w-full flex flex-col">
              <app-checkbox
                :standard="true"
                v-model="newPromotion.legal.ad_guidelines"
              >
                <app-normal-text>
                  I confirm that my promotion complies with Shoppoint’s
                  <span
                    class="!text-primary-main dark:!text-primary-200 underline"
                    >Advertising Guidelines</span
                  >
                </app-normal-text>
              </app-checkbox>
            </div>
          </template>
        </app-form-wrapper>

        <!-- Spacer -->
        <div class="h-[70px]"></div>
      </div>

      <fixed-container custom-class="pt-4" class="!z-[20000]">
        <template v-if="!newPromotion.progress.loading">
          <app-button
            @click.prevent="continueToNext"
            :class="`w-full ${formIsValid ? '' : 'opacity-50'}`"
            :padding="'py-3'"
          >
            {{
              isEdit
                ? "Update"
                : promotionCreationStep.current == promotionCreationStep.total
                ? "Submit for review"
                : "Continue"
            }}
          </app-button>
        </template>
        <div v-else class="w-full flex flex-col space-y-4">
          <div class="w-full flex flex-row justify-between items-center">
            <app-normal-text class="!text-grey-700 dark:!text-gray-400">
              {{ newPromotion.progress.message }}
            </app-normal-text>
            <svg
              :class="`animate-spin  h-5 w-5 text-primary-400`"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div class="w-full relative h-[8px] rounded-[6px] bg-grey-50">
            <div
              class="absolute h-full top-0 left-0 rounded-[6px] bg-secondary-500"
              :style="`width: ${newPromotion.progress.percentage}%`"
            ></div>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import {
  onIonViewDidEnter,
  onIonViewDidLeave,
  onIonViewWillEnter,
} from "@ionic/vue";
import { Logic } from "@shpt/logic";
import MediaEditor from "@shpt/ui-components/src/components/AppCommon/MediaEditor.vue";
import {
  newPromotion,
  newPromotionMedia,
} from "@shpt/ui-components/src/composable/promote";
import AppWrapper from "@/components/AppWrapper.vue";
import { Objectives } from "@shpt/logic/src/gql/graphql";
import {
  supportedAreasOption,
  adDemographiesOptions,
  FormValidations,
} from "@shpt/ui-components";
import { SelectOption, Story } from "@shpt/ui-components/src/types";
import {
  AppFormWrapper,
  AppStoryView,
  AppTextField,
  AppSelect,
  AppContentEditable,
  AppFileAttachment,
  AppImageLoader,
  AppNormalText,
  AppButton,
  AppIcon,
  AppProduct,
  AppShoplist,
  AppCheckbox,
} from "@shpt/ui-components";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import {
  getMediaBox,
  getMediaBoxForProduct,
} from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    MediaEditor,
    AppWrapper,
    AppFormWrapper,
    AppStoryView,
    FixedContainer,
    AppTextField,
    AppSelect,
    AppContentEditable,
    AppFileAttachment,
    AppImageLoader,
    AppNormalText,
    AppButton,
    AppIcon,
    AppProduct,
    AppShoplist,
    AppCheckbox,
  },
  name: "CreateAdvertPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManySupportedAreas",
        method: "GetSupportAreas",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Ad",
        property: "ManyAdDemographies",
        method: "GetAdDemographies",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Wallet",
        property: "AdWallet",
        method: "GetAdWallet",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Create Advert Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Plain",
  setup() {
    useMeta({
      title: "Create Advert",
    });

    const mediaEditorRef = ref();

    const pageTitle = ref("Ad Objective");

    const promotionCreationStep = reactive({
      current: 1,
      total: 4,
    });

    const isEdit = ref(false);

    const showMaximimAmount = ref(false);

    const useCustomMediaForShoppable = ref(false);

    const AdWallet = ref(Logic.Wallet.AdWallet);

    const advertTypes = reactive<SelectOption[]>([
      { key: "shoppable", value: "Advertise your products and special offers" },
      { key: "website_visit", value: "Drive traffic to your website" },
      { key: "polls", value: "Engage your audience with polls" },
      { key: "banner", value: "Advertise your brand" },
    ]);

    const singleStory = ref<Story>();

    const setStory = () => {
      const mediaBox = newPromotion.basic.shoplist;
      if (mediaBox) {
        singleStory.value = {
          media: mediaBox,
          caption: "",
          is_group_buy: false,
          saved: false,
          saving: mediaBox.reward_percentage || 0,
          type: "shoplist",
          shoplist: {
            base_tag: mediaBox.main_category || "",
            price: mediaBox.price || 0,
            product_images: mediaBox.grid_images || [],
            title: mediaBox.title || "",
            uuid: mediaBox.data?.uuid || "",
            reward_percentage: mediaBox.reward_percentage || 0,
            reward_type: "cashback",
            type: "shoplist",
          },
        };
      }
    };

    const baseShoplist = reactive({
      base_image: "/images/temp/shoplist-sub-2.png",
      base_tag: "Weekly",
      price: 23000,
      title: "Fresh foods",
      reward_percentage: 12,
      reward_type: "savings",
    });

    const locationOptions = ref<SelectOption[]>([]);
    const behaviouralDemographiesOptions = ref<SelectOption[]>([]);

    const generateNumberArray = (start: number, end: number): number[] => {
      const result: number[] = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    };

    const ageRangeOptions = reactive<SelectOption[]>([
      {
        key: generateNumberArray(18, 120),
        value: "All Ages",
      },
      {
        key: generateNumberArray(18, 24),
        value: "18 to 24 years",
      },
      {
        key: generateNumberArray(25, 34),
        value: "25 to 34 years",
      },
      {
        key: generateNumberArray(35, 44),
        value: "35 to 44 years",
      },
      {
        key: generateNumberArray(45, 54),
        value: "45 to 54 years",
      },
      {
        key: generateNumberArray(55, 64),
        value: "55 to 64 years",
      },
      {
        key: generateNumberArray(65, 120),
        value: "65+ years",
      },
    ]);

    const genderOptions = reactive<SelectOption[]>([
      {
        key: ["male", "female"],
        value: "All Genders",
      },
      {
        key: ["male"],
        value: "Male",
      },
      {
        key: ["female"],
        value: "Female",
      },
    ]);

    const timeOfTheDayOptions = reactive<SelectOption[]>([
      { key: "24", value: "12am" },
      { key: "01", value: "1am" },
      { key: "02", value: "2am" },
      { key: "03", value: "3am" },
      { key: "04", value: "4am" },
      { key: "05", value: "5am" },
      { key: "06", value: "6am" },
      { key: "07", value: "7am" },
      { key: "08", value: "8am" },
      { key: "09", value: "9am" },
      { key: "10", value: "10am" },
      { key: "11", value: "11am" },
      { key: "12", value: "12pm" },
      { key: "13", value: "1pm" },
      { key: "14", value: "2pm" },
      { key: "15", value: "3pm" },
      { key: "16", value: "4pm" },
      { key: "17", value: "5pm" },
      { key: "18", value: "6pm" },
      { key: "19", value: "7pm" },
      { key: "20", value: "8pm" },
      { key: "21", value: "9pm" },
      { key: "22", value: "10pm" },
      { key: "23", value: "11pm" },
    ]);

    const endTimeMustBeMoreThanStartTime = () => {
      let startTime = parseInt(newPromotion.scheduling.ad_start_time);
      let endTime = parseInt(newPromotion.scheduling.ad_end_time);

      startTime = startTime == 24 ? 0 : startTime;
      endTime = endTime == 24 ? 0 : endTime;

      return endTime > startTime;
    };

    const ctaOptions = reactive<SelectOption[]>([
      { key: "Visit Now", value: "Visit Now" },
      { key: "Shop Now", value: "Shop Now" },
      { key: "Buy Now", value: "Buy Now" },
      { key: "Order Now", value: "Order Now" },
      { key: "Get Offer", value: "Get Offer" },
      { key: "Learn More", value: "Learn More" },
      { key: "Sign Up", value: "Sign Up" },
      { key: "Subscribe", value: "Subscribe" },
      { key: "Download", value: "Download" },
      { key: "Watch Video", value: "Watch Video" },
      { key: "See Menu", value: "See Menu" },
      { key: "Book Now", value: "Book Now" },
      { key: "Get Quote", value: "Get Quote" },
      { key: "Apply Now", value: "Apply Now" },
      { key: "Contact Us", value: "Contact Us" },
      { key: "Get Directions", value: "Get Directions" },
      { key: "Install Now", value: "Install Now" },
      { key: "Play Now", value: "Play Now" },
      { key: "Read More", value: "Read More" },
      { key: "Start Free Trial", value: "Start Free Trial" },
      { key: "View Demo", value: "View Demo" },
    ]);

    const biddingStrategiesOptions = (): SelectOption[] => {
      if (newPromotion.basic.type == "shoppable") {
        return [
          {
            key: "cpm",
            value: "Cost Per 1,000 Impression (CPM)",
          },
          {
            key: "cpc",
            value: "Cost Per Click (CPC)",
          },
        ];
      } else {
        return [
          {
            key: "cpc",
            value: "Cost Per Click (CPC)",
          },
          {
            key: "cpm",
            value: "Cost Per 1,000 Impression (CPM)",
          },
        ];
      }
    };

    const getMaximumStrategyAmount = (type: string) => {
      if (type == "cpc") {
        return 70;
      }
      if (type == "cpm") {
        return 7000;
      }
      if (type == "cpe") {
        return 7000;
      }

      return 0;
    };

    const getMinimumStrategyAmount = (type: string) => {
      if (type == "cpc") {
        return 40;
      }
      if (type == "cpm") {
        return 5000;
      }
      if (type == "cpe") {
        return 5000;
      }

      return 0;
    };

    const showAddShoplist = () => {
      Logic.Common.showModal({
        show: true,
        type: "add_shoplist",
        title: "Select an offer or a product",
        action: (data: any) => {
          if (data.type == "shoplist") {
            newPromotion.basic.shoplist_id = data.data.data.id;
            newPromotion.basic.shoplist = data.data;
            newPromotion.basic.shoppable_data = data.data;
            newPromotion.basic.name = `${data.data.title} Promotion`;
            newPromotion.run_until_paused = true;
            newPromotion.basic.shoppable_type = "shoplist";
            useCustomMediaForShoppable.value = false;
            setStory();
          } else {
            useCustomMediaForShoppable.value = true;
            newPromotion.basic.shoppable_data = data.data;
            newPromotion.basic.shoplist_id = data.data.data.id;
            newPromotion.basic.shoplist = getMediaBoxForProduct(data.data.data);
            newPromotion.basic.shoppable_type = "product";
            newPromotion.basic.name = `${data.data.data.name} Promotion`;
            newPromotion.run_until_paused = true;
            setStory();
          }

          Logic.Common.showModal({
            show: false,
          });
        },
        extraData: {
          selected: "",
          getMediaBox: getMediaBox,
        },
      });
    };

    const formIsValid = computed(() => {
      if (promotionCreationStep.current == 1) {
        const status =
          newPromotion.basic.name != "" &&
          ((newPromotion.basic.type == "shoppable" &&
            newPromotion.basic.shoplist_id) ||
            (newPromotion.basic.type == "website_visit" &&
              newPromotion.basic.cta &&
              newPromotion.basic.landing_page_url) ||
            (newPromotion.basic.type == "polls" &&
              newPromotion.basic.polls.options.length > 0) ||
            (newPromotion.basic.type == "banner" &&
              newPromotion.basic.landing_page_url &&
              newPromotion.basic.banner.crop_image_blob));
        return status;
      } else if (promotionCreationStep.current == 2) {
        return (
          newPromotion.targeting.age_ranges.length > 0 &&
          newPromotion.targeting.behavioural_demographies.length > 0 &&
          newPromotion.targeting.gender.length > 0 &&
          newPromotion.targeting.locations.length > 0
        );
      } else if (promotionCreationStep.current == 3) {
        return (
          newPromotion.budgeting.max_amount != "" &&
          parseInt(newPromotion.budgeting.max_amount.replace(/,/g, "")) >=
            5000 &&
          parseInt(newPromotion.budgeting.total.replace(/,/g, "")) >= 5000 &&
          newPromotion.scheduling.start != "" &&
          newPromotion.scheduling.end != "" &&
          newPromotion.scheduling.ad_start_time != "" &&
          newPromotion.scheduling.ad_end_time != ""
        );
      } else if (promotionCreationStep.current == 4) {
        return (
          newPromotion.legal.merchant_terms &&
          newPromotion.legal.privacy &&
          newPromotion.legal.ad_guidelines
        );
      }

      return false;
    });

    const goBackHandler = () => {
      if (promotionCreationStep.current <= 1) {
        Logic.Common.goBack();
      } else {
        promotionCreationStep.current--;
      }
    };

    const resetForm = () => {
      useCustomMediaForShoppable.value = false;
      newPromotion.basic = {
        name: "",
        type: "shoppable",
        shoplist_id: "",
        cta: "Visit now",
        polls: {
          question: "Is this your favourite food?",
          answer_type: "options",
          option_type: "plain",
          options: [],
        },
        banner: {},
      };
      newPromotion.targeting = {
        age_ranges: [],
        gender: [],
        locations: [],
        behavioural_demographies: [],
      };
      newPromotion.budgeting = {
        max_amount: "",
        strategy: "",
        total: "",
      };
      newPromotion.scheduling = {
        start: Logic.Common.momentInstance(new Date()).format("YYYY-MM-DD"),
        end: Logic.Common.momentInstance(new Date())
          .add(7, "days")
          .format("YYYY-MM-DD"),
        ad_start_time: "24",
        ad_end_time: "23",
      };
      newPromotion.legal = {
        merchant_terms: false,
        privacy: false,
        ad_guidelines: false,
      };
      newPromotion.run_until_paused = true;
      newPromotionMedia.file = undefined;
      newPromotionMedia.file_url = "";
    };
    const handleProgress = (progress: number) => {
      newPromotion.progress.percentage = progress;
      newPromotion.progress.message = "Uploading...";
      if (progress >= 100) {
        newPromotion.progress.message = "Processing...";
      }
    };

    const savePromotion = () => {
      const getMergedValues = (data: any[][]) => {
        const mergedArrays = data.flat();
        return Array.from(new Set(mergedArrays));
      };

      let startTime = parseInt(newPromotion.scheduling.ad_start_time);
      let endTime = parseInt(newPromotion.scheduling.ad_end_time);

      startTime = startTime == 24 ? 0 : startTime;
      endTime = endTime == 24 ? 0 : endTime;

      newPromotion.budgeting.strategy = "cpm";

      Logic.Ad.CreateAdvertForm = {
        ad_budget: newPromotion.budgeting.total.replace(/,/g, ""),
        maximum_cost: newPromotion.budgeting.max_amount.replace(/,/g, ""),
        ad_daytime_start: startTime,
        ad_daytime_end: endTime,
        ad_start_date: newPromotion.scheduling.start,
        ad_end_date: newPromotion.scheduling.end,
        bidding_strategy: newPromotion.budgeting.strategy,
        objectives: Objectives.Traffic,
        type: newPromotion.basic.type,
        promotion_title: newPromotion.basic.name,
        source_media_type: newPromotionMedia.type,
        source_media:
          newPromotionMedia.type == "image"
            ? newPromotion.basic.type != "banner"
              ? newPromotionMedia.base_image
              : newPromotion.basic.banner.crop_image_blob
            : newPromotionMedia.base_video,
        cta_rules: {
          cta: newPromotion.basic.cta,
          landing_page_url: newPromotion.basic.landing_page_url,
          polls: {
            answer_type: newPromotion.basic.polls.answer_type,
            option_type: newPromotion.basic.polls.option_type,
            question: newPromotion.basic.polls.question,
            options: newPromotion.basic.polls.options.map((item) => {
              return {
                slug: item.slug,
                value: item.value,
                image: item.image_blob,
              };
            }),
          },
        },
        metadata: {
          media_type: newPromotionMedia.type,
          shoplist_id: newPromotion.basic.shoplist_id,
          shoppable_type: newPromotion.basic.shoppable_type,
        },
        target_audience_rules: {
          age_ranges: getMergedValues(newPromotion.targeting.age_ranges).map(
            (item) => parseInt(item)
          ),
          gender: newPromotion.targeting.gender,
          locations: getMergedValues(newPromotion.targeting.locations).map(
            (item) => parseInt(item)
          ),
          demographies: getMergedValues(
            newPromotion.targeting.behavioural_demographies
          ).map((item) => parseInt(item)),
        },
        run_until_paused: newPromotion.run_until_paused,
      };

      newPromotion.progress.loading = true;

      Logic.Ad.CreateAdvert(handleProgress)?.then((data) => {
        if (data) {
          Logic.Ad.GetAdverts(
            1,
            15,
            "CREATED_AT",
            "DESC",
            `{
                  column: USER_ID
                  operator: EQ
                  value: ${Logic.Auth.AuthUser?.id}
                }`
          ).then(() => {
            newPromotion.progress.loading = false;
            resetForm();
            Logic.Common.showAlert({
              show: true,
              message: "Promotion created successfully",
              type: "success",
            });
          });
        } else {
          newPromotion.progress.loading = false;
        }
      });

      Logic.Common.showAlert({
        show: true,
        message: "Promotion is now being created",
        type: "info",
      });
      Logic.Common.goBack();
    };

    const handleFileURLReady = (url: string) => {
      useCustomMediaForShoppable.value = true;
      newPromotionMedia.file_url = url;
      mediaEditorRef.value?.runOnIonViewDidLeave();
      setTimeout(() => {
        mediaEditorRef.value?.runOnIonViewDidEnter();
      }, 600);
    };

    const handleFileSelected = (data: any) => {
      // Check file type from blob
      const fileType = data.type;
      if (fileType.includes("video")) {
        newPromotionMedia.type = "video";
      } else {
        newPromotionMedia.type = "image";
      }
      newPromotionMedia.file = data;
    };

    const makeAdPayment = () => {
      Logic.Common.showModal({
        show: true,
        title: "Complete Payment",
        type: "payment_provider",
        extraData: {
          amount: parseFloat(newPromotion.budgeting.total.replace(/,/g, "")),
          currency: "NGN",
          email: Logic.Auth.AuthUser?.email,
          user_uuid: Logic.Auth.AuthUser?.uuid,
          wallet_uuid: AdWallet.value?.uuid,
          type: "cash",
          narration: "Top Up Ad Wallet",
        },
        action: () => {
          Logic.Wallet.GetTransactionsByWalletId(
            "advert",
            parseInt(AdWallet.value?.id || "0"),
            1,
            50,
            "UPDATED_AT",
            "DESC"
          );
          Logic.Wallet.GetAdWallet();
          Logic.Common.showModal({ show: false });

          savePromotion();
        },
        preventClose: true,
        closeAction: () => {
          Logic.Common.showModal({ show: false });
        },
      });
    };

    const continueToNext = () => {
      if (isEdit.value) {
        savePromotion();
      } else {
        moveForward();
      }
    };

    const moveForward = () => {
      if (formIsValid.value) {
        if (promotionCreationStep.current < promotionCreationStep.total) {
          promotionCreationStep.current += 1;
        } else {
          makeAdPayment();
        }
      }
    };

    const onBiddingStategySelected = () => {
      showMaximimAmount.value = false;

      setTimeout(() => {
        showMaximimAmount.value = true;
      }, 500);
    };

    const addOption = () => {
      newPromotion.basic.polls.options.push({
        slug: "new_option",
        value: "New Option",
        image_url: "",
      });
    };

    const removeOption = (index: number) => {
      newPromotion.basic.polls.options =
        newPromotion.basic.polls.options.filter(
          (innerItem, innerIndex) => innerIndex != index
        );
    };

    watch(promotionCreationStep, () => {
      if (promotionCreationStep.current == 1) {
        pageTitle.value = "Ad Objective";
      }
      if (promotionCreationStep.current == 2) {
        pageTitle.value = "Ad Targeting";
      }
      if (promotionCreationStep.current == 3) {
        pageTitle.value = "Budget and Scheduling";
      }
      if (promotionCreationStep.current == 4) {
        pageTitle.value = "Legal and Compliance";
      }
    });

    onMounted(() => {
      locationOptions.value = supportedAreasOption(true);
      behaviouralDemographiesOptions.value = adDemographiesOptions();
      Logic.Wallet.watchProperty("AdWallet", AdWallet);
    });

    onIonViewWillEnter(() => {
      //
    });

    onIonViewDidEnter(() => {
      mediaEditorRef.value?.runOnIonViewDidEnter();
    });

    onIonViewDidLeave(() => {
      mediaEditorRef.value?.runOnIonViewDidLeave();
    });

    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        mediaEditorRef.value?.runOnIonViewDidEnter();
      }
    });

    onUnmounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        mediaEditorRef.value?.runOnIonViewDidLeave();
      }
    });

    return {
      Logic,
      mediaEditorRef,
      newPromotionMedia,
      isEdit,
      pageTitle,
      promotionCreationStep,
      formIsValid,
      baseShoplist,
      ageRangeOptions,
      genderOptions,
      locationOptions,
      behaviouralDemographiesOptions,
      AdWallet,
      newPromotion,
      advertTypes,
      singleStory,
      FormValidations,
      ctaOptions,
      useCustomMediaForShoppable,
      showMaximimAmount,
      timeOfTheDayOptions,
      getMinimumStrategyAmount,
      getMaximumStrategyAmount,
      biddingStrategiesOptions,
      endTimeMustBeMoreThanStartTime,
      continueToNext,
      moveForward,
      onBiddingStategySelected,
      addOption,
      removeOption,
      goBackHandler,
      handleFileURLReady,
      showAddShoplist,
      handleFileSelected,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
});
</script>
<style scoped>
.slide-out-bottom-enter-active,
.slide-out-bottom-leave-active {
  transition: transform 0.5s ease;
}
.slide-out-bottom-enter {
  transform: translateY(100%);
}
.slide-out-bottom-leave-to {
  transform: translateY(100%);
}
body {
  background: black !important;
}
</style>
