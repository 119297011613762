<template>
  <app-wrapper>
    <dashboard-layout
      :title="'Advertise'"
      topBarType="plain"
      :pageKey="'merchant-promote'"
      :hasAddButton="Logic.Auth.BusinessUserCan('manage_promotions')"
      :hasNotify="false"
      :addButtonAction="showAddPromotion"
    >
      <div class="w-full flex flex-col space-y-2 pt-4">
        <!-- Summary -->
        <div class="w-full grid grid-cols-2 gap-3 px-4">
          <div
            v-for="(item, index) in promotionSummary"
            :key="index"
            class="border-grey-50 border-[1px] flex flex-col items-center justify-center rounded-[8px] py-3 px-3 space-y-2 col-span-1 mdlg:!py-5"
          >
            <app-icon :custom-class="'h-[26px]'" :name="item.icon"></app-icon>
            <div class="w-full flex flex-col space-y-[2px] items-center">
              <app-normal-text
                :custom-class="'!text-[10px] mdlg:!text-[12px]'"
                :color="'text-black'"
              >
                {{ item.title }}
              </app-normal-text>
              <div class="w-full flex items-center space-x-2 justify-center">
                <app-normal-text :custom-class="'font-bold !text-sm'">
                  {{ Logic.Common.convertToMoney(item.value, false, "") }}
                </app-normal-text>
                <div class="flex flex-row items-center space-x-[2px]">
                  <!-- <div>
                  <AppIcon
                    :custom-class="'h-[10px]'"
                    :name="'arrow-stats-up'"
                  ></AppIcon>
                </div>
                <app-normal-text
                  :class="`!text-[10px] ${
                    item.change.type == 'increase'
                      ? '!text-green-500'
                      : '!text-red-500'
                  }`"
                >
                  {{ item.change.value }}%
                </app-normal-text> -->
                </div>
              </div>
              <!-- <app-normal-text class="!text-gray-500 !text-[12px]">
              From last week
            </app-normal-text> -->
            </div>
          </div>
        </div>

        <!-- Promotions -->
        <div class="w-full flex flex-col space-y-2">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-2 py-2 pr-4">
              <div
                :class="` ${
                  selectedPromotion == item.key
                    ? 'bg-primary-main'
                    : 'bg-gray-100 dark:bg-gray-700'
                } px-3 py-1 rounded-[30px] w-auto cursor-pointer`"
                v-for="(item, index) in promotionTypes"
                :key="index"
                @click="selectedPromotion = item.key"
              >
                <app-normal-text
                  :class="`!whitespace-nowrap  ${
                    selectedPromotion == item.key ? 'text-white' : 'text-gray-500'
                  }`"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <div class="w-full px-4 flex flex-col space-y-2">
            <template
              v-if="
                selectedPromotion == 'all'
                  ? promotions.length > 0
                  : promotions.filter((item) => item.type == selectedPromotion).length > 0
              "
            >
              <div
                v-for="(data, index) in selectedPromotion == 'all'
                  ? promotions
                  : promotions.filter((item) => item.type == selectedPromotion)"
                :key="index"
                class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer"
                @click="
                  Logic.Auth.BusinessUserCan('manage_promotions')
                    ? Logic.Common.GoToRoute(`/promote/${data.uuid}`)
                    : null
                "
              >
                <div class="flex justify-between w-full">
                  <AppNormalText class="!font-semibold !text-left !line-clamp-1"
                    >{{ data.title }}
                  </AppNormalText>

                  <app-badge
                    :color="`${
                      data.status == 'active' || data.status == 'verified'
                        ? 'green'
                        : data.status == 'inactive' || data.status == 'failed'
                        ? 'red'
                        : 'yellow'
                    }`"
                    :class="'mdlg:!text-[12px]'"
                  >
                    {{ capitalize(data.status.replace("_", " ")) }}
                  </app-badge>
                </div>

                <div class="h-[1px] bg-grey-50 w-full"></div>

                <div class="flex justify-between w-full">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    Impressions
                  </AppNormalText>
                  <AppNormalText>
                    {{ data.total_impression }}
                  </AppNormalText>
                </div>
                <div class="flex justify-between w-full">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    {{
                      data.type == "website_visit" || data.type == "banner_ad"
                        ? "Clicks"
                        : "Engagements"
                    }}
                  </AppNormalText>
                  <AppNormalText>
                    {{ data.total_engagement }}
                  </AppNormalText>
                </div>
                <div class="flex justify-between w-full">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    {{
                      data.type == "website_visit" || data.type == "banner_ad"
                        ? "Click-Through Rate"
                        : "Engagement Rate"
                    }}
                  </AppNormalText>
                  <AppNormalText> {{ data.engagement_rate }}% </AppNormalText>
                </div>

                <div class="flex justify-between w-full" v-if="data.bid_type == 'cpm'">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    CPM
                  </AppNormalText>
                  <AppNormalText>
                    {{
                      Logic.Common.convertToMoney(data.cost_per_impression, false, "ngn")
                    }}
                  </AppNormalText>
                </div>

                <div class="flex justify-between w-full" v-if="data.bid_type == 'cpe'">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    CPE
                  </AppNormalText>
                  <AppNormalText>
                    {{
                      Logic.Common.convertToMoney(data.cost_per_engagement, false, "ngn")
                    }}
                  </AppNormalText>
                </div>

                <!-- <div class="flex justify-between w-full">
                <AppNormalText :custom-class="'!font-semibold'">
                  Total Cost
                </AppNormalText>

                <AppNormalText class="font-semibold">
                  {{
                    Logic.Common.convertToMoney(
                      data.total_cost,
                      false,
                      "ngn"
                    )
                  }}
                </AppNormalText>
              </div> -->
              </div>
            </template>
            <template v-else>
              <div class="w-full flex items-center justify-center h-[200px]">
                <app-empty-state
                  :title="`No ${selectedPromotion.replace('_', ' ')} promotion found`"
                  :sub-title="`Your ${selectedPromotion.replace(
                    '_',
                    ' '
                  )} promotions will appear here`"
                >
                </app-empty-state>
              </div>
            </template>
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { capitalize, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { AppIcon, AppNormalText, AppBadge, AppEmptyState } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppBadge,
    AppEmptyState,
    AppWrapper,
  },
  name: "MerchantPromotePage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Ad",
        property: "ManyAdverts",
        method: "GetAdverts",
        params: [
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
            column: USER_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.business_user_id}
            }
            `,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Promote Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Merchant Promotions",
    });

    const selectedPromotion = ref("all");

    const ManyAdverts = ref(Logic.Ad.ManyAdverts);

    const promotionSummary = reactive([
      {
        title: "Total Impressions",
        icon: "purple-chart",
        value: 0,
        change: {
          value: 0,
          type: "increase",
        },
      },
      {
        title: "Total Engagements",
        icon: "green-chart",
        value: 0,
        change: {
          value: 0,
          type: "increase",
        },
      },
    ]);

    const promotionTypes = reactive([
      {
        title: "All",
        key: "all",
      },
      {
        title: "Shoppable",
        key: "shoppable",
      },
      {
        title: "Website Visit",
        key: "website_visit",
      },
      {
        title: "Polls",
        key: "polls",
      },
      {
        title: "Banner Ad",
        key: "banner",
      },
    ]);

    // Bidding options can be cost per engagment or cost per 1000 Impresssion

    const promotions = reactive<
      {
        uuid: string;
        type: "shoppable" | "website_visit" | "polls" | "banner_ad";
        title: string;
        total_impression: string;
        total_engagement: string;
        engagement_rate: string;
        cost_per_engagement: number;
        cost_per_impression: number;
        total_cost: number;
        status: string;
        bid_type: "cpm" | "cpe" | "cpc";
      }[]
    >([]);

    const setPromotion = () => {
      promotions.length = 0;

      let totalImpressions = 0;
      let totalEngagement = 0;

      ManyAdverts.value?.data?.forEach((item) => {
        const type: any = item.type;
        const bid_type: any = item.adset?.bidding_strategy || "";
        const status: any = item?.status || "";

        let engagement = 0;

        if (type == "website_visit" || type == "banner") {
          engagement = item.performance?.clicks || 0;
        } else {
          engagement = item.performance?.conversions || 0;
        }

        totalImpressions += item.performance?.impressions || 0;
        totalEngagement += engagement || 0;

        promotions.push({
          uuid: item.uuid,
          type,
          title: item.title || "",
          total_impression: (item.performance?.impressions || 0).toString(),
          total_engagement: (engagement || 0).toString(),
          engagement_rate: (
            ((engagement || 0) / (item.performance?.impressions || 0)) * 100 || 0
          ).toFixed(2),
          cost_per_engagement: item.adset?.maximum_cost || 0,
          cost_per_impression: item.adset?.maximum_cost || 0,
          total_cost: 0,
          status,
          bid_type,
        });

        promotionSummary[0].value = totalImpressions;
        promotionSummary[1].value = totalEngagement;
      });
    };

    const showAddPromotion = () => {
      Logic.Common.GoToRoute(`/promote/create`);
      return;

      // Logic.Common.showModal({
      //   show: true,
      //   type: "promotion_type",
      //   title: "Create new Promotion",
      //   action: (
      //     type: "banner" | "shoppable" | "website_visit" | "polls",
      //     id: string,
      //     data: any
      //   ) => {
      //     newPromotion.basic.type = type;
      //     if (type == "shoppable") {
      //       newPromotion.basic.shoplist_id = data.data.id;
      //       newPromotion.basic.shoplist = data;
      //       newPromotion.basic.name = `${data.data.name} Promotion`;
      //       newPromotion.run_until_paused = true;
      //       Logic.Common.GoToRoute("/promote/create/shoppable");
      //     } else {
      //       newPromotionMedia.file_url = id;
      //       newPromotionMedia.file = data;

      //       if (data.type == "video/mp4") {
      //         newPromotionMedia.type = "video";
      //       } else {
      //         newPromotionMedia.type = "image";
      //       }

      //       Logic.Common.GoToRoute(`/promote/create/${type}`);
      //     }
      //   },
      //   extraData: {
      //     getMediaBox: getMediaBox,
      //   },
      // });
    };

    watch(ManyAdverts, () => {
      setPromotion();
    });

    onIonViewWillEnter(() => {
      setPromotion();
    });

    onMounted(() => {
      Logic.Ad.watchProperty("ManyAdverts", ManyAdverts);
      setPromotion();
    });

    return {
      Logic,
      promotionSummary,
      promotionTypes,
      selectedPromotion,
      promotions,
      showAddPromotion,
      capitalize,
    };
  },
});
</script>
