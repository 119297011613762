import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-4" }
const _hoisted_2 = { class: "w-full py-2 px-3 border-[2px] border-primary-500 rounded-full flex flex-row items-center bg-primary-50 justify-center cursor-pointer" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_4 = { class: "w-full grid grid-cols-2 gap-3" }
const _hoisted_5 = { class: "col-span-1 flex flex-col" }
const _hoisted_6 = { class: "col-span-1 flex flex-col" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_8 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[210px]"
}
const _hoisted_10 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_AppBalanceCard = _resolveComponent("AppBalanceCard")!
  const _component_AppSummaryBox = _resolveComponent("AppSummaryBox")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_order = _resolveComponent("app-order")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        title: 'Home',
        pageKey: 'merchant-home',
        hasNotify: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (
            _ctx.Logic.Auth.AuthUser?.profile?.business &&
            _ctx.Logic.Auth.AuthUser?.profile?.business?.verification_status !=
              'completed'
          )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "w-full flex flex-col space-y-2 px-4",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/onboarding/setup')))
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    (!_ctx.Logic.Auth.AuthUser?.profile?.business?.manager_info)
                      ? (_openBlock(), _createBlock(_component_app_normal_text, {
                          key: 0,
                          class: "!text-center !text-primary-800"
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" Complete your account verification to "),
                            _createElementVNode("span", { class: "font-semibold" }, "Go Live", -1)
                          ])),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_app_normal_text, {
                          key: 1,
                          class: "!text-center !text-primary-800"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Your account verification is pending ")
                          ])),
                          _: 1
                        }))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppBalanceCard, {
                title: 'Your Earnings',
                balance: 
              _ctx.Logic.Common.convertToMoney(
                _ctx.BusinessDashboardSummary?.total_earnings,
                false,
                'ngn'
              )
            ,
                statsAmount: 
              _ctx.Logic.Common.convertToMoney(
                _ctx.BusinessDashboardSummary?.this_week_earnings,
                false,
                'ngn'
              )
            ,
                statsPercent: '',
                totalProduct: _ctx.BusinessDashboardSummary?.total_products.toString(),
                outOfStock: '',
                onButtonClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/shoplists')))
              }, null, 8, ["balance", "statsAmount", "totalProduct"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AppSummaryBox, {
                    icon: 'orders-summary',
                    title: 'Pending Orders',
                    total: 
                  (
                    _ctx.BusinessDashboardSummary?.total_pending_orders || 0
                  ).toString()
                
                  }, null, 8, ["total"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_AppSummaryBox, {
                    icon: 'list-summary',
                    title: 'Impressions',
                    total: 
                  (_ctx.BusinessDashboardSummary?.total_impressions || 0).toString()
                
                  }, null, 8, ["total"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_AppNormalText, { "custom-class": 'font-semibold' }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Recent Orders ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_AppNormalText, {
                  "custom-class": 'text-primary-500 underline !text-[12px] cursor-pointer',
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/orders')))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" View All ")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.orders.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_app_empty_state, {
                      title: `No orders here`,
                      subTitle: 'You can check back later'
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, index) => {
                      return (_openBlock(), _createBlock(_component_app_order, {
                        key: index,
                        data: order,
                        class: "cursor-pointer",
                        onClick: ($event: any) => (_ctx.showOrderInfo(order.code, order.data, true))
                      }, null, 8, ["data", "onClick"]))
                    }), 128))
                  ]))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}