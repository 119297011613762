<template>
  <app-wrapper>
    <subpage-layout :title="'Users'">
      <div class="w-full flex flex-col space-y-4">
        <div class="w-full flex flex-col space-y-2 px-4">
          <div
            @click="showAdAdmin()"
            class="w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
          >
            <app-normal-text class="text-center text-gray-700">
              + New User Invitation
            </app-normal-text>
          </div>

          <!-- Loading State -->

          <div
            class="w-full flex flex-row justify-center items-center h-[100px]"
            v-if="merchantAdminIsLoading"
          >
            <app-loading-state />
          </div>

          <template v-if="!merchantAdminIsLoading">
            <div
              v-for="(data, index) in merchantsAdmins"
              :key="index"
              class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer"
              @click="showAdminInfo(data.data)"
            >
              <div class="flex justify-between w-full">
                <AppNormalText class="!font-semibold !line-clamp-1"
                  >{{ data.name }}
                </AppNormalText>

                <app-badge
                  :color="data.status == 'active' ? 'green' : 'red'"
                  :class="'capitalize'"
                >
                  {{ data.status }}
                </app-badge>
              </div>

              <div class="h-[1px] bg-grey-50 w-full"></div>
              <div class="flex justify-between w-full">
                <AppNormalText
                  :color="'text-grey-700'"
                  class="!text-[12px]"
                  :custom-class="'gap-2'"
                >
                  Joined on
                </AppNormalText>
                <AppNormalText>
                  {{ data.joined_on }}
                </AppNormalText>
              </div>
              <div class="flex justify-between w-full">
                <AppNormalText
                  :color="'text-grey-700'"
                  class="!text-[12px]"
                  :custom-class="'gap-2'"
                >
                  Permissions
                </AppNormalText>
                <span
                  class="h-[20px] w-[20px] rounded-full bg-grey-50 flex items-center justify-center"
                >
                  <app-icon :name="'show-more'" class="!h-[5px]" />
                </span>
              </div>
            </div>
          </template>
        </div>

        <div
          v-if="merchantsAdmins.length == 0 && !merchantAdminIsLoading"
          class="w-full flex flex-col items-center justify-center h-[200px] px-4"
        >
          <app-empty-state
            :title="'No Users Found'"
            :sub-title="'You can invite your users by clicking the button above.'"
          />
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppLoadingState,
  AppIcon,
  AppBadge,
  AppEmptyState,
} from "@shpt/ui-components";
import { BusinessAdmin } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppLoadingState,
    AppIcon,
    AppBadge,
    AppWrapper,
    AppEmptyState,
  },
  name: "MerchantAdminsIndexPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Merchant Admins Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Merchant Admins",
    });

    const merchantAdminIsLoading = ref(false);

    const ManyBusinessAdmins = ref(Logic.User.ManyBusinessAdmins);

    const merchantsAdmins = reactive<
      {
        name: string;
        joined_on: string;
        status: string;
        id: string;
        data: BusinessAdmin;
      }[]
    >([]);

    const showAdAdmin = () => {
      Logic.Common.showModal({
        show: true,
        title: "Send user invitation",
        type: "send_invitation",
        action: (data: any) => {
          Logic.User.InviteBusinessAdminForm = {
            email: data.email,
            permissions: data.permissions,
          };

          Logic.User.InviteBusinessAdmin()?.then((data) => {
            if (data) {
              Logic.Common.showAlert({
                show: true,
                message: "User Invitation Sent",
                type: "success",
              });
            }
          });

          Logic.Common.showModal({
            show: false,
          });
        },
      });
    };

    const showAdminInfo = (admin: BusinessAdmin) => {
      Logic.Common.showModal({
        show: true,
        title: "User Settings",
        type: "store_admin_update",
        extraData: {
          status: admin.status,
          permissions: admin.permissions,
        },
        action: (data: any) => {
          Logic.User.UpdateBusinessAdminForm = {
            business_admin_uuid: admin.uuid,
            permissions: data.permissions,
            status: data.status,
          };

          Logic.User.UpdateBusinessAdmin()?.then((data) => {
            if (data) {
              Logic.Common.showAlert({
                show: true,
                type: "success",
                message: "User Updated",
              });
              getBusinessAdmin();
              Logic.Common.showModal({
                show: false,
              });
            }
          });
        },
      });
    };

    const setBusinessAdmins = () => {
      merchantsAdmins.length = 0;

      Logic.User.ManyBusinessAdmins?.data?.forEach((item) => {
        merchantsAdmins.push({
          id: item.uuid,
          name: item.user?.full_name || "",
          status: item.status,
          joined_on: Logic.Common.fomartDate(
            item.invitation_accepted_at || "",
            "MMMM D [at] h:mma"
          ),
          data: item,
        });
      });
    };

    const getBusinessAdmin = async () => {
      merchantAdminIsLoading.value = true;
      await Logic.User.GetBusinessAdmins(
        1,
        50,
        "CREATED_AT",
        "DESC",
        `
         {
        column: UUID
        operator: EQ
        value: "${Logic.Auth.GetCurrentBusiness()?.uuid}"
      }
        `,
        `{
        column: INVITATION_ACCEPTED_AT
        operator: NEQ
        value: null
      }`
      );
      setBusinessAdmins();
      merchantAdminIsLoading.value = false;
    };

    watch(ManyBusinessAdmins, () => {
      setBusinessAdmins();
    });

    onIonViewWillEnter(() => {
      getBusinessAdmin();
    });

    onMounted(() => {
      Logic.User.watchProperty("ManyBusinessAdmins", ManyBusinessAdmins);

      if (Logic.Common.currentBuildType() == "web") {
        getBusinessAdmin();
      }
    });

    return {
      Logic,
      merchantAdminIsLoading,
      merchantsAdmins,
      showAdminInfo,
      showAdAdmin,
    };
  },
});
</script>
