<template>
  <app-wrapper>
    <dashboard-layout
      :title="'Home'"
      :pageKey="'merchant-home'"
      :hasNotify="true"
    >
      <div class="w-full flex flex-col space-y-3 pt-4">
        <!-- Verify your account -->
        <div
          class="w-full flex flex-col space-y-2 px-4"
          v-if="
            Logic.Auth.AuthUser?.profile?.business &&
            Logic.Auth.AuthUser?.profile?.business?.verification_status !=
              'completed'
          "
          @click="Logic.Common.GoToRoute('/onboarding/setup')"
        >
          <div
            class="w-full py-2 px-3 border-[2px] border-primary-500 rounded-full flex flex-row items-center bg-primary-50 justify-center cursor-pointer"
          >
            <app-normal-text
              class="!text-center !text-primary-800"
              v-if="!Logic.Auth.AuthUser?.profile?.business?.manager_info"
            >
              Complete your account verification to
              <span class="font-semibold">Go Live</span>
            </app-normal-text>
            <app-normal-text class="!text-center !text-primary-800" v-else>
              Your account verification is pending
            </app-normal-text>
          </div>
        </div>

        <!-- Top sections , balance and order info -->
        <div class="w-full flex flex-col space-y-3 px-4">
          <AppBalanceCard
            :title="'Your Earnings'"
            :balance="
              Logic.Common.convertToMoney(
                BusinessDashboardSummary?.total_earnings,
                false,
                'ngn'
              )
            "
            :statsAmount="
              Logic.Common.convertToMoney(
                BusinessDashboardSummary?.this_week_earnings,
                false,
                'ngn'
              )
            "
            :statsPercent="''"
            :totalProduct="BusinessDashboardSummary?.total_products.toString()"
            :outOfStock="''"
            @button-clicked="Logic.Common.GoToRoute('/shoplists')"
          ></AppBalanceCard>
          <div class="w-full grid grid-cols-2 gap-3">
            <div class="col-span-1 flex flex-col">
              <AppSummaryBox
                :icon="'orders-summary'"
                :title="'Pending Orders'"
                :total="
                  (
                    BusinessDashboardSummary?.total_pending_orders || 0
                  ).toString()
                "
              ></AppSummaryBox>
            </div>

            <div class="col-span-1 flex flex-col">
              <AppSummaryBox
                :icon="'list-summary'"
                :title="'Impressions'"
                :total="
                  (BusinessDashboardSummary?.total_impressions || 0).toString()
                "
              ></AppSummaryBox>
            </div>
          </div>
        </div>

        <!-- Orders -->
        <div class="w-full flex flex-col space-y-2">
          <div class="w-full flex flex-row items-center justify-between px-4">
            <AppNormalText :custom-class="'font-semibold'">
              Recent Orders
            </AppNormalText>
            <AppNormalText
              :custom-class="'text-primary-500 underline !text-[12px] cursor-pointer'"
              @click="Logic.Common.GoToRoute('/orders')"
            >
              View All
            </AppNormalText>
          </div>

          <div
            v-if="orders.length == 0"
            class="w-full flex flex-col items-center justify-center h-[210px]"
          >
            <app-empty-state
              :title="`No orders here`"
              :subTitle="'You can check back later'"
            />
          </div>
          <div class="w-full flex flex-col space-y-3 px-4" v-else>
            <app-order
              v-for="(order, index) in orders"
              :key="index"
              :data="order"
              class="cursor-pointer"
              @click="showOrderInfo(order.code, order.data, true)"
            />
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppBalanceCard,
  AppSummaryBox,
  AppNormalText,
  AppOrder,
  AppEmptyState,
} from "@shpt/ui-components";
import {
  getStatusColor,
  showOrderInfo,
} from "@shpt/ui-components/src/composable/shop";
import { Order } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppBalanceCard,
    AppSummaryBox,
    AppNormalText,
    AppOrder,
    AppEmptyState,
    AppWrapper,
  },
  name: "MerchantHomePage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Shop",
        property: "ManyBusinessOrders",
        method: "GetBusinessOrders",
        params: [
          parseInt(Logic.Auth.GetCurrentBusiness()?.id || "0"),
          1,
          50,
          "UPDATED_AT",
          "DESC",
          ``,
        ],
        requireAuth: true,
      },
      {
        domain: "Shop",
        property: "BusinessDashboardSummary",
        method: "GetBusinessDashboardSummary",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ParentCategories",
        method: "GetParentCategories",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Home Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Home",
    });

    const ManyBusinessOrders = ref(Logic.Shop.ManyBusinessOrders);
    const BusinessDashboardSummary = ref(Logic.Shop.BusinessDashboardSummary);

    const orders = reactive<
      {
        id: string;
        code: string;
        product_name: string;
        amount: number;
        resale_commission: number;
        cashback: number;
        date: string;
        status: {
          name: string;
          type: "green" | "red" | "yellow" | "blue" | "purple";
        };
        data: Order;
      }[]
    >([]);

    const setOrders = () => {
      orders.length = 0;

      ManyBusinessOrders.value?.data.forEach((order) => {
        orders.push({
          id: order.id,
          code: `#${order.uuid.split("-")[0].toUpperCase()}`,
          product_name: `${order.items.length} item${
            order.items.length > 1 ? "s" : ""
          }`,
          amount: order.total_amount,
          date: Logic.Common.fomartDate(
            order.updated_at,
            "DD MMMM, YYYY. hh:mmA"
          ),
          cashback: order.cashback ?? 0,
          resale_commission:
            order.sold_by?.id != Logic.Auth.GetCurrentBusiness()?.id
              ? order.commission ?? 0
              : 0,
          status: {
            name: capitalize(order.status),
            type: getStatusColor(order.status),
          },
          data: order,
        });
      });
    };

    const askForNotification = () => {
      localStorage.setItem("onboarding_notification_prompted", "true");
      Logic.Common.showModal({
        show: true,
        title: "Get Notified",
        type: "ask_for_permission",
        extraData: {
          info: "Be the first to know about top cashback deals and rewards",
          cta_copy: "Enable Notification",
        },
        action: async () => {
          try {
            await Logic.Notification.registerNotifications();
          } catch (error) {
            //
          }

          Logic.Common.showModal({ show: false });
        },
      });
    };

    onIonViewWillEnter(() => {
      if (Logic.Auth.GetCurrentBusiness()?.phone_number) {
        //
      } else {
        Logic.Common.GoToRoute("/onboarding");
      }
      setOrders();
    });

    watch(ManyBusinessOrders, () => {
      setOrders();
    });

    onMounted(() => {
      Logic.Shop.watchProperty("ManyBusinessOrders", ManyBusinessOrders);
      Logic.Shop.watchProperty(
        "BusinessDashboardSummary",
        BusinessDashboardSummary
      );
      if (ManyBusinessOrders.value?.data.length == 0) {
        Logic.Shop.GetBusinessOrders(
          parseInt(Logic.Auth.GetCurrentBusiness()?.id || "0"),
          1,
          50,
          "UPDATED_AT",
          "DESC",
          ``
        );
      }
      setOrders();
      if (Logic.Common.currentBuildType() == "mobile") {
        if (
          localStorage.getItem("onboarding_notification_prompted") != "true"
        ) {
          askForNotification();
        }
      }
    });

    return {
      Logic,
      orders,
      BusinessDashboardSummary,
      showOrderInfo,
    };
  },
});
</script>
