import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col items-center justify-center space-y-2 px-4" }
const _hoisted_4 = { class: "w-full flex flex-row space-x-5 justify-around items-center py-4 px-4 pt-4" }
const _hoisted_5 = { class: "flex flex-col items-center space-y-1 justify-center" }
const _hoisted_6 = { class: "flex flex-col items-center space-y-1 justify-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col items-center space-y-1 justify-center"
}
const _hoisted_8 = { class: "w-full flex flex-col items-center justify-center space-y-3 px-4" }
const _hoisted_9 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_10 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_11 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_12 = { class: "w-full flex flex-col items-center justify-center space-y-3 px-4 pt-1" }
const _hoisted_13 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_14 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_15 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_16 = {
  key: 0,
  class: "w-full flex flex-row item-center justify-between pt-1"
}
const _hoisted_17 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_18 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_19 = {
  key: 1,
  class: "w-full flex flex-row item-center justify-between pt-1"
}
const _hoisted_20 = {
  key: 2,
  class: "w-full flex flex-row item-center justify-between pt-1"
}
const _hoisted_21 = {
  key: 3,
  class: "w-full flex flex-row item-center justify-between pt-1"
}
const _hoisted_22 = { class: "w-full flex flex-col items-center justify-center space-y-3 px-4 pt-1" }
const _hoisted_23 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_24 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_25 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_26 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_27 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_28 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_29 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_30 = { class: "w-full flex flex-row item-center justify-between pt-1" }
const _hoisted_31 = {
  key: 0,
  class: "!text-[10px] !text-grey-400 !font-normal"
}
const _hoisted_32 = { class: "w-full flex flex-row items-center justify-between pt-4" }
const _hoisted_33 = { class: "w-full flex flex-col" }
const _hoisted_34 = { class: "w-full flex flex-col items-center space-y-3 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-3 py-4" }
const _hoisted_35 = { class: "w-full flex flex-row space-x-2 items-center justify-center h-full" }
const _hoisted_36 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_37 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_38 = { class: "w-full flex flex-col items-center justify-center space-y-3 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-4 py-4" }
const _hoisted_39 = { class: "w-full flex flex-col space-y-1 items-center h-full" }
const _hoisted_40 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_41 = { class: "w-[80%] h-[7px] relative" }
const _hoisted_42 = { class: "w-full flex flex-col items-center justify-center space-y-2 border-grey-200 border-[1px] rounded-[10px] h-[300px] px-4 py-4" }
const _hoisted_43 = { class: "w-full flex flex-col space-y-1 items-center h-full" }
const _hoisted_44 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_45 = { class: "w-[80%] h-[7px] relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_app_swiper = _resolveComponent("app-swiper")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Ad Insights' }, {
        default: _withCtx(() => [
          (_ctx.SingleAdvert)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.SingleAdvert.type == 'banner')
                      ? (_openBlock(), _createBlock(_component_app_image_loader, {
                          key: 0,
                          photoUrl: _ctx.SingleAdvert?.media?.metadata?.image_url || '',
                          class: "w-full !h-[60px] rounded-[10px]"
                        }, null, 8, ["photoUrl"]))
                      : (_openBlock(), _createBlock(_component_app_image_loader, {
                          key: 1,
                          "photo-url": 
                _ctx.SingleAdvert.type == 'shoppable'
                  ? _ctx.SingleAdvert.media?.metadata?.shoppable_type != 'product'
                    ? (_ctx.SingleAdvert.media?.metadata?.image_url
                        ? _ctx.SingleAdvert.media?.metadata?.image_url
                        : _ctx.SingleAdvert.media?.shoplist?.base_image_url) || ''
                    : _ctx.SingleAdvert.media?.metadata?.image_url || ''
                  : _ctx.SingleAdvert?.media?.metadata?.image_url || ''
              ,
                          class: "h-[200px] w-[130px] !rounded-[10px]"
                        }, null, 8, ["photo-url"])),
                    _createVNode(_component_app_normal_text, { class: "text-grey-500 w-full text-center" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(
                  _ctx.SingleAdvert.ad_insights.last_updated,
                  "MMMM D [at] h:mma"
                )), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_app_icon, {
                      name: 'black-heart',
                      class: "!h-[15px]"
                    }),
                    _createVNode(_component_app_normal_text, { class: "!font-normal" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                _ctx.SingleAdvert.ad_insights?.engagements.likes,
                false,
                ""
              )), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_app_icon, {
                      name: 'black-send',
                      class: "!h-[15px]"
                    }),
                    _createVNode(_component_app_normal_text, { class: "!font-normal" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                _ctx.SingleAdvert.ad_insights?.engagements.shares,
                false,
                ""
              )), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.SingleAdvert.type == 'shoppable')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_app_icon, {
                          name: 'black-shop',
                          class: "!h-[16px]"
                        }),
                        _createVNode(_component_app_normal_text, { class: "!font-normal" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                (_ctx.SingleAdvert.ad_insights?.engagements.purchases || 0) +
                  (_ctx.SingleAdvert.ad_insights?.engagements.saves || 0),
                false,
                ""
              )), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _cache[25] || (_cache[25] = _createElementVNode("div", { class: "w-full h-[4px] bg-gray-50 dark:!bg-gray-400" }, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_app_header_text, { class: "flex flex-row items-center space-x-2 !text-sm" }, {
                      default: _withCtx(() => [
                        _cache[1] || (_cache[1] = _createTextVNode(" Reach ")),
                        _createVNode(_component_app_icon, {
                          name: "info-circle",
                          class: "h-[17px] ml-2"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_app_header_text, { class: "text-center" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.SingleAdvert.ad_insights?.reach.total,
                  false,
                  ""
                )), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "text-center !text-gray-400 !text-sm" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Total accounts reached so far ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Impressions ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.SingleAdvert.ad_insights.reach.impressions,
                  false,
                  ""
                )), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "w-full h-[4px] bg-gray-50 dark:!bg-gray-400" }, null, -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_app_header_text, { class: "flex flex-row items-center space-x-2 !text-sm" }, {
                      default: _withCtx(() => [
                        _cache[4] || (_cache[4] = _createTextVNode(" Engagements ")),
                        _createVNode(_component_app_icon, {
                          name: "info-circle",
                          class: "h-[17px] ml-2"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.SingleAdvert.ad_insights.engagements.total,
                  false,
                  ""
                )), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Clicks ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.clicks,
                    false,
                    ""
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (
                _ctx.SingleAdvert.type == 'website_visit' ||
                _ctx.SingleAdvert.type == 'banner'
              )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Website Visits ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_app_normal_text, { class: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.website_visits,
                    false,
                    ""
                  )), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Likes ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.likes,
                    false,
                    ""
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Shares ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.shares,
                    false,
                    ""
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (_ctx.SingleAdvert.type == 'shoppable')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Saved ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_app_normal_text, { class: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.saves,
                    false,
                    ""
                  )), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.SingleAdvert.type == 'shoppable')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Purchases ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_app_normal_text, { class: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.purchases,
                    false,
                    ""
                  )), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.SingleAdvert.type == 'polls')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode(" Polls Completed ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_app_normal_text, { class: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights.engagements.polls_completed,
                    false,
                    ""
                  )), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "w-full h-[4px] bg-gray-50 dark:!bg-gray-400" }, null, -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_app_header_text, { class: "flex flex-row items-center space-x-2 !text-sm" }, {
                      default: _withCtx(() => [
                        _cache[12] || (_cache[12] = _createTextVNode(" Ad ")),
                        _createVNode(_component_app_icon, {
                          name: "info-circle",
                          class: "h-[17px] ml-2"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_app_header_text, { class: "text-center" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.totalAdSummary.total_count,
                  false,
                  ""
                )), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "text-center !text-gray-400 !text-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.totalAdSummary.comment), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode(" Amount Spent ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.ad_insights?.ad?.amount_spent,
                    false,
                    "ngn"
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode(" Daily Ad Budget ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.SingleAdvert.adset?.maximum_cost,
                    false,
                    "ngn"
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Cost per " + _toDisplayString(_ctx.SingleAdvert?.adset?.bidding_strategy == "cpm"
                    ? "Impression"
                    : "Conversion"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    (_ctx.SingleAdvert.ad_insights?.ad?.amount_spent || 0) /
                      (_ctx.totalAdSummary.total_count > 0
                        ? _ctx.totalAdSummary.total_count
                        : 1),
                    false,
                    "ngn"
                  )), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Ad Type ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { class: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.SingleAdvert.type?.split("_").join(" ") || "")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-grey-700 dark:!text-gray-400 !leading-tight" }, {
                        default: _withCtx(() => [
                          _cache[16] || (_cache[16] = _createTextVNode(" Status ")),
                          _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                          (_ctx.SingleAdvert.adset?.run_until_paused)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.Logic.Common.momentInstance(_ctx.SingleAdvert.created_at).diff(
                      new Date(),
                      "hours"
                    )) + " hours elapsed", 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.SingleAdvert.adset?.run_until_paused
                    ? "Run until paused"
                    : `Run until ${_ctx.Logic.Common.fomartDate(
                        _ctx.SingleAdvert.adset?.ad_end_date || "",
                        "MMMM D, YYYY"
                      )}`), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_app_header_text, { class: "flex flex-row items-center space-x-2 !text-xs !text-grey-800 !font-semibold" }, {
                      default: _withCtx(() => [
                        _cache[18] || (_cache[18] = _createTextVNode(" Ad audience ")),
                        _createVNode(_component_app_icon, {
                          name: "info-circle",
                          class: "h-[15px] ml-2"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_app_swiper, {
                      "show-pagination": true,
                      "free-mode": false,
                      "space-between": 5,
                      "slide-per-view": 1,
                      currentSlidePosition: _ctx.currentSlidePosition,
                      swiperClass: '',
                      modelValue: _ctx.slidePosition,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slidePosition) = $event)),
                      id: "swiperContainer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_swiper_slide, { class: "!h-[300px]" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_app_normal_text, { class: "w-full text-center !text-grey-800 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode(" Gender ")
                                ])),
                                _: 1
                              }),
                              _createElementVNode("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createVNode(_component_app_normal_text, { class: "!font-bold" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.adAnalytics.gender.female) + "% ", 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_app_normal_text, { class: "!text-grey-400 !text-[10px] flex flex-row space-x-1 items-center" }, {
                                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                                      _createElementVNode("span", null, "Female", -1),
                                      _createElementVNode("span", { class: "h-[5px] w-[5px] bg-secondary-500 rounded-full" }, null, -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex flex-col relative" }, [
                                  _createElementVNode("div", {
                                    class: "donut-chart",
                                    id: "donutChart"
                                  })
                                ], -1)),
                                _createElementVNode("div", _hoisted_37, [
                                  _createVNode(_component_app_normal_text, { class: "!font-bold" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.adAnalytics.gender.male) + "% ", 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_app_normal_text, { class: "!text-grey-400 !text-[10px] flex flex-row space-x-1 items-center" }, {
                                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                                      _createElementVNode("span", null, "Male", -1),
                                      _createElementVNode("span", { class: "h-[5px] w-[5px] bg-primary-400 rounded-full" }, null, -1)
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_swiper_slide, { class: "!h-[300px]" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_38, [
                              _createVNode(_component_app_normal_text, { class: "w-full text-center !text-grey-800 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode(" Age Range ")
                                ])),
                                _: 1
                              }),
                              _createElementVNode("div", _hoisted_39, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adAnalytics.age_range, (item, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "w-full flex flex-col",
                                    key: index
                                  }, [
                                    _createVNode(_component_app_normal_text, { class: "!text-left !text-[11px] !text-grey-800 dark:!text-gray-400" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("div", _hoisted_40, [
                                      _createElementVNode("div", _hoisted_41, [
                                        _createElementVNode("div", {
                                          class: "absolute top-0 left-0 h-full bg-secondary-500 rounded-r-full",
                                          style: _normalizeStyle(`width: ${item.percentage}%;`)
                                        }, null, 4)
                                      ]),
                                      _createVNode(_component_app_normal_text, { class: "!text-right !text-[12px] !font-semibold" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.percentage) + "% ", 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_swiper_slide, { class: "!h-[300px]" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_42, [
                              _createVNode(_component_app_normal_text, { class: "w-full text-center !text-grey-800 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode(" Top Locations ")
                                ])),
                                _: 1
                              }),
                              _createElementVNode("div", _hoisted_43, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adAnalytics.locations, (item, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "w-full flex flex-col",
                                    key: index
                                  }, [
                                    _createVNode(_component_app_normal_text, { class: "!text-left !text-[11px] !text-grey-800 dark:!text-gray-400" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("div", _hoisted_44, [
                                      _createElementVNode("div", _hoisted_45, [
                                        _createElementVNode("div", {
                                          class: "absolute top-0 left-0 h-full bg-secondary-500 rounded-r-full",
                                          style: _normalizeStyle(`width: ${item.percentage}%;`)
                                        }, null, 4)
                                      ]),
                                      _createVNode(_component_app_normal_text, { class: "!text-right !text-[12px] !font-semibold" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.percentage) + "% ", 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["currentSlidePosition", "modelValue"])
                  ])
                ]),
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "h-[50px]" }, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}