<template>
  <app-wrapper>
    <subpage-layout :title="'All Orders'">
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-3">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in filters"
                :key="index"
                :class="`px-4 py-1  cursor-pointer ${
                  selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-800'
                }  rounded-[20px]  flex flex-row items-center justify-center`"
                @click="selectedFilter = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-4">
        <div class="w-full flex flex-col space-y-3 px-4">
          <div
            v-if="orders.length == 0"
            class="w-full flex flex-col items-center justify-center h-[300px]"
          >
            <app-empty-state
              :title="`No ${
                selectedFilter == 'all' ? '' : selectedFilter
              } orders here`"
              :subTitle="'You can check back later'"
            />
          </div>
          <template v-else>
            <app-order
              v-for="(order, index) in orders"
              :key="index"
              class="cursor-pointer"
              :data="order"
              @click="showOrderInfo(order.code, order.data, true)"
            />
          </template>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { AppOrder, AppNormalText, AppEmptyState } from "@shpt/ui-components";
import {
  getStatusColor,
  showOrderInfo,
} from "@shpt/ui-components/src/composable/shop";
import { Order } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppOrder,
    AppNormalText,
    AppEmptyState,
    AppWrapper,
  },
  name: "MerchantOrdersIndexPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shop",
        property: "ManyBusinessOrders",
        method: "GetBusinessOrders",
        params: [
          parseInt(Logic.Auth.GetCurrentBusiness()?.id || "0"),
          1,
          50,
          "UPDATED_AT",
          "DESC",
          ``,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Orders Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Orders",
    });

    const ManyBusinessOrders = ref(Logic.Shop.ManyBusinessOrders);

    const selectedFilter = ref("all");

    const filters = reactive([
      {
        id: "all",
        title: "All",
      },
      {
        id: "pending",
        title: "Pending",
      },
      {
        id: "processing",
        title: "Processing",
      },
      {
        id: "completed",
        title: "Completed",
      },
      {
        id: "cancelled",
        title: "Cancelled",
      },
      {
        id: "return",
        title: "Returned",
      },
    ]);

    const orders = reactive<
      {
        id: string;
        code: string;
        product_name: string;
        amount: number;
        resale_commission: number;
        cashback: number;
        date: string;
        status: {
          name: string;
          type: "green" | "red" | "yellow" | "blue" | "purple";
        };
        data: Order;
      }[]
    >([]);

    const setOrders = () => {
      orders.length = 0;

      ManyBusinessOrders.value?.data.forEach((order) => {
        const eachOrder = {
          id: order.id,
          code: `#${order.uuid.split("-")[0].toUpperCase()}`,
          product_name: `${order.items.length} item${
            order.items.length > 1 ? "s" : ""
          }`,
          amount: order.total_amount,
          date: Logic.Common.fomartDate(
            order.updated_at,
            "DD MMMM, YYYY. hh:mmA"
          ),
          cashback: order.cashback ?? 0,
          resale_commission:
            order.sold_by?.id != Logic.Auth.GetCurrentBusiness()?.id
              ? order.commission ?? 0
              : 0,
          status: {
            name: capitalize(order.status),
            type: getStatusColor(order.status),
          },
          data: order,
        };
        if (selectedFilter.value == "all") {
          orders.push(eachOrder);
        } else {
          if (selectedFilter.value == "pending") {
            if (order.status == "pending") {
              orders.push(eachOrder);
            }
          }
          if (selectedFilter.value == "processing") {
            if (
              order.status == "confirmed" ||
              order.status == "picked_up" ||
              order.status == "in_transit"
            ) {
              orders.push(eachOrder);
            }
          }
          if (selectedFilter.value == "completed") {
            if (order.status == "completed") {
              orders.push(eachOrder);
            }
          }
          if (selectedFilter.value == "cancelled") {
            if (order.status == "cancelled") {
              orders.push(eachOrder);
            }
          }

          if (selectedFilter.value == "return") {
            if (order.status.includes("return")) {
              orders.push(eachOrder);
            }
          }
        }
      });
    };

    watch(selectedFilter, () => {
      setOrders();
    });

    watch(ManyBusinessOrders, () => {
      setOrders();
    });

    onIonViewWillEnter(() => {
      setOrders();
    });

    onMounted(() => {
      Logic.Shop.watchProperty("ManyBusinessOrders", ManyBusinessOrders);
      setOrders();
    });

    return {
      Logic,
      orders,
      filters,
      selectedFilter,
      showOrderInfo,
    };
  },
});
</script>
