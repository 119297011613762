import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-row px-4 justify-between items-center" }
const _hoisted_4 = { class: "flex flex-col space-y-1 justify-start" }
const _hoisted_5 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_6 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_7 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 px-4"
}
const _hoisted_8 = { class: "w-full flex flex-col space-y-1 px-4" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_10 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_11 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_12 = ["onClick", "id"]
const _hoisted_13 = {
  key: 0,
  class: "w-full grid grid-cols-2 gap-3 pb-2"
}
const _hoisted_14 = { class: "w-full flex flex-row flex-wrap" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_17 = { class: "flex flex-col items-end w-full justify-between space-y-1" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_19 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_20 = { class: "w-full flex flex-col" }
const _hoisted_21 = { class: "w-full flex flex-row pt-3" }
const _hoisted_22 = { class: "!font-bold" }
const _hoisted_23 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 px-4"
}
const _hoisted_24 = { class: "w-full grid grid-cols-2 gap-2" }
const _hoisted_25 = { class: "!h-full !w-full flex flex-col justify-between" }
const _hoisted_26 = {
  class: "w-full flex flex-row justify-between pb-2",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_27 = { class: "h-[30px] w-[30px] rounded-full flex flex-row items-center justify-center border-[2px] border-white cursor-pointer" }
const _hoisted_28 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_29 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_30 = ["onClick", "id"]
const _hoisted_31 = { class: "w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black" }
const _hoisted_32 = { class: "flex flex-col space-y-[3px]" }
const _hoisted_33 = { class: "flex flex-row space-x-1 justify-start flex-grow items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_app_swiper = _resolveComponent("app-swiper")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_product = _resolveComponent("app-product")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        mainSectionClass: 
        _ctx.Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      
      }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.fromPreview)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  class: _normalizeClass(`!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white`),
                  onClick: _ctx.handleAddToCart,
                  id: "add-to-cart-button"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(_toDisplayString("Add to Offer"))
                  ])),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_header_text, { class: "!text-base" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.SingleShoplistProduct?.name), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_app_normal_text, { class: "!text-success-500" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Earn " + _toDisplayString(_ctx.mediaData?.affiliate_commission_percentage?.toFixed(2)) + "% ", 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            (_ctx.SingleShoplistProduct?.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_app_normal_text, { class: "!text-left !text-[#5A5A5A] dark:!text-grey-200" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.SingleShoplistProduct?.description), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productLists, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "w-full flex flex-col space-y-3 border-b-[1px] border-grey-100 py-3"
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_app_swiper, {
                      "free-mode": false,
                      "show-pagination": false,
                      "space-between": 10,
                      "slide-per-view": 1,
                      currentSlidePosition: _ctx.currentSlidePosition,
                      "custom-class": "h-[300px]",
                      swiperClass: '',
                      modelValue: _ctx.slidePosition,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.slidePosition) = $event)),
                      id: "swiperContainerProducts"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
                          return (_openBlock(), _createBlock(_component_swiper_slide, {
                            class: "w-full h-full",
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_app_image_loader, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFullImages = true)),
                                photoUrl: image,
                                class: "rounded-[10px] h-[300px] w-full border-[1px] border-gray-200 dark:!border-gray-700 cursor-pointer"
                              }, null, 8, ["photoUrl"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["currentSlidePosition", "modelValue"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "flex flex-row cursor-pointer pr-2",
                            key: index,
                            onClick: ($event: any) => (_ctx.currentSlidePosition = index),
                            id: `image${index}`
                          }, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: image,
                              class: _normalizeClass(`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        _ctx.currentSlidePosition == index
                          ? '!border-primary-main'
                          : ''
                      }`)
                            }, null, 8, ["photoUrl", "class"])
                          ], 8, _hoisted_12))
                        }), 128))
                      ])
                    ]),
                    (_ctx.productAttributes.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productAttributes, (attribute, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "col-span-1 flex flex-col space-y-[1px]",
                              key: index
                            }, [
                              _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(attribute.name), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("div", _hoisted_14, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute.existing_values, (attributeValue, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "pr-2 pt-2 flex flex-col cursor-pointer",
                                    key: index,
                                    onClick: ($event: any) => (_ctx.selectOption(attribute.name, attributeValue))
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(`px-2 py-[2px] bg-primary-50 dark:!bg-primary-500 rounded-[4px] flex flex-row items-center justify-center space-x-2 ${
                          _ctx.itemSelected(attribute.name, attributeValue)
                            ? '!border-primary-main !border-[2px] dark:!border-primary-50'
                            : '!border-[2px] !border-transparent'
                        }`)
                                    }, [
                                      _createVNode(_component_app_normal_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(attributeValue), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ], 2)
                                  ], 8, _hoisted_15))
                                }), 128))
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_app_header_text, { class: "!text-sm text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_app_badge, {
                            color: 
                        item.reward_type == 'cashback' ? 'purple' : 'purple'
                      ,
                            class: "capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Save ₦" + _toDisplayString(_ctx.numberToAbbrev(item.reward_point)), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"]),
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200" }, null, -1)),
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900 dark:!text-grey-200" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.main_category), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          ((_ctx.currentVariant?.inventory_quantity || 0) > 0)
                            ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 0,
                                class: _normalizeClass(` ${
                        (_ctx.currentVariant?.inventory_quantity || 0) > 5
                          ? '!text-success-main'
                          : '!text-secondary-700'
                      }`)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.currentVariant?.inventory_quantity), 1),
                                  _cache[7] || (_cache[7] = _createTextVNode(" left in stock "))
                                ]),
                                _: 1
                              }, 8, ["class"]))
                            : (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 1,
                                class: "!text-error-main"
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Out of stock ")
                                ])),
                                _: 1
                              }))
                        ])
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(_component_app_header_text, { class: "!text-base !font-semibold" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Similar products ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.similarProducts, (item, index) => {
                      return (_openBlock(), _createBlock(_component_app_product, {
                        key: index,
                        item: item,
                        onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                      }, null, 8, ["item", "onClick"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ]),
          (_ctx.showFullImages)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: "#modal"
              }, [
                _createVNode(_component_fixed_container, {
                  class: "!z-[9999999999999999] !h-full !w-full !fixed !top-0 !left-0",
                  baseColor: "bg-black !bg-opacity-50",
                  customClass: '!border-none !h-screen ',
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showFullImages = false))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, [
                          _createVNode(_component_app_icon, {
                            name: "close-white",
                            customClass: '!h-[10px]'
                          })
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: "w-full flex flex-col !h-[80%]",
                        onClick: _withModifiers(($event: any) => (null), ["stop"])
                      }, [
                        _createVNode(_component_app_swiper, {
                          "free-mode": false,
                          "show-pagination": false,
                          "space-between": 10,
                          "slide-per-view": 1,
                          currentSlidePosition: _ctx.currentSlidePosition,
                          "custom-class": "h-full",
                          swiperClass: 'h-full',
                          modelValue: _ctx.slidePosition,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.slidePosition) = $event)),
                          id: "swiperContainerProductsFull"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
                              return (_openBlock(), _createBlock(_component_swiper_slide, {
                                class: "w-full h-full",
                                key: index
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_image_loader, {
                                    photoUrl: image,
                                    class: "rounded-[10px] h-full w-full border-[1px] border-gray-200 dark:!border-gray-700"
                                  }, null, 8, ["photoUrl"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["currentSlidePosition", "modelValue"])
                      ]),
                      _createElementVNode("div", {
                        class: "w-full flex flex-col pt-1",
                        onClick: _withModifiers(($event: any) => (null), ["stop"])
                      }, [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "flex flex-row cursor-pointer pr-2",
                                key: index,
                                onClick: _withModifiers(($event: any) => (_ctx.currentSlidePosition = index), ["stop"]),
                                id: `image${index}`
                              }, [
                                _createVNode(_component_app_image_loader, {
                                  photoUrl: image,
                                  class: _normalizeClass(`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        _ctx.currentSlidePosition == index
                          ? '!border-primary-main'
                          : ''
                      }`)
                                }, null, 8, ["photoUrl", "class"])
                              ], 8, _hoisted_30))
                            }), 128))
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_fixed_container, {
            customClass: "!px-0",
            class: "z-50"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createVNode(_component_app_header_text, { class: "!text-base" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalCost, true, "ngn")), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_app_normal_text, { class: "!text-[#898989] dark:!text-grey-200" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Earn " + _toDisplayString(_ctx.mediaData?.affiliate_commission_percentage?.toFixed(2)) + "% ", 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", {
                  class: "py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleAddToCart && _ctx.handleAddToCart(...args)))
                }, [
                  _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Add to Offer ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["mainSectionClass"])
    ]),
    _: 1
  }))
}