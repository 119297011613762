import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_3 = { class: "w-full flex flex-row item-center space-x-3" }
const _hoisted_4 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_5 = { class: "flex flex-col space-y-1" }
const _hoisted_6 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_7 = { class: "flex space-x-2 items-center" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[10px] px-4 pb-4 pt-2" }
const _hoisted_9 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_10 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[250px]"
}
const _hoisted_11 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[250px]"
}
const _hoisted_12 = {
  key: 1,
  class: "w-full flex flex-col space-y-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_transaction = _resolveComponent("app-transaction")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Payments',
        topBarType: "plain",
        pageKey: 'merchant-payments',
        hasNotify: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_button, {
                  "custom-class": '!py-2 !px-4 !rounded-[8px]',
                  class: "!bg-primary-50 !text-primary-main !border-[1px] !border-primary-main",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/payments/accept_payments')))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Accept Payment ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_button, {
                  "custom-class": '!py-2 !px-4 !rounded-[8px]',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/payments/confirm_payment')))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Confirm Payment ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_app_image_loader, {
                "custom-class": 'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500 relative',
                "photo-url": '/images/card-bg.svg'
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_AppNormalText, { color: 'text-white' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedWalletItem.title), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_app_header_text, { color: 'text-white' }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.canView
                    ? `${_ctx.Logic.Common.convertToMoney(
                        _ctx.selectedWalletItem.balance.amount
                      )}`
                    : "*********"), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.canView)
                        ? (_openBlock(), _createBlock(_component_app_icon, {
                            key: 0,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.canView = false)),
                            "custom-class": 'w-[16px] cursor-pointer',
                            name: 'show-white'
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.canView)
                        ? (_openBlock(), _createBlock(_component_app_icon, {
                            key: 1,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.canView = true)),
                            "custom-class": 'w-[16px] cursor-pointer',
                            name: 'hide-white'
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_app_normal_text, {
                        size: 'small',
                        "custom-class": '!font-extralight',
                        class: "font-extralight",
                        color: 'text-white'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" + " + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.selectedWalletItem.balance.this_week_earnings,
                      false,
                      "ngn"
                    )) + " Today ", 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_app_normal_text, { class: "!font-semibold !text-sm text-left" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Recent payments ")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.transactionIsLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_app_loading_state, { class: "text-primary-500" })
                  ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.transactions.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_app_empty_state, {
                            title: 'No transactions yet',
                            "sub-title": 'Your transactions will appear here'
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (item, index) => {
                            return (_openBlock(), _createBlock(_component_app_transaction, {
                              key: index,
                              data: item
                            }, null, 8, ["data"]))
                          }), 128))
                        ]))
                  ], 64))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}