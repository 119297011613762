<template>
  <app-wrapper>
    <subpage-layout
      :title="'Payments'"
      topBarType="plain"
      :pageKey="'merchant-payments'"
      :hasNotify="true"
    >
      <div class="w-full flex flex-col space-y-3">
        <!-- Wallets -->
        <div class="w-full flex flex-col space-y-2 px-4">
          <div class="w-full flex flex-row item-center space-x-3">
            <!-- Actions -->
            <app-button
              :custom-class="'!py-2 !px-4 !rounded-[8px]'"
              class="!bg-primary-50 !text-primary-main !border-[1px] !border-primary-main"
              @click="Logic.Common.GoToRoute('/payments/accept_payments')"
            >
              Accept Payment
            </app-button>

            <app-button
              :custom-class="'!py-2 !px-4 !rounded-[8px]'"
              @click="Logic.Common.GoToRoute('/payments/confirm_payment')"
            >
              Confirm Payment
            </app-button>
          </div>

          <app-image-loader
            :custom-class="'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500 relative'"
            :photo-url="'/images/card-bg.svg'"
          >
            <div class="w-full flex flex-row space-x-2">
              <AppNormalText :color="'text-white'">
                {{ selectedWalletItem.title }}</AppNormalText
              >
            </div>

            <div class="flex flex-col space-y-1">
              <div class="flex flex-row space-x-2 items-center">
                <app-header-text :color="'text-white'">{{
                  canView
                    ? `${Logic.Common.convertToMoney(
                        selectedWalletItem.balance.amount
                      )}`
                    : "*********"
                }}</app-header-text>
                <app-icon
                  v-if="canView"
                  @click="canView = false"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'show-white'"
                ></app-icon>
                <app-icon
                  @click="canView = true"
                  v-if="!canView"
                  :custom-class="'w-[16px] cursor-pointer'"
                  :name="'hide-white'"
                ></app-icon>
              </div>

              <div class="flex space-x-2 items-center">
                <app-normal-text
                  :size="'small'"
                  :custom-class="'!font-extralight'"
                  class="font-extralight"
                  :color="'text-white'"
                >
                  +
                  {{
                    Logic.Common.convertToMoney(
                      selectedWalletItem.balance.this_week_earnings,
                      false,
                      "ngn"
                    )
                  }}
                  Today
                </app-normal-text>
              </div>
            </div>
          </app-image-loader>
        </div>

        <!-- Transactions -->

        <div
          class="w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[10px] px-4 pb-4 pt-2"
        >
          <div class="w-full flex flex-row items-center justify-between">
            <app-normal-text class="!font-semibold !text-sm text-left">
              Recent payments
            </app-normal-text>

            <!-- <app-normal-text class="!text-[#9D4EDD] underline !text-[12px]">
              View all
            </app-normal-text> -->
          </div>

          <template v-if="transactionIsLoading">
            <div
              class="w-full flex flex-col justify-center items-center h-[250px]"
            >
              <app-loading-state class="text-primary-500" />
            </div>
          </template>
          <template v-else>
            <div
              class="w-full flex flex-col justify-center items-center h-[250px]"
              v-if="transactions.length == 0"
            >
              <app-empty-state
                :title="'No transactions yet'"
                :sub-title="'Your transactions will appear here'"
              />
            </div>

            <div class="w-full flex flex-col space-y-5" v-else>
              <app-transaction
                v-for="(item, index) in transactions"
                :key="index"
                :data="item"
              />
            </div>
          </template>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppImageLoader,
  AppIcon,
  AppHeaderText,
  AppTransaction,
  AppEmptyState,
  AppLoadingState,
  AppButton,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppIcon,
    AppHeaderText,
    AppTransaction,
    AppEmptyState,
    AppLoadingState,
    AppWrapper,
    AppButton,
  },
  name: "MerchantPaymentsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "BusinessWallet",
        method: "GetBusinessWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shop",
        property: "BusinessDashboardSummary",
        method: "GetBusinessDashboardSummary",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Payments Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Payments",
    });

    const canView = ref(true);
    const BusinessWallet = ref(Logic.Wallet.BusinessWallet);
    const BusinessDashboardSummary = ref(Logic.Shop.BusinessDashboardSummary);
    const BusinessWalletTransactions = ref(
      Logic.Wallet.BusinessWalletTransactions
    );

    const selectedWallet = ref("payment_earnings");

    const transactionIsLoading = ref(false);

    const selectedWalletItem = computed(() => {
      return walletTypes.filter(
        (eachItem) => eachItem.id == selectedWallet.value
      )[0];
    });

    const walletTypes = reactive([
      {
        id: "payment_earnings",
        name: "Payments",
        title: "Balance",
        balance: {
          amount: 30200020,
          this_week_earnings: 10000,
        },
      },
    ]);

    const transactions = reactive<
      {
        id: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
      }[]
    >([]);

    const setWallets = () => {
      walletTypes[0].balance.amount = BusinessWallet.value?.total_balance || 0;
      walletTypes[0].balance.this_week_earnings =
        BusinessDashboardSummary.value?.this_week_earnings || 0;
    };

    const setTransactions = () => {
      transactions.length = 0;
      if (selectedWallet.value == "payment_earnings") {
        BusinessWalletTransactions.value?.data.forEach((item) => {
          if (item.chargeable_type == "shpt_to_shpt") {
            transactions.push({
              id: item.id,
              description: item.description,
              type: item.dr_or_cr,
              time: Logic.Common.fomartDate(
                item.created_at,
                "MMM D, YYYY | h:mma"
              ),
              amount: item.amount,
              category: "Payments",
            });
          }
        });
      }
    };

    const getTransactions = async () => {
      transactionIsLoading.value = true;
      await Logic.Wallet.GetTransactionsByWalletId(
        "business",
        parseInt(BusinessWallet.value?.id || "0"),
        1,
        50,
        "UPDATED_AT",
        "DESC"
      );
      transactionIsLoading.value = false;
    };

    watch([BusinessWalletTransactions], () => {
      setTransactions();
    });

    watch([BusinessDashboardSummary, BusinessWallet], () => {
      setWallets();
    });

    watch(selectedWallet, () => {
      getTransactions();
    });

    onIonViewWillEnter(() => {
      setWallets();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("BusinessWallet", BusinessWallet);
      Logic.Wallet.watchProperty(
        "BusinessWalletTransactions",
        BusinessWalletTransactions
      );
      Logic.Shop.watchProperty(
        "BusinessDashboardSummary",
        BusinessDashboardSummary
      );
      setWallets();
      getTransactions();
    });

    return {
      Logic,
      walletTypes,
      selectedWallet,
      canView,
      selectedWalletItem,
      transactions,
      BusinessWallet,
      transactionIsLoading,
    };
  },
});
</script>
