<template>
  <app-wrapper>
    <dashboard-layout
      :title="'Offers'"
      topBarType="plain"
      :pageKey="'merchant-shoplists'"
      :hasAddButton="Logic.Auth.BusinessUserCan('manage_shoplists')"
      :addButtonAction="showAddShoplist"
    >
      <div class="w-full flex flex-col space-y-3 px-4 pt-4">
        <!-- Search and add -->
        <div class="w-full flex flex-row space-x-2 items-center">
          <AppTextField :placeholder="`Search offers`" v-model="searchQuery">
            <template v-slot:inner-prefix>
              <app-icon :custom-class="''" :name="'search'"></app-icon>
            </template>
            <!-- <template v-slot:inner-suffix>
              <app-icon :custom-class="''" :name="'filter-icon'"></app-icon>
            </template> -->
          </AppTextField>
        </div>

        <div class="w-full flex flex-col">
          <app-switch :items="shoplistSwitch" v-model="selectedSwitch" />
        </div>

        <!-- Shoplists -->
        <template v-if="shopLists.length > 0">
          <div class="w-full flex flex-col">
            <app-virtual-scroller
              :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
              :data="shopLists"
              :pagination="paginatorInfo"
              :fetchMore="fetchMoreShoplists"
              :direction="'vertical'"
              ref="productVirtualScrollerRef"
              ><template #item-content="{ index, item }">
                <div class="col-span-1 flex flex-col" :key="index">
                  <app-shoplist
                    :item="item"
                    class="cursor-pointer"
                    @click="
                      !item?.is_grid && !item?.video_url
                        ? null
                        : goToShoplist(item.data)
                    "
                  />
                </div>
              </template>
            </app-virtual-scroller>
          </div>
        </template>
        <div
          v-else
          class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
        >
          <app-empty-state
            :title="`No ${
              selectedSwitch == 'active' ? 'Active' : 'Inactive'
            } Offers Here`"
            :subTitle="`All ${
              selectedSwitch == 'active' ? 'Active' : 'Inactive'
            } offers will show up here`"
          >
          </app-empty-state>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import {
  AppTextField,
  AppIcon,
  AppSwitch,
  AppEmptyState,
  AppShoplist,
  AppVirtualScroller,
} from "@shpt/ui-components";
import { getMediaBox } from "@shpt/ui-components/src/composable";
import { Collection } from "@shpt/ui-components/src/gql/graphql";
import { MediaBox } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import { PaginatorInfo } from "@shpt/logic/src/gql/graphql";

export default defineComponent({
  components: {
    AppTextField,
    AppIcon,
    AppShoplist,
    AppSwitch,
    AppEmptyState,
    AppWrapper,
    AppVirtualScroller,
  },
  name: "MerchantShoplistPage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyShoplistCollections",
        method: "GetShoplistCollections",
        params: [
          1,
          15,
          "CREATED_AT",
          "DESC",
          Logic.Auth.CurrentBusinessIsSuperAdmin()
            ? `{
            column: BUSINESS_PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.id}
            } 
            `
            : `{
            column: BUSINESS_PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.id}
            AND: {
              column: SUB_BUSINESS_ID
              operator: EQ
              value: ${Logic.Auth.AuthUser?.business_admin?.id}
            }
            } `,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Shoplist Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Merchant Shoplists",
    });

    const selectedSwitch = ref("active");

    const ManyShoplistCollections = ref(Logic.Shoplist.ManyShoplistCollections);

    const paginatorInfo = ref<PaginatorInfo | undefined>(undefined);

    const shoplistSwitch = reactive([
      {
        name: "Active",
        key: "active",
      },
      {
        name: "Inactive",
        key: "inactive",
      },
    ]);

    const showAddShoplist = () => {
      Logic.Common.GoToRoute("/shoplists/create");

      // Logic.Common.showModal({
      //   show: true,
      //   type: "shoplist_type",
      //   title: "Create new Shoplist",
      //   action: (
      //     type: "video" | "image" | "instagram",
      //     data: any,
      //     url: string
      //   ) => {
      //     if (type == "instagram") {
      //       newShoplist.type = "video";
      //       newShoplist.instagram_data = data;
      //     } else {
      //       newShoplist.type = type;
      //       newShoplist.file = data;
      //       newShoplist.file_url = url;
      //       newShoplist.instagram_data = undefined;
      //     }

      //     Logic.Common.GoToRoute("/shoplists/create");
      //   },
      // });
    };

    const searchQuery = ref("");

    const shopLists = reactive<MediaBox[]>([]);

    const setShoplists = () => {
      shopLists.length = 0;

      ManyShoplistCollections.value?.data.forEach((item) => {
        const mediaBox = getMediaBox(item);

        if (selectedSwitch.value == "active") {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item.status != "inactive"
          ) {
            shopLists.push(mediaBox);
          }
        } else {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item.status == "inactive"
          ) {
            shopLists.push(mediaBox);
          }
        }
      });

      paginatorInfo.value = ManyShoplistCollections.value?.paginatorInfo;
    };

    const goToShoplist = (data: Collection | undefined) => {
      if (Logic.Auth.BusinessUserCan("manage_shoplists")) {
        Logic.Shoplist.SingleShoplistCollection = data;
        Logic.Common.GoToRoute(`/shoplists/create?uuid=${data?.uuid}`);
      }
    };

    const fetchMoreShoplists = (nextPage: number) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      return Logic.Shoplist.GetShoplistCollections(
        nextPage,
        15,
        "CREATED_AT",
        "DESC",
        Logic.Auth.CurrentBusinessIsSuperAdmin()
          ? `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`
          : `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      AND: {
                        column: SUB_BUSINESS_ID
                        operator: EQ
                        value: ${Logic.Auth.AuthUser?.business_admin?.id}
                      }
                    }`,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyShoplistCollections)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyShoplistCollections = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    watch(selectedSwitch, () => {
      setShoplists();
    });

    watch(ManyShoplistCollections, () => {
      if (ManyShoplistCollections.value) {
        paginatorInfo.value = ManyShoplistCollections.value.paginatorInfo;
      }
      setShoplists();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyShoplistCollections",
        ManyShoplistCollections
      );
      setShoplists();
    });

    return {
      Logic,
      searchQuery,
      shopLists,
      shoplistSwitch,
      selectedSwitch,
      paginatorInfo,
      showAddShoplist,
      goToShoplist,
      fetchMoreShoplists,
    };
  },
});
</script>
