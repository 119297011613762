import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4 pt-4" }
const _hoisted_2 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_shoplist = _resolveComponent("app-shoplist")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        title: 'Offers',
        topBarType: "plain",
        pageKey: 'merchant-shoplists',
        hasAddButton: _ctx.Logic.Auth.BusinessUserCan('manage_shoplists'),
        addButtonAction: _ctx.showAddShoplist
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppTextField, {
                placeholder: `Search offers`,
                modelValue: _ctx.searchQuery,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
              }, {
                "inner-prefix": _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    "custom-class": '',
                    name: 'search'
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_switch, {
                items: _ctx.shoplistSwitch,
                modelValue: _ctx.selectedSwitch,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSwitch) = $event))
              }, null, 8, ["items", "modelValue"])
            ]),
            (_ctx.shopLists.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_app_virtual_scroller, {
                    "container-class": 'w-full grid grid-cols-2 gap-3 !space-y-0',
                    data: _ctx.shopLists,
                    pagination: _ctx.paginatorInfo,
                    fetchMore: _ctx.fetchMoreShoplists,
                    direction: 'vertical',
                    ref: "productVirtualScrollerRef"
                  }, {
                    "item-content": _withCtx(({ index, item }) => [
                      (_openBlock(), _createElementBlock("div", {
                        class: "col-span-1 flex flex-col",
                        key: index
                      }, [
                        _createVNode(_component_app_shoplist, {
                          item: item,
                          class: "cursor-pointer",
                          onClick: ($event: any) => (
                      !item?.is_grid && !item?.video_url
                        ? null
                        : _ctx.goToShoplist(item.data)
                    )
                        }, null, 8, ["item", "onClick"])
                      ]))
                    ]),
                    _: 1
                  }, 8, ["data", "pagination", "fetchMore"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_app_empty_state, {
                    title: `No ${
              _ctx.selectedSwitch == 'active' ? 'Active' : 'Inactive'
            } Offers Here`,
                    subTitle: `All ${
              _ctx.selectedSwitch == 'active' ? 'Active' : 'Inactive'
            } offers will show up here`
                  }, null, 8, ["title", "subTitle"])
                ])),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["hasAddButton", "addButtonAction"])
    ]),
    _: 1
  }))
}