<template>
  <app-wrapper :innerClass="'!bg-transparent'">
    <onboarding-layout
      class="!bg-transparent"
      :title="'clear'"
      id="videoContainer"
    >
      <div
        :class="`w-full flex flex-col items-center justify-start space-y-2 fixed !font-inter h-screen !pt-0 top-0 left-0 z-[9999999999999]  px-4`"
      >
        <div id="reader" width="0px" class="hidden"></div>
        <div
          class="w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
        >
          <div class="flex flex-row items-center space-x-3">
            <div
              :class="`w-[40px] h-[40px] xs:w-[43px] xs:h-[43px] rounded-full flex flex-row items-center justify-center border-[1px] border-white`"
              @click="
                Logic.Common.goBack();
                CameraPreview.stop();
              "
            >
              <app-icon
                :name="'chevron-left-white'"
                :customClass="'h-[14px]'"
              />
            </div>
            <div class="flex flex-col space-y-1">
              <app-header-text :color="'!text-white'" class="!font-semibold">
                Scan Payment QrCode
              </app-header-text>
            </div>
          </div>
        </div>
        <div
          class="w-full flex flex-row justify-center items-start z-[99999]"
        ></div>
        <div
          :class="`w-[95%] h-full h-min-[300px] flex-grow flex flex-col transparent-center  items-center rounded-[22px] relative `"
          id="scanContainer"
        >
          <app-icon
            :name="'scan-top-left'"
            :customClass="'h-auto absolute top-[-1%] left-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-top-right'"
            :customClass="'h-auto absolute top-[-1%] right-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-bottom-left'"
            :customClass="'h-auto absolute bottom-[-1%] left-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-bottom-right'"
            :customClass="'h-auto absolute bottom-[-1%] right-[-1%] z-50'"
          />
        </div>
        <div
          class="w-full flex flex-row justify-center items-start z-[99999] pt-3"
          style="
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
        >
          <div
            @click="startScanning()"
            class="w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
            v-if="snappedImages.length == 0"
          >
            <app-icon :name="'scan'" :customClass="'h-[28px] xs:h-[22px]'">
            </app-icon>
          </div>
        </div>
      </div>
    </onboarding-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewDidLeave } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  CameraPreview,
  CameraPreviewOptions,
  CameraPreviewPictureOptions,
} from "@capacitor-community/camera-preview";
import fixOrientation from "fix-orientation";
import { AppHeaderText, AppIcon } from "@shpt/ui-components";
import { Html5Qrcode } from "html5-qrcode";
import AppWrapper from "@/components/AppWrapper.vue";
export default defineComponent({
  components: {
    AppWrapper,
    AppHeaderText,
    AppIcon,
  },
  name: "ConfirmPaymentPage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Confirm Payment Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Onboarding",
  setup() {
    useMeta({
      title: "Confirm Payment",
    });

    const showScanImage = ref(false);

    const rotateVideo = ref(false);

    const imageFileUrl = ref("");

    const scanInterval = ref();

    // const ClientAppContent = ref(Logic.User.ClientAppContent);

    const selfieVideoElement = ref<any>(document.getElementById("videoScan"));

    const selfieCanvasElement = ref<any>(document.getElementById("scanCanvas"));

    const showScan = ref(false);

    const snappedImages = ref<
      {
        url: string;
        blob: Blob;
      }[]
    >([]);

    const showSelfie = async () => {
      showScanImage.value = false;
      selfieVideoElement.value = document.getElementById("video");
      selfieCanvasElement.value = document.getElementById("scanCanvas");

      const cameraPreviewOptions: CameraPreviewOptions = {
        position: "rear",
        disableAudio: true,
        parent: "videoContainer",
        toBack: true,
        rotateWhenOrientationChanged: false,
        width: window.screen.width,
        height: window.screen.height,
        enableHighResolution: true,
      };

      CameraPreview.start(cameraPreviewOptions);
    };

    const startScanning = async () => {
      showScan.value = true;

      const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
        quality: 50,
      };

      const result = await CameraPreview.capture(cameraPreviewPictureOptions);
      const base64PictureData = result.value;
      await fixOrientation(
        "data:image/jpeg;base64," + base64PictureData,
        { image: true },
        (fixed: string) => {
          Logic.Common.base64ToBlob(fixed).then((blobImage) => {
            const imageFile = new File([blobImage], "scanned-image");
            const html5QrCode = new Html5Qrcode(/* element id */ "reader");
            html5QrCode
              .scanFile(imageFile, true)
              .then((decodedText) => {
                // success, use decodedText
                // const fullData = JSON.parse(decodedText);
                console.log(decodedText);
                //   Logic.Common.GoToRoute(
                //     `/pay/initiate?account_number=${fullData.id}&account_name=${fullData.name}&photo_url=${fullData.photo_url}&amount=${fullData.amount}&user_uuid=${fullData.user_uuid}&is_merchant=true`
                //   );
                clearInterval(scanInterval.value);
              })
              .catch(() => {
                showScan.value = false;
                // failure, handle it.
                // console.log(`Error scanning file. Reason: ${err}`);
              });
            showScan.value = false;
          });
        }
      );
    };

    const monitorScan = () => {
      scanInterval.value = setInterval(() => {
        //
      }, 1000);
    };

    onIonViewDidEnter(() => {
      showSelfie();

      setTimeout(() => {
        monitorScan();
      }, 700);
    });

    onIonViewDidLeave(() => {
      clearInterval(scanInterval.value);
      setTimeout(() => {
        CameraPreview.stop();
      }, 1000);
    });

    onMounted(() => {
      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 2000);

      if (Logic.Common.currentBuildType() == "web") {
        showSelfie();
        setTimeout(() => {
          monitorScan();
        }, 700);
      }
    });

    onUnmounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        clearInterval(scanInterval.value);
        setTimeout(() => {
          CameraPreview.stop();
        }, 1000);
      }
    });

    return {
      Logic,
      showScanImage,
      rotateVideo,
      imageFileUrl,
      showScan,
      CameraPreview,
      snappedImages,
      startScanning,
    };
  },
});
</script>
<style scoped>
.transparent-center {
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.7) !important;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.7) !important;
  appearance: none;
}

ion-content {
  --background: transparent !important;
}

ion-page {
  --background: transparent !important;
}

body {
  background-color: transparent !important;
}

#mainApp {
  background-color: transparent !important;
}

#ionPageApp {
  background-color: transparent !important;
}

#mainContent {
  --background: transparent;
}
</style>
