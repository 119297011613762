<template>
  <app-wrapper>
    <subpage-layout :title="title" :goBackHandler="goBackHandler">
      <template v-slot:extra-topbar>
        <app-normal-text :custom-class="'!text-primary-400 '"
          >{{ "Step " + `(${onboardingStep.current + "/" + onboardingStep.total})` }}
        </app-normal-text>
      </template>
      <template v-slot:extra-topbar-row>
        <div
          :class="`${
            onboardingStep.current == onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
        >
          <div
            v-for="(item, index) in onboardingStep.total"
            :key="item"
            :class="`${
              onboardingStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>
      <app-form-wrapper
        :parentRefs="parentRefs"
        class="w-full flex flex-col space-y-2 px-4"
        ref="formComp"
      >
        <!-- Step 1 -->
        <div
          class="w-full flex flex-col space-y-3 pt-4"
          v-if="onboardingStep.current == 1"
        >
          <app-normal-text v-if="formData.account_type == 'individual'">
            For
            <span class="!font-extrabold !text-black">Creator Shop Account</span>
            verification, provide your names and verification documents
          </app-normal-text>
          <app-normal-text v-else>
            For
            <span class="!font-extrabold !text-black">Business Shop Account</span>
            verification, provide your account manager's names, verification documents and
            business documents
          </app-normal-text>
          <AppTextField
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Enter first name'"
            ref="manager_first_name"
            :name="'First name'"
            :focus-border="'border-primary-400'"
            v-model="formData.account_manager.first_name"
            :rules="[FormValidations.RequiredRule]"
            required
          >
            <template v-slot:title>
              {{ formData.account_type == "individual" ? "Your" : "Account Manager" }}
              First Name</template
            >
          </AppTextField>

          <AppTextField
            :has-title="true"
            :custom-class="''"
            padding="px-3 py-3"
            :placeholder="'Enter last name'"
            :focus-border="'border-primary-400'"
            ref="manager_last_name"
            :name="'Last name'"
            v-model="formData.account_manager.last_name"
            :rules="[FormValidations.RequiredRule]"
            required
          >
            <template v-slot:title
              >{{
                formData.account_type == "individual" ? "Your" : "Account Manager"
              }}
              Last Name</template
            >
          </AppTextField>

          <div class="w-full flex flex-col space-y-2 py-3">
            <app-normal-text
              class="!font-semibold w-full border-b-[1px] border-gray-200 pb-2"
            >
              Personal Verification
              {{ formData.account_type == "individual" ? "" : "(Account Manager)" }}
            </app-normal-text>

            <div class="w-full flex flex-col space-y-3 pt-1">
              <AppSelect
                :has-title="true"
                :placeholder="'Select document type'"
                :paddings="'px-3 py-3'"
                ref="manager_id_type"
                :options="individualVerificationOptions"
                v-model="formData.account_manager.verification_type"
              >
                <template v-slot:title> ID Document Type </template>
              </AppSelect>

              <AppTextField
                :has-title="true"
                :custom-class="''"
                padding="px-3 py-3"
                :placeholder="'Enter ID Number'"
                name="ID Number"
                ref="manager_id_number"
                :focus-border="'border-primary-400'"
                v-model="formData.account_manager.verification_number"
                :rules="[
                  FormValidations.RequiredRule,
                  FormValidations.customValidator(
                    formData.account_manager.verification_number.length == 11,
                    'ID number must be valid'
                  ),
                ]"
                :content-rule="{
                  addAfterCount: 0,
                  characterToAdd: ``,
                  max: 11,
                }"
                :max-character="11"
                required
              >
                <template v-slot:title>ID Number </template>
              </AppTextField>
            </div>
          </div>

          <div
            class="w-full flex flex-col space-y-2 pb-3"
            v-if="formData.account_type == 'business'"
          >
            <app-normal-text
              class="!font-semibold w-full border-b-[1px] border-gray-200 pb-2"
            >
              Business Verification
            </app-normal-text>

            <div class="w-full flex flex-col space-y-3 pt-1">
              <AppSelect
                v-if="formData.account_type == 'business'"
                :has-title="true"
                :placeholder="'Select document type'"
                :paddings="'px-3 py-3'"
                :options="businessVerificationOptions"
                ref="business_document"
                v-model="formData.document_type"
                @on-option-selected="
                  (data) => {
                    documentName = data.value;
                  }
                "
              >
                <template v-slot:title> Document Type </template>
              </AppSelect>

              <div class="w-full flex flex-col">
                <app-file-attachment
                  :accept="'image/jpeg,image/gif,image/png,application/pdf'"
                  :isWrapper="true"
                  class="w-full border-[1px] border-gray-200 bg-gray-50 rounded-[10px] h-[90px]"
                  v-model:local-file-url="uploadedDocument.url"
                  v-model:model-value="uploadedDocument.blob"
                >
                  <div
                    class="w-full flex flex-col px-6 py-6 space-y-2 items-center justify-center h-full"
                  >
                    <app-normal-text class="text-center !text-gray-600">
                      Click to Upload {{ documentName }}
                    </app-normal-text>

                    <app-normal-text
                      class="!font-semibold text-center !text-gray-600"
                      v-if="uploadedDocument.blob"
                    >
                      <span class="!text-black">1 File Selected</span>
                    </app-normal-text>
                  </div>
                </app-file-attachment>
                <app-normal-text
                  v-if="uploadedDocument.blob"
                  class="text-left !line-clamp-2 pt-2 whitespace-pre-wrap !text-gray-600"
                >
                  <span class="font-semibold !text-black">Selected File:</span>
                  {{ uploadedDocument.blob?.name }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 2 -->
        <div
          class="w-full flex flex-col space-y-3 pt-4"
          v-if="onboardingStep.current == 2"
        >
          <app-normal-text>
            Your earnings will be sent into your settlement account.
          </app-normal-text>
          <AppSelect
            :has-title="true"
            :placeholder="'Select Bank'"
            :paddings="'px-3 py-3'"
            :options="CommercialBanksOptions"
            auto-complete
            ref="bank"
            v-model="settlementData.bank_code"
          >
            <template v-slot:title> Bank </template>
          </AppSelect>

          <div class="w-full flex flex-col space-y-2">
            <AppTextField
              :has-title="true"
              :custom-class="''"
              padding="px-3 py-3"
              :placeholder="'Enter Account Number'"
              :focus-border="'border-primary-400'"
              v-model="settlementData.account_no"
              :rules="[FormValidations.RequiredRule]"
              name="Account number"
              ref="account_number"
              type="tel"
              required
            >
              <template v-slot:title>Account Number</template>
            </AppTextField>

            <div class="w-full flex flex-row justify-end">
              <app-normal-text
                :color="'text-primary-main'"
                v-if="!accountResolved && settlementData.account_no.length >= 10"
              >
                Resolving account...
              </app-normal-text>
              <template v-if="accountResolved && accountName">
                <app-normal-text>
                  {{ accountName }}
                </app-normal-text>
              </template>
            </div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>

        <!-- Bottom section -->
        <fixed-container>
          <div class="w-full flex flex-col pt-4 bg-white dark:!bg-black">
            <AppButton
              :padding="'py-3'"
              :bg-color="'bg-primary-400'"
              :text-color="'text-white'"
              :class="`w-full ${formItemIsValid ? '' : 'opacity-[50%]'}`"
              @click.prevent="moveForward()"
            >
              Continue
            </AppButton>
          </div>
        </fixed-container>
      </app-form-wrapper>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppTextField,
  AppSelect,
  AppButton,
  AppFileAttachment,
  AppFormWrapper,
} from "@shpt/ui-components";
import { SelectOption } from "@shpt/ui-components/src/types";
import { FormValidations } from "@shpt/ui-components/src/composable";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppTextField,
    AppSelect,
    AppButton,
    AppFileAttachment,
    AppFormWrapper,
    AppWrapper,
    FixedContainer,
  },
  name: "MerchantOnboardingSetupPage",
  layout: "Subpage",
  middlewares: {
    tracking_data: {
      lable: "Merchant Onboarding Setup Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Wallet",
        property: "CommercialBanks",
        method: "GetCommercialBanks",
        params: [],
        requireAuth: false,
      },
      {
        domain: "Wallet",
        property: "BusinessWallet",
        method: "GetBusinessWallet",
        params: [],
        requireAuth: false,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Merchant Onboarding",
    });

    const CommercialBanksOptions = ref(Logic.Wallet.CommercialBanksOptions);

    const photoUrl = ref("");

    const onboardingStep = reactive({
      current: 1,
      total: 2,
    });

    const AuthUser = ref(Logic.Auth.AuthUser);
    const BusinessWallet = ref(Logic.Wallet.BusinessWallet);

    const username = ref("");

    const usernameExist = ref(false);

    const resolvingUsername = ref(false);

    const formComp = ref();

    const documentName = ref("");

    const uploadedDocument = reactive<{
      url: string;
      blob: File | null;
    }>({
      url: "",
      blob: null,
    });

    const title = ref("Setup Account");

    const accountName = ref("");
    const accountResolved = ref(true);

    const aboutBusiness = ref("");

    const settlementData = reactive({
      account_no: "",
      bank_code: "",
    });

    const formData = reactive({
      name: "",
      handle: "",
      account_type: "individual",
      verification_type: "",
      business_verification_documents: [],
      document_type: "business_name",
      settlement_bank_code: "",
      settlement_account_number: "",
      facebook: "",
      instagram: "",
      whatsapp: "",
      website: "",
      account_manager: {
        first_name: "",
        last_name: "",
        verification_number: "",
        verification_type: "nin",
      },
      business_logo: "",
      about_business: "",
      business_phone_number: "",
    });

    const formItemIsValid = computed(() => {
      if (onboardingStep.current == 1) {
        if (formData.account_type == "individual") {
          return (
            formData.account_manager.first_name != "" &&
            formData.account_manager.last_name != "" &&
            formData.account_manager.verification_type != "" &&
            formData.account_manager.verification_number != ""
          );
        } else {
          return (
            formData.account_manager.first_name != "" &&
            formData.account_manager.last_name != "" &&
            formData.account_manager.verification_type != "" &&
            formData.account_manager.verification_number != "" &&
            uploadedDocument.blob &&
            formData.document_type
          );
        }
      } else if (onboardingStep.current == 2) {
        return (
          settlementData.account_no != "" &&
          settlementData.bank_code != "" &&
          accountName.value != "" &&
          accountResolved.value
        );
      }

      return false;
    });

    const businessVerificationOptions = reactive<SelectOption[]>([
      {
        value: "Business Name Certificate (BN)",
        key: "business_name",
      },
      {
        value: "Company Registration (RC)",
        key: "company_registration",
      },
    ]);

    const individualVerificationOptions = reactive<SelectOption[]>([
      {
        value: "National Indentity Number",
        key: "nin",
      },
    ]);

    const goBackHandler = () => {
      if (onboardingStep.current <= 1) {
        Logic.Common.goBack();
      } else {
        onboardingStep.current--;
      }
    };

    const next = () => {
      if (onboardingStep.current < onboardingStep.total) {
        onboardingStep.current += 1;
        return;
      }
    };

    const moveForward = async () => {
      formComp.value.validate();
      if (formItemIsValid.value) {
        if (onboardingStep.current == 1) {
          // save business profile
          Logic.User.UpdateBusinessProfileForm = {
            business_document: uploadedDocument.blob,
            manager_info: {
              first_name: formData.account_manager.first_name,
              last_name: formData.account_manager.last_name,
              id_type: formData.account_manager.verification_type,
              id_number: formData.account_manager.verification_number,
            },
          };
          Logic.User.UpdateBusinessProfile()?.then(async (data) => {
            if (data?.UpdateBusinessProfile) {
              next();
              await Logic.Wallet.GetBusinessWallet();
              await Logic.Auth.GetAuthUser();
            }
          });
          return;
        }

        if (onboardingStep.current == 1) {
          // save settlement account
          Logic.Wallet.AddUserBankForm = {
            account_no: settlementData.account_no,
            bank_code: settlementData.bank_code,
            account_type: "business",
          };
          await Logic.Wallet.AddBank(true, true);
          await Logic.Wallet.GetBusinessWallet();

          Logic.Common.showAlert({
            show: true,
            message: "Business Verification Submitted",
            type: "success",
          });
          Logic.Common.goBack();
          return;
        }

        next();
      }
    };

    watch(onboardingStep, () => {
      if (onboardingStep.current == 1) {
        title.value = "Account Verification";
      }

      if (onboardingStep.current == 2) {
        title.value = "Settlement Account";
      }
    });

    watch(settlementData, () => {
      Logic.Common.debounce(() => {
        if (settlementData.bank_code && settlementData.account_no.length >= 10) {
          accountResolved.value = false;

          Logic.Wallet.ResolveBankAccountForm = {
            account_no: settlementData.account_no,
            bank_code: settlementData.bank_code,
            wallet_uuid: "empty",
          };

          Logic.Wallet.ResolveBankAccount(true)?.then((data) => {
            if (data && data.data?.ResolveBankAccount) {
              accountName.value = data.data.ResolveBankAccount.account_name || "";
            }
            accountResolved.value = true;
          });
        } else {
          accountResolved.value = false;
        }
      });
    });

    const setDefault = () => {
      formData.account_type =
        AuthUser.value?.profile?.business?.account_type || "individual";
      if (AuthUser.value?.profile?.business?.manager_info?.first_name) {
        onboardingStep.current = 2;
      }
    };

    onIonViewWillEnter(() => {
      setDefault();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("CommercialBanksOptions", CommercialBanksOptions);
      Logic.Wallet.watchProperty("BusinessWallet", BusinessWallet);
      setDefault();
    });

    return {
      onboardingStep,
      Logic,
      formData,
      businessVerificationOptions,
      individualVerificationOptions,
      title,
      documentName,
      uploadedDocument,
      CommercialBanksOptions,
      FormValidations,
      photoUrl,
      aboutBusiness,
      formComp,
      formItemIsValid,
      username,
      usernameExist,
      resolvingUsername,
      AuthUser,
      settlementData,
      accountName,
      accountResolved,
      goBackHandler,
      moveForward,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
