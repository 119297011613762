import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 h-full" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col h-full items-center min-h-[500px] justify-center px-4 space-y-2"
}
const _hoisted_3 = { class: "w-[300px] h-[300px] max-w-full bg-primary-main rounded-[10px] px-3 py-3" }
const _hoisted_4 = { class: "w-full flex flex-col h-full" }
const _hoisted_5 = {
  key: 1,
  class: "w-full flex flex-col h-full items-center min-h-[500px] justify-center space-y-4"
}
const _hoisted_6 = { class: "w-full flex flex-col items-center justify-center px-4 space-y-2 pt-6" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2 px-4 pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_qrcode = _resolveComponent("app-qrcode")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_keyboard = _resolveComponent("app-keyboard")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Accept Payment' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.showQrCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_app_header_text, { class: "text-center !text-2xl !text-primary-700" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Scan To Pay ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_app_qrcode, {
                        qrcodeValue: JSON.stringify(_ctx.businessPaymentInfo()),
                        class: "h-full w-full"
                      }, null, 8, ["qrcodeValue"])
                    ])
                  ]),
                  _createVNode(_component_app_normal_text, { class: "w-[70%] text-center !text-gray-600 dark:!text-gray-400" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Scan this code with your Shoppoint App to pay ")
                    ])),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_app_normal_text, { class: "text-center w-full !text-gray-600 dark:!text-gray-300" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Enter amount ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_header_text, { class: "text-center !text-xl !text-primary-700" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  parseFloat(_ctx.amountToPay).toFixed(2),
                  false,
                  "ngn"
                )), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_app_keyboard, {
                      modelValue: _ctx.amountToPay,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amountToPay) = $event)),
                      isNumber: true
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_app_button, {
                      onClick: _ctx.handleConfirm,
                      customClass: "w-full",
                      class: _normalizeClass(`py-3 ${
                  parseFloat(_ctx.amountToPay) > 50 ? 'opacity-100' : 'opacity-50'
                }`)
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Confirm ")
                      ])),
                      _: 1
                    }, 8, ["onClick", "class"])
                  ])
                ]))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}