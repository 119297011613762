<template>
  <app-wrapper>
    <subpage-layout :title="'Top up Ad Wallet'">
      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-col space-y-3 px-4">
          <!-- Amount field -->
          <app-text-field
            v-if="!hideAmount"
            :placeholder="'Enter amount'"
            :hasTitle="true"
            :type="'tel'"
            :isFormatted="true"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                parseFloat(formData.amount.replace(/,/g, '')) <= 500000,
                'Topup cannot be more than 500,000'
              ),
            ]"
            ref="amount"
            name="Amount"
            v-model="formData.amount"
          >
            <template v-slot:title> Amount </template>
            <template v-slot:inner-prefix>
              <app-normal-text
                class="!text-grey-700 dark:!text-gray-400 !font-semibold"
              >
                NGN
              </app-normal-text>
            </template>
          </app-text-field>
          <app-text-field
            v-else
            :placeholder="'Enter amount'"
            :hasTitle="true"
            :type="'tel'"
            :isFormatted="true"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                parseFloat(formData.amount.replace(/,/g, '')) <= 500000,
                'Topup cannot be more than 500,000'
              ),
            ]"
            ref="amount"
            name="Amount"
            v-model="formData.amount"
          >
            <template v-slot:title> Amount </template>
            <template v-slot:inner-prefix>
              <app-normal-text
                class="!text-grey-700 dark:!text-gray-400 !font-semibold"
              >
                NGN
              </app-normal-text>
            </template>
          </app-text-field>
        </div>

        <!-- Common options -->
        <div
          class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
        >
          <div class="flex flex-row space-x-3 py-2 pr-4">
            <div
              class="px-4 py-1 rounded-[20px] bg-grey-50 dark:bg-gray-800 flex items-center justify-center cursor-pointer"
              v-for="(item, index) in commonOptions"
              :key="index"
              @click="setAmount(item.toString())"
            >
              <app-normal-text class="!font-semibold">
                {{ Logic.Common.convertToMoney(item, false, "ngn") }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col px-4">
          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-purple-50 dark:bg-gray-900"
          >
            <app-normal-text class="!flex !flex-row !text-center !space-x-2">
              <span class="text-left !text-gray-700 dark:!text-gray-400">
                <span class="!font-extrabold !text-black dark:!text-white"
                  >Ad Wallet</span
                >
                funds are non-refundable pre-payments. Only top up with the
                amount you intend to use for advertising.
              </span>
            </app-normal-text>
          </div>
        </div>
      </div>

      <!-- Bottom section -->
      <fixed-container>
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :text-color="'text-white'"
            class="w-full"
            @click="continueWithPayment"
          >
            Continue
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { AppTextField, AppNormalText, AppButton } from "@shpt/ui-components";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppNormalText,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "TopUpAdWalletPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "AdWallet",
        method: "GetAdWallet",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Top Up Ad Wallet Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Top Up Ad Wallet",
    });

    const hideAmount = ref(false);

    const AuthUser = ref(Logic.Auth.AuthUser);
    const AdWallet = ref(Logic.Wallet.AdWallet);

    const defaultAmount = ref("0");

    const formComp = ref();

    const formData = reactive({
      amount: "",
    });

    const amountIsNotValid = computed(() => {
      return (
        parseFloat(formData.amount.replace(/,/g, "")) == 0 ||
        parseFloat(formData.amount.replace(/,/g, "")) > 500000
      );
    });

    const commonOptions = reactive([
      5000, 10000, 20000, 50000, 100000, 200000, 500000,
    ]);

    const continueWithPayment = () => {
      if (!amountIsNotValid.value) {
        Logic.Common.showModal({
          show: true,
          title: "Complete Payment",
          type: "payment_provider",
          extraData: {
            amount: parseFloat(formData.amount.replace(/,/g, "")),
            currency: "NGN",
            email: Logic.Auth.AuthUser?.email,
            user_uuid: Logic.Auth.AuthUser?.uuid,
            wallet_uuid: AdWallet.value?.uuid,
            type: "cash",
            narration: "Top Up Ad Wallet",
          },
          action: () => {
            Logic.Common.showAlert({
              show: true,
              message: "Top up successful",
              type: "success",
            });
            Logic.Wallet.GetTransactionsByWalletId(
              "advert",
              parseInt(AdWallet.value?.id || "0"),
              1,
              50,
              "UPDATED_AT",
              "DESC"
            );
            Logic.Wallet.GetAdWallet();
            Logic.Common.showModal({ show: false });
            Logic.Common.goBack();
          },
          preventClose: true,
          closeAction: () => {
            Logic.Common.showModal({ show: false });
          },
        });
      }
    };

    const setAmount = (amount: string) => {
      hideAmount.value = true;
      formData.amount = amount;
      setTimeout(() => {
        hideAmount.value = false;
      }, 100);
    };

    onIonViewWillEnter(() => {
      formData.amount = "0";
    });

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Wallet.watchProperty("AdWallet", AdWallet);
      formData.amount = "0";
    });

    return {
      FormValidations,
      formData,
      formComp,
      loaderSetup,
      AuthUser,
      Logic,
      commonOptions,
      amountIsNotValid,
      defaultAmount,
      hideAmount,
      continueWithPayment,
      setAmount,
    };
  },
});
</script>
