<template>
  <app-wrapper>
    <subpage-layout :title="'Store Branches'">
      <div class="w-full flex flex-col space-y-4">
        <div class="w-full flex flex-col space-y-2 px-4">
          <div
            @click="showAddArea(null, -1)"
            class="w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
          >
            <app-normal-text class="text-center text-gray-700">
              + New Store Branch
            </app-normal-text>
          </div>

          <!-- Loading State -->

          <div
            class="w-full flex flex-row justify-center items-center h-[100px]"
            v-if="pickupAreaIsLoading"
          >
            <app-loading-state />
          </div>

          <app-business-pick-area
            v-for="(data, index) in deliveryAreas"
            :key="index"
            :data="data"
            @click="showAddArea(data, index)"
            class="cursor-pointer"
          >
          </app-business-pick-area>
        </div>

        <div
          v-if="deliveryAreas.length == 0 && !pickupAreaIsLoading"
          class="w-full flex flex-col items-center justify-center h-[200px] px-4"
        >
          <app-empty-state
            :title="'No Store Branches Found'"
            :sub-title="'You can add your store branches by clicking the button above.'"
          />
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppBusinessPickArea,
  AppLoadingState,
  AppEmptyState,
} from "@shpt/ui-components";
import { supportedAreasOption } from "@shpt/ui-components/src/composable";
import AppWrapper from "@/components/AppWrapper.vue";
import {
  deliveryAreas,
  getDeliveryLocations,
  pickupAreaIsLoading,
  setBusinessAdminOptions,
  setDeliveryLocations,
  showAddArea,
} from "@shpt/ui-components/src/composable/shop";

export default defineComponent({
  components: {
    AppNormalText,
    AppBusinessPickArea,
    AppLoadingState,
    AppWrapper,
    AppEmptyState,
  },
  name: "MerchantDeliveryAreaIndexPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "ManyBusinessAdmins",
        method: "GetBusinessAdmins",
        params: [
          1,
          50,
          "CREATED_AT",
          "DESC",
          `
         {
        column: UUID
        operator: EQ
        value: "${Logic.Auth.GetCurrentBusiness()?.uuid}"
         }
        `,
          `{
        column: INVITATION_ACCEPTED_AT
        operator: NEQ
        value: null
        }`,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Store Location Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Store Location",
    });

    const ManyDeliveryLocations = ref(Logic.Shoplist.ManyDeliveryLocations);
    const ManyBusinessAdmins = ref(Logic.User.ManyBusinessAdmins);

    watch(ManyDeliveryLocations, () => {
      setDeliveryLocations();
    });

    watch(ManyBusinessAdmins, () => {
      setBusinessAdminOptions();
    });

    onIonViewWillEnter(() => {
      getDeliveryLocations();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyDeliveryLocations",
        ManyDeliveryLocations
      );
      Logic.User.watchProperty("ManyBusinessAdmins", ManyBusinessAdmins);
      setBusinessAdminOptions();

      if (Logic.Common.currentBuildType() == "web") {
        getDeliveryLocations();
      }
    });

    return {
      Logic,
      deliveryAreas,
      pickupAreaIsLoading,
      supportedAreasOption,
      showAddArea,
    };
  },
});
</script>
