import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 pt-4" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-3 px-4" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-[2px] items-center" }
const _hoisted_4 = { class: "w-full flex items-center space-x-2 justify-center" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_7 = { class: "flex flex-row space-x-2 py-2 pr-4" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "w-full px-4 flex flex-col space-y-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex justify-between w-full" }
const _hoisted_12 = { class: "flex justify-between w-full" }
const _hoisted_13 = { class: "flex justify-between w-full" }
const _hoisted_14 = { class: "flex justify-between w-full" }
const _hoisted_15 = {
  key: 0,
  class: "flex justify-between w-full"
}
const _hoisted_16 = {
  key: 1,
  class: "flex justify-between w-full"
}
const _hoisted_17 = {
  key: 1,
  class: "w-full flex items-center justify-center h-[200px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        title: 'Advertise',
        topBarType: "plain",
        pageKey: 'merchant-promote',
        hasAddButton: _ctx.Logic.Auth.BusinessUserCan('manage_promotions'),
        hasNotify: false,
        addButtonAction: _ctx.showAddPromotion
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionSummary, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "border-grey-50 border-[1px] flex flex-col items-center justify-center rounded-[8px] py-3 px-3 space-y-2 col-span-1 mdlg:!py-5"
                }, [
                  _createVNode(_component_app_icon, {
                    "custom-class": 'h-[26px]',
                    name: item.icon
                  }, null, 8, ["name"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_normal_text, {
                      "custom-class": '!text-[10px] mdlg:!text-[12px]',
                      color: 'text-black'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_app_normal_text, { "custom-class": 'font-bold !text-sm' }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.value, false, "")), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-row items-center space-x-[2px]" }, null, -1))
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionTypes, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(` ${
                  _ctx.selectedPromotion == item.key
                    ? 'bg-primary-main'
                    : 'bg-gray-100 dark:bg-gray-700'
                } px-3 py-1 rounded-[30px] w-auto cursor-pointer`),
                      key: index,
                      onClick: ($event: any) => (_ctx.selectedPromotion = item.key)
                    }, [
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`!whitespace-nowrap  ${
                    _ctx.selectedPromotion == item.key ? 'text-white' : 'text-gray-500'
                  }`)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ], 10, _hoisted_8))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (
                _ctx.selectedPromotion == 'all'
                  ? _ctx.promotions.length > 0
                  : _ctx.promotions.filter((item) => item.type == _ctx.selectedPromotion).length > 0
              )
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.selectedPromotion == 'all'
                  ? _ctx.promotions
                  : _ctx.promotions.filter((item) => item.type == _ctx.selectedPromotion), (data, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer",
                        onClick: ($event: any) => (
                  _ctx.Logic.Auth.BusinessUserCan('manage_promotions')
                    ? _ctx.Logic.Common.GoToRoute(`/promote/${data.uuid}`)
                    : null
                )
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_AppNormalText, { class: "!font-semibold !text-left !line-clamp-1" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_app_badge, {
                            color: `${
                      data.status == 'active' || data.status == 'verified'
                        ? 'green'
                        : data.status == 'inactive' || data.status == 'failed'
                        ? 'red'
                        : 'yellow'
                    }`,
                            class: _normalizeClass('mdlg:!text-[12px]')
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.capitalize(data.status.replace("_", " "))), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_AppNormalText, {
                            color: 'text-grey-700',
                            class: "!text-[12px]",
                            "custom-class": 'gap-2'
                          }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode(" Impressions ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_AppNormalText, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.total_impression), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_AppNormalText, {
                            color: 'text-grey-700',
                            class: "!text-[12px]",
                            "custom-class": 'gap-2'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.type == "website_visit" || data.type == "banner_ad"
                        ? "Clicks"
                        : "Engagements"), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_AppNormalText, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.total_engagement), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_AppNormalText, {
                            color: 'text-grey-700',
                            class: "!text-[12px]",
                            "custom-class": 'gap-2'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.type == "website_visit" || data.type == "banner_ad"
                        ? "Click-Through Rate"
                        : "Engagement Rate"), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_AppNormalText, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.engagement_rate) + "% ", 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        (data.bid_type == 'cpm')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createVNode(_component_AppNormalText, {
                                color: 'text-grey-700',
                                class: "!text-[12px]",
                                "custom-class": 'gap-2'
                              }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode(" CPM ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_AppNormalText, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(data.cost_per_impression, false, "ngn")), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]))
                          : _createCommentVNode("", true),
                        (data.bid_type == 'cpe')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createVNode(_component_AppNormalText, {
                                color: 'text-grey-700',
                                class: "!text-[12px]",
                                "custom-class": 'gap-2'
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" CPE ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_AppNormalText, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(data.cost_per_engagement, false, "ngn")), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_10))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_app_empty_state, {
                        title: `No ${_ctx.selectedPromotion.replace('_', ' ')} promotion found`,
                        "sub-title": `Your ${_ctx.selectedPromotion.replace(
                    '_',
                    ' '
                  )} promotions will appear here`
                      }, null, 8, ["title", "sub-title"])
                    ]))
              ])
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["hasAddButton", "addButtonAction"])
    ]),
    _: 1
  }))
}