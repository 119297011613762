<template>
  <app-wrapper>
    <subpage-layout :title="'Activities'">
      <div class="w-full h-full flex flex-col px-4 justify-center items-center">
        <app-empty-state
          :title="'No Activities Here'"
          :subTitle="'All activities will show up here'"
        />
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { AppEmptyState } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppWrapper,
    AppEmptyState,
  },
  name: "NotificationsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "Notifications",
        method: "GetNotifications",
        params: [1, 10],
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Notifications Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Notifications",
    });

    const notificationsPage = ref();

    onIonViewWillEnter(() => {
      notificationsPage.value?.setupPage();
    });

    return {
      notificationsPage,
    };
  },
});
</script>
