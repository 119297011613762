import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col pl-4 pb-2"
}
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-4 px-4 pt-3" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_8 = { class: "w-full flex flex-row space-x-2 justify-center items-center" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_10 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_11 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "pr-1" }
const _hoisted_14 = {
  key: 1,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_15 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_16 = { class: "col-span-1 flex flex-row" }
const _hoisted_17 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_18 = { class: "w-full flex flex-row items-start justify-start" }
const _hoisted_19 = { class: "col-span-1 flex flex-col" }
const _hoisted_20 = { class: "w-full flex flex-col" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "w-full flex flex-row flex-wrap" }
const _hoisted_23 = { class: "px-2 py-[2px] bg-white border-[1px] border-grey-200 dark:!bg-grey-800 dark:!border-grey-700 rounded-[4px] flex flex-row items-center justify-center space-x-2" }
const _hoisted_24 = {
  key: 1,
  class: "w-full flex flex-col space-y-2 py-2"
}
const _hoisted_25 = { class: "w-full flex flex-col space-y-2 items-center justify-center border-[1px] border-grey-200 dark:!border-grey-700 border-dashed h-[70px] rounded-[10px]" }
const _hoisted_26 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_27 = { class: "w-full flex flex-col space-y-2 pt-2" }
const _hoisted_28 = { class: "!w-[70px] flex flex-col space-y-2" }
const _hoisted_29 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_30 = { class: "w-full flex flex-row items-center justify-center" }
const _hoisted_31 = { class: "w-full flex flex-col space-y-1 flex-grow" }
const _hoisted_32 = { class: "w-full grid grid-cols-2 gap-1" }
const _hoisted_33 = { class: "col-span-1 flex flex-col" }
const _hoisted_34 = { class: "pr-[1px]" }
const _hoisted_35 = { class: "col-span-1 flex flex-col" }
const _hoisted_36 = {
  key: 0,
  class: "w-full flex flex-col pt-2"
}
const _hoisted_37 = { class: "w-full flex flex-row flex-wrap" }
const _hoisted_38 = {
  key: 0,
  class: "px-2 py-[2px] bg-white border-[1px] border-grey-200 dark:!bg-grey-800 dark:!border-grey-700 rounded-[4px] flex flex-row items-center justify-center space-x-2 relative"
}
const _hoisted_39 = {
  key: 1,
  class: "w-full flex flex-col space-y-2 items-center justify-center border-[1px] border-grey-200 dark:!border-grey-700 border-dashed h-[70px] rounded-[10px]"
}
const _hoisted_40 = {
  key: 2,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_41 = { class: "flex justify-between w-full" }
const _hoisted_42 = { class: "pr-1" }
const _hoisted_43 = { class: "w-full items-center flex justify-center py-2" }
const _hoisted_44 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_45 = {
  key: 0,
  class: "w-full flex flex-row px-3 py-3 bg-secondary-50 rounded-[10px]"
}
const _hoisted_46 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "flex justify-between w-full" }
const _hoisted_49 = { class: "flex justify-between w-full" }
const _hoisted_50 = { class: "pl-1" }
const _hoisted_51 = {
  key: 1,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_52 = {
  key: 0,
  class: "w-full px-3 py-3 bg-primary-50 rounded-[10px] flex flex-col space-y-2 justify-center items-center"
}
const _hoisted_53 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_54 = { class: "w-full items-center flex justify-center py-2" }
const _hoisted_55 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_content_editable = _resolveComponent("app-content-editable")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_business_pick_area = _resolveComponent("app-business-pick-area")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.isEdit ? ' Update Product' : _ctx.pageTitle,
        goBackHandler: _ctx.goBackHandler,
        mainSectionClass: '!h-fit'
      }, _createSlots({
        "extra-topbar-row": _withCtx(() => [
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(`px-4 py-1 ${
                  _ctx.selectedSection == item.id ? 'bg-primary-main' : 'bg-grey-50'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`),
                        onClick: ($event: any) => (_ctx.selectedSection = item.id)
                      }, [
                        _createVNode(_component_app_normal_text, {
                          class: _normalizeClass(`${
                    _ctx.selectedSection == item.id
                      ? '!text-white'
                      : '!text-grey-700'
                  }`),
                          "custom-class": 'whitespace-nowrap'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ], 10, _hoisted_4))
                    }), 128))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(`${
            _ctx.productCreationStep.current == _ctx.productCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productCreationStep.total, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item,
                    class: _normalizeClass(`${
              _ctx.productCreationStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.productCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
                  }, null, 2))
                }), 128))
              ], 2))
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_form_wrapper, {
              ref: "formComp",
              parentRefs: _ctx.parentRefs,
              class: "w-full flex flex-col space-y-4"
            }, {
              default: _withCtx(() => [
                (_ctx.selectedSection == 'product_details')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode(" Product Images "),
                              _createElementVNode("span", { class: "text-error-main pl-[2px]" }, "*", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_app_file_attachment, {
                          class: "w-full h-[100px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed",
                          "onUpdate:modelValue": _ctx.onFileAdded,
                          "onUpdate:localFileUrl": _ctx.onFileUrlGenerated,
                          "is-wrapper": "",
                          "is-multiple": "",
                          accept: 'image/jpeg,image/gif,image/png'
                        }, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_app_icon, {
                                name: 'image',
                                customClass: '!h-[18px]'
                              }),
                              _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[21] || (_cache[21] = [
                                  _createTextVNode(" Add images ")
                                ])),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"]),
                        (_ctx.formData.details.medias.length > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.details.medias, (media, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "flex flex-row cursor-pointer pr-2 relative",
                                      key: index
                                    }, [
                                      _createElementVNode("span", {
                                        class: "absolute top-[-5px] right-0 h-[20px] w-[20px] flex justify-center items-center bg-grey-200 dark:!bg-grey-white rounded-full cursor-pointer",
                                        onClick: ($event: any) => (_ctx.removeMedia(index))
                                      }, [
                                        _createVNode(_component_app_icon, {
                                          name: 'close',
                                          customClass: '!h-[10px]'
                                        })
                                      ], 8, _hoisted_12),
                                      _createVNode(_component_app_image_loader, {
                                        "photo-url": media,
                                        customClass: 'w-[100px] h-[100px] rounded-[10px] !border-[1px] !border-gray-200 dark:!border-gray-700'
                                      }, null, 8, ["photo-url"])
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_AppTextField, {
                        "focus-border": 'border-primary-400',
                        "has-title": true,
                        "custom-class": '!bg-grey-50',
                        placeholder: 'Product Name',
                        name: "Product name",
                        rules: [_ctx.FormValidations.RequiredRule],
                        required: "",
                        ref: "product_name",
                        modelValue: _ctx.formData.details.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.details.name) = $event)),
                        "update-value": _ctx.formData.details.name
                      }, {
                        title: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode(" Product Name ")
                        ])),
                        _: 1
                      }, 8, ["rules", "modelValue", "update-value"]),
                      (!_ctx.hideFields)
                        ? (_openBlock(), _createBlock(_component_AppSelect, {
                            key: 0,
                            "has-title": true,
                            "placeholder-color": 'placeholder:text-black',
                            "default-size": 'w-full',
                            placeholder: 'Select',
                            customClass: 'dark:!bg-grey-800',
                            paddings: 'px-3 py-3',
                            ref: 'category',
                            "auto-complete": "",
                            options: _ctx.categoryOptions,
                            modelValue: _ctx.selectedCategory,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                            theme: _ctx.Logic.Common.currentAppTheme(),
                            required: ""
                          }, {
                            title: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode("Category")
                            ])),
                            _: 1
                          }, 8, ["options", "modelValue", "theme"]))
                        : _createCommentVNode("", true),
                      (_ctx.subCategoryOptions.length > 0)
                        ? (_openBlock(), _createBlock(_component_AppSelect, {
                            key: 1,
                            "has-title": true,
                            "placeholder-color": 'placeholder:text-black',
                            "default-size": 'w-full',
                            placeholder: 'Select',
                            customClass: 'dark:!bg-grey-800',
                            paddings: 'px-3 py-3',
                            ref: 'category',
                            "auto-complete": "",
                            options: _ctx.subCategoryOptions,
                            modelValue: _ctx.selectedSubCategory,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSubCategory) = $event)),
                            theme: _ctx.Logic.Common.currentAppTheme(),
                            required: ""
                          }, {
                            title: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode("Sub Category")
                            ])),
                            _: 1
                          }, 8, ["options", "modelValue", "theme"]))
                        : _createCommentVNode("", true),
                      (_ctx.brandOptions.length > 0)
                        ? (_openBlock(), _createBlock(_component_AppSelect, {
                            key: 2,
                            "has-title": true,
                            "placeholder-color": 'placeholder:text-black',
                            "default-size": 'w-full',
                            placeholder: 'Select',
                            customClass: 'dark:!bg-grey-800',
                            paddings: 'px-3 py-3',
                            ref: 'brand',
                            "auto-complete": "",
                            options: _ctx.brandOptions,
                            modelValue: _ctx.formData.details.brand_id,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.details.brand_id) = $event)),
                            theme: _ctx.Logic.Common.currentAppTheme()
                          }, {
                            title: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode("Brand")
                            ])),
                            _: 1
                          }, 8, ["options", "modelValue", "theme"]))
                        : _createCommentVNode("", true),
                      (!_ctx.hideFields)
                        ? (_openBlock(), _createBlock(_component_AppTextField, {
                            key: 3,
                            "focus-border": 'border-primary-400',
                            "has-title": true,
                            "custom-class": '!bg-grey-50',
                            placeholder: 'Enter price',
                            required: "",
                            rules: [_ctx.FormValidations.RequiredRule],
                            name: "price",
                            ref: "price",
                            type: "tel",
                            "is-formatted": "",
                            modelValue: _ctx.formData.details.price_per_unit,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.details.price_per_unit) = $event))
                          }, {
                            title: _withCtx(() => _cache[26] || (_cache[26] = [
                              _createTextVNode("Price Per Unit ")
                            ])),
                            "inner-prefix": _withCtx(() => [
                              _createElementVNode("span", _hoisted_13, [
                                _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode("NGN")
                                  ])),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }, 8, ["rules", "modelValue"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_AppTextField, {
                        "focus-border": 'border-primary-400',
                        "has-title": true,
                        "custom-class": '!bg-grey-50 !items-start',
                        placeholder: 'Enter Description',
                        "max-character": 250,
                        textAreaRow: '3',
                        name: "Description",
                        ref: "description",
                        rules: [_ctx.FormValidations.RequiredRule],
                        "is-textarea": "",
                        modelValue: _ctx.formData.details.description,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.details.description) = $event)),
                        "update-value": _ctx.formData.details.description
                      }, {
                        title: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode("Describe Product ")
                        ])),
                        _: 1
                      }, 8, ["rules", "modelValue", "update-value"]),
                      _createVNode(_component_app_checkbox, {
                        modelValue: _ctx.productIsAvailable,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.productIsAvailable) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_app_normal_text, null, {
                            default: _withCtx(() => _cache[29] || (_cache[29] = [
                              _createTextVNode(" Published ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'product_variant')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !text-sm" }, {
                          default: _withCtx(() => _cache[30] || (_cache[30] = [
                            _createTextVNode(" Custom Attributes ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_button, {
                          class: "!px-3 !py-1 !text-primary-500 !bg-primary-50 dark:!bg-primary-400 dark:!text-primary-50",
                          onClick: _withModifiers(_ctx.addAttribute, ["prevent"])
                        }, {
                          default: _withCtx(() => _cache[31] || (_cache[31] = [
                            _createTextVNode(" + new ")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      (_ctx.formAttributes.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formAttributes, (attribute, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "w-full grid grid-cols-2 gap-2 justify-between pb-3 border-b-[1px] border-grey-200 dark:!border-grey-700",
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_app_content_editable, {
                                    placeholder: 'Name e.g Size',
                                    class: _normalizeClass('!bg-grey-50 dark:!bg-grey-800 px-2 py-1 !text-black dark:!text-white w-full focus:!outline-primary-500 rounded-[4px]'),
                                    modelValue: attribute.name,
                                    "onUpdate:modelValue": ($event: any) => ((attribute.name) = $event),
                                    "default-value": attribute.name
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "default-value"]),
                                  _createElementVNode("div", _hoisted_18, [
                                    _createVNode(_component_app_badge, {
                                      color: 'red',
                                      class: "!text-[9px] !rounded-[10px] !py-[1px] cursor-pointer",
                                      onClick: _withModifiers(($event: any) => (_ctx.removeAttribute(index)), ["prevent"])
                                    }, {
                                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                                        _createTextVNode(" Remove ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createVNode(_component_app_text_field, {
                                    hasTitle: false,
                                    placeholder: 'Value. e.g Red',
                                    customClass: "!rounded-[4px]",
                                    padding: "px-2 py-1",
                                    modelValue: attribute.value,
                                    "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                                    "update-value": attribute.value
                                  }, {
                                    "inner-suffix": _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: "text-primary-500 dark:!text-primary-300 cursor-pointer",
                                        onClick: _withModifiers(($event: any) => (_ctx.addAttributeValue(attribute)), ["prevent"])
                                      }, " Add ", 8, _hoisted_21)
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue", "onUpdate:modelValue", "update-value"])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute.existing_values, (value, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "pr-2 pt-2 flex flex-col",
                                      key: index
                                    }, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createVNode(_component_app_normal_text, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(value), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_app_icon, {
                                          onClick: _withModifiers(($event: any) => (
                              _ctx.removeAttributeValue(attribute, index)
                            ), ["prevent"]),
                                          name: `close-modal${
                              _ctx.Logic.Common.currentAppTheme() == 'light'
                                ? ''
                                : '-white'
                            }`,
                                          customClass: '!h-[16px] cursor-pointer'
                                        }, null, 8, ["onClick", "name"])
                                      ])
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[33] || (_cache[33] = [
                                  _createTextVNode(" No product attributes yet ")
                                ])),
                                _: 1
                              })
                            ])
                          ])),
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !text-sm" }, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode(" Product Variations ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_button, {
                          class: _normalizeClass(`!px-3 !py-1 !text-primary-500 !bg-primary-50 cursor-pointer dark:!bg-primary-400 dark:!text-primary-50 ${
                    _ctx.formAttributes.length ? '' : '!opacity-50'
                  }`),
                          onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (
                    _ctx.formAttributes.length
                      ? _ctx.addVariant()
                      : _ctx.showAddAttributeAlert()
                  ), ["prevent"]))
                        }, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode(" + new ")
                          ])),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        (_ctx.formData.variants.length)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formData.variants, (variation, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "w-full flex flex-row space-x-2 justify-between items-start pb-4 border-b-[1px] border-grey-200 dark:!border-grey-700",
                                key: index
                              }, [
                                _createElementVNode("div", _hoisted_28, [
                                  _createVNode(_component_app_file_attachment, {
                                    class: "!w-[70px]",
                                    "local-file-url": variation.image_url,
                                    "onUpdate:localFileUrl": ($event: any) => ((variation.image_url) = $event),
                                    modelValue: variation.image_blob,
                                    "onUpdate:modelValue": ($event: any) => ((variation.image_blob) = $event),
                                    "is-wrapper": "",
                                    accept: 'image/jpeg,image/gif,image/png'
                                  }, {
                                    content: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_29, [
                                        _createVNode(_component_app_image_loader, {
                                          photoUrl: variation.image_url,
                                          class: "h-[70px] w-[70px] bg-gray-100 rounded-[10px] flex items-center justify-center"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_app_icon, {
                                              name: 'upload-image',
                                              class: "!h-[20px]"
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["photoUrl"])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["local-file-url", "onUpdate:localFileUrl", "modelValue", "onUpdate:modelValue"]),
                                  _createElementVNode("div", _hoisted_30, [
                                    _createVNode(_component_app_badge, {
                                      color: 'red',
                                      class: "!text-[9px] !rounded-[10px] !py-[1px] cursor-pointer",
                                      onClick: _withModifiers(($event: any) => (_ctx.removeVariant(index)), ["prevent"])
                                    }, {
                                      default: _withCtx(() => _cache[36] || (_cache[36] = [
                                        _createTextVNode(" Remove ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_31, [
                                  _createVNode(_component_app_content_editable, {
                                    placeholder: 'Variant Name',
                                    defaultValue: _ctx.formData.details.name,
                                    class: _normalizeClass('!bg-grey-50  dark:!bg-grey-800 px-2 py-1 !text-black dark:!text-white w-full focus:!outline-primary-500 rounded-[4px]'),
                                    modelValue: variation.name,
                                    "onUpdate:modelValue": ($event: any) => ((variation.name) = $event)
                                  }, null, 8, ["defaultValue", "modelValue", "onUpdate:modelValue"]),
                                  _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("div", _hoisted_33, [
                                      _createVNode(_component_AppTextField, {
                                        "focus-border": 'border-primary-400',
                                        "has-title": true,
                                        "custom-class": '!bg-grey-50 !rounded-[4px]',
                                        placeholder: 'Enter price',
                                        padding: 'px-2 py-1',
                                        name: "price",
                                        ref_for: true,
                                        ref: "price",
                                        type: "tel",
                                        "is-formatted": "",
                                        modelValue: variation.price,
                                        "onUpdate:modelValue": ($event: any) => ((variation.price) = $event)
                                      }, {
                                        title: _withCtx(() => _cache[37] || (_cache[37] = [
                                          _createTextVNode("Price ")
                                        ])),
                                        "inner-prefix": _withCtx(() => [
                                          _createElementVNode("span", _hoisted_34, [
                                            _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
                                              default: _withCtx(() => _cache[38] || (_cache[38] = [
                                                _createTextVNode("₦")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _createElementVNode("div", _hoisted_35, [
                                      _createVNode(_component_AppTextField, {
                                        type: 'tel',
                                        "focus-border": 'border-primary-400',
                                        "has-title": true,
                                        "custom-class": '!bg-grey-50 !rounded-[4px]',
                                        placeholder: 'Enter quantity',
                                        padding: 'px-2 py-1',
                                        name: "Stock quantity",
                                        ref_for: true,
                                        ref: "stock_quantity",
                                        modelValue: variation.stock_quantity,
                                        "onUpdate:modelValue": ($event: any) => ((variation.stock_quantity) = $event),
                                        "update-value": variation.stock_quantity
                                      }, {
                                        title: _withCtx(() => _cache[39] || (_cache[39] = [
                                          _createTextVNode(" Quantity ")
                                        ])),
                                        _: 2
                                      }, 1032, ["modelValue", "onUpdate:modelValue", "update-value"])
                                    ])
                                  ]),
                                  (_ctx.formAttributes.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                        _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                                            _createTextVNode(" Attributes ")
                                          ])),
                                          _: 1
                                        }),
                                        _createElementVNode("div", _hoisted_37, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formAttributes, (attribute, index) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              class: "pr-2 pt-2 flex flex-col",
                                              key: index
                                            }, [
                                              (variation.attributes[index])
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                                    _createVNode(_component_app_normal_text, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(variation.attributes[index]?.value), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_app_select, {
                                                      hasTitle: false,
                                                      theme: _ctx.Logic.Common.currentAppTheme(),
                                                      customClass: '!w-[30px]',
                                                      defaultSize: "!opacity-0 absolute top-0 left-0 h-full w-full",
                                                      paddings: 'py-1 px-2',
                                                      modelValue: variation.attributes[index].value,
                                                      "onUpdate:modelValue": ($event: any) => ((variation.attributes[index].value) = $event),
                                                      options: _ctx.getAttributeOptions(attribute)
                                                    }, null, 8, ["theme", "modelValue", "onUpdate:modelValue", "options"])
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ]))
                                          }), 128))
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]))
                            }), 128))
                          : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                              _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[41] || (_cache[41] = [
                                  _createTextVNode(" No product variations yet ")
                                ])),
                                _: 1
                              })
                            ]))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'product_pricing')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.pricing, (data, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
                        }, [
                          _createElementVNode("div", _hoisted_41, [
                            _createVNode(_component_AppNormalText, { class: "!font-semibold" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(data.area_name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _cache[43] || (_cache[43] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
                          _createElementVNode("div", {
                            class: _normalizeClass(`flex justify-between w-full ${
                    data.id == 'global' ? 'opacity-80' : ''
                  }`)
                          }, [
                            (!_ctx.hideFields)
                              ? (_openBlock(), _createBlock(_component_AppTextField, {
                                  key: 0,
                                  "focus-border": 'border-primary-400',
                                  "has-title": false,
                                  "custom-class": '!bg-grey-50',
                                  placeholder: 'Enter price',
                                  required: "",
                                  rules: [_ctx.FormValidations.RequiredRule],
                                  name: "Price per unit",
                                  ref_for: true,
                                  ref: `amount_${index}`,
                                  type: "tel",
                                  "is-formatted": "",
                                  modelValue: data.amount,
                                  "onUpdate:modelValue": ($event: any) => ((data.amount) = $event),
                                  disabled: data.id == 'global'
                                }, {
                                  "inner-prefix": _withCtx(() => [
                                    _createElementVNode("span", _hoisted_42, [
                                      _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
                                        default: _withCtx(() => _cache[42] || (_cache[42] = [
                                          _createTextVNode("NGN")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["rules", "modelValue", "onUpdate:modelValue", "disabled"]))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]))
                      }), 128)),
                      _createElementVNode("div", _hoisted_43, [
                        _createVNode(_component_app_button, {
                          class: "!py-1 !px-3 w-auto !rounded-[5px] cursor-pointer",
                          onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.showAddArea(null, -1)), ["prevent"]))
                        }, {
                          default: _withCtx(() => _cache[44] || (_cache[44] = [
                            _createTextVNode(" Add new store location ")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'product_reward')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createElementVNode("div", _hoisted_44, [
                        (
                  _ctx.formData.reward_settings.filter(
                    (item) => item.quantity == '-1'
                  ).length == 0
                )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                              _createVNode(_component_app_normal_text, { class: "text-secondary-main" }, {
                                default: _withCtx(() => _cache[45] || (_cache[45] = [
                                  _createTextVNode(" Please add a reward setting for any quantity purchased ")
                                ])),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: "w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAddReward(null, -1)))
                        }, [
                          _createVNode(_component_app_normal_text, { class: "text-center text-gray-700" }, {
                            default: _withCtx(() => _cache[46] || (_cache[46] = [
                              _createTextVNode(" + Add reward settings ")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_46, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.reward_settings, (data, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer",
                            onClick: ($event: any) => (_ctx.showAddReward(data, index))
                          }, [
                            _createElementVNode("div", _hoisted_48, [
                              _createVNode(_component_AppNormalText, { class: "!font-semibold" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(parseFloat(data.quantity) > 0 ? data.quantity : "Any") + " Quantit" + _toDisplayString(parseFloat(data.quantity) > 1 ? "ies" : "y"), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_app_badge, {
                                color: data.type == 'cashback' ? 'purple' : 'green',
                                class: _normalizeClass('capitalize')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(data.type), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"])
                            ]),
                            _cache[48] || (_cache[48] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
                            _createElementVNode("div", _hoisted_49, [
                              _createVNode(_component_AppNormalText, {
                                color: 'text-grey-700',
                                class: "!text-[12px]",
                                "custom-class": 'gap-2'
                              }, {
                                default: _withCtx(() => _cache[47] || (_cache[47] = [
                                  _createTextVNode(" Reward Percentage ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_AppNormalText, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(data.percentage) + "% ", 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ], 8, _hoisted_47))
                        }), 128))
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'product_commission')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createVNode(_component_AppTextField, {
                        "focus-border": 'border-primary-400',
                        "has-title": true,
                        "custom-class": '!bg-grey-50',
                        placeholder: 'Enter percentage',
                        name: "Affiliate Commission",
                        rules: [_ctx.FormValidations.RequiredRule],
                        required: _ctx.formData.product_commission.active,
                        type: "number",
                        modelValue: _ctx.formData.product_commission.percentage,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.product_commission.percentage) = $event)),
                        "update-value": _ctx.formData.product_commission.percentage
                      }, {
                        title: _withCtx(() => _cache[49] || (_cache[49] = [
                          _createTextVNode("Affiliate Commission ")
                        ])),
                        "inner-suffix": _withCtx(() => [
                          _createElementVNode("span", _hoisted_50, [
                            _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
                              default: _withCtx(() => _cache[50] || (_cache[50] = [
                                _createTextVNode("%")
                              ])),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["rules", "required", "modelValue", "update-value"]),
                      _createVNode(_component_app_checkbox, {
                        modelValue: _ctx.formData.product_commission.active,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.product_commission.active) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_app_normal_text, null, {
                            default: _withCtx(() => _cache[51] || (_cache[51] = [
                              _createTextVNode(" Allow Affiliate Sales ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'delivery')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                      _createVNode(_component_app_checkbox, {
                        modelValue: _ctx.formData.delivery.has_delivery,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData.delivery.has_delivery) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_app_normal_text, null, {
                            default: _withCtx(() => _cache[52] || (_cache[52] = [
                              _createTextVNode(" Enable Delivery ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      (_ctx.formData.delivery.has_delivery)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!_ctx.hideFields)
                              ? (_openBlock(), _createBlock(_component_AppSelect, {
                                  key: 0,
                                  "has-title": true,
                                  "placeholder-color": 'placeholder:text-black',
                                  "default-size": 'w-full',
                                  placeholder: 'Select',
                                  customClass: 'dark:!bg-grey-800',
                                  paddings: 'px-3 py-3',
                                  ref: 'weight',
                                  "auto-complete": "",
                                  options: _ctx.weightOptions,
                                  onOnOptionSelected: _ctx.onWeightChange,
                                  theme: _ctx.Logic.Common.currentAppTheme(),
                                  modelValue: _ctx.formData.details.weight,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.details.weight) = $event)),
                                  required: ""
                                }, {
                                  title: _withCtx(() => _cache[53] || (_cache[53] = [
                                    _createTextVNode("Product Weight")
                                  ])),
                                  _: 1
                                }, 8, ["options", "onOnOptionSelected", "theme", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.showCustomWeight)
                              ? (_openBlock(), _createBlock(_component_AppTextField, {
                                  key: 1,
                                  "focus-border": 'border-primary-400',
                                  "has-title": true,
                                  "custom-class": '!bg-grey-50',
                                  placeholder: 'Enter weight',
                                  name: "Custom weight",
                                  rules: [_ctx.FormValidations.RequiredRule],
                                  required: "",
                                  type: "tel",
                                  ref: "custom_weight",
                                  modelValue: _ctx.customWeight,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customWeight) = $event)),
                                  "update-value": _ctx.customWeight
                                }, {
                                  title: _withCtx(() => _cache[54] || (_cache[54] = [
                                    _createTextVNode(" Custom Weight (KG) ")
                                  ])),
                                  _: 1
                                }, 8, ["rules", "modelValue", "update-value"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_app_select, {
                              options: _ctx.shippingCategoryOptions,
                              "has-title": true,
                              placeholder: 'Select Category',
                              paddings: 'px-4 py-3',
                              "auto-complete": "",
                              modelValue: _ctx.formData.delivery.shipping_category_id,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formData.delivery.shipping_category_id) = $event)),
                              theme: _ctx.Logic.Common.currentAppTheme(),
                              required: ""
                            }, {
                              title: _withCtx(() => _cache[55] || (_cache[55] = [
                                _createTextVNode(" Shipping Category ")
                              ])),
                              _: 1
                            }, 8, ["options", "modelValue", "theme"]),
                            _createVNode(_component_AppSelect, {
                              "has-title": true,
                              "placeholder-color": 'placeholder:text-black',
                              "default-size": 'w-full',
                              placeholder: 'Select',
                              paddings: 'px-4 py-3',
                              ref: 'product_size',
                              options: _ctx.packageDimensionOptions,
                              theme: _ctx.Logic.Common.currentAppTheme(),
                              modelValue: _ctx.formData.details.package_dimension_id,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formData.details.package_dimension_id) = $event)),
                              required: ""
                            }, {
                              title: _withCtx(() => _cache[56] || (_cache[56] = [
                                _createTextVNode(" Package Dimension ")
                              ])),
                              _: 1
                            }, 8, ["options", "theme", "modelValue"])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.formData.delivery.has_delivery)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                            (_ctx.ManyDeliveryLocations?.data.length == 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                                  _createVNode(_component_app_normal_text, { class: "text-center text-gray-700 dark:!text-gray-200" }, {
                                    default: _withCtx(() => _cache[57] || (_cache[57] = [
                                      _createTextVNode(" You have no pick up areas yet ")
                                    ])),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.deliveryAreas.length > 0)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createElementVNode("div", _hoisted_53, [
                                    _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                      default: _withCtx(() => _cache[58] || (_cache[58] = [
                                        _createTextVNode(" Select the pickup areas for this product ")
                                      ])),
                                      _: 1
                                    }),
                                    _createElementVNode("div", _hoisted_54, [
                                      _createVNode(_component_app_button, {
                                        class: "!py-1 !px-3 w-auto !rounded-[5px] cursor-pointer",
                                        onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_ctx.showAddArea(null, -1)), ["prevent"]))
                                      }, {
                                        default: _withCtx(() => _cache[59] || (_cache[59] = [
                                          _createTextVNode(" Create new pickup areas ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_55, [
                                      _createVNode(_component_app_checkbox, {
                                        modelValue: _ctx.selectedAllAreas,
                                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.selectedAllAreas) = $event)),
                                        class: "!text-gray-500 dark:!text-gray-400 !text-[12px]"
                                      }, {
                                        default: _withCtx(() => _cache[60] || (_cache[60] = [
                                          _createTextVNode(" Select all areas ")
                                        ])),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ])
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryAreas, (data, index) => {
                                    return (_openBlock(), _createBlock(_component_app_business_pick_area, {
                                      class: _normalizeClass(` border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer ${
                    _ctx.formData.delivery.areas.includes(data.id)
                      ? 'border-primary-400 border-[2px]'
                      : 'border-grey-50'
                  }`),
                                      key: index,
                                      data: data,
                                      onClick: _withModifiers(($event: any) => (_ctx.toggleFromDeliveryArea(data)), ["stop"])
                                    }, null, 8, ["class", "data", "onClick"]))
                                  }), 128))
                                ], 64))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[61] || (_cache[61] = _createElementVNode("div", { class: "h-[70px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, { "custom-class": "pt-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppButton, {
                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.continueToNext())),
                "custom-class": 'h-fit w-full ',
                padding: 'py-3 px-3',
                class: _normalizeClass(`${!_ctx.formIsValid ? 'opacity-50' : ''}`),
                loading: _ctx.updateLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isEdit ? "Update" : "Continue"), 1)
                ]),
                _: 1
              }, 8, ["class", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (!_ctx.isEdit)
          ? {
              name: "extra-topbar",
              fn: _withCtx(() => [
                _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 dark:!text-primary-300' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString("Step " +
            `(${_ctx.productCreationStep.current + "/" + _ctx.productCreationStep.total})`), 1)
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}