<template>
  <app-wrapper>
    <subpage-layout
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
    >
      <!-- Add to basket -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            :class="`!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white`"
            v-if="!fromPreview"
            @click="handleAddToCart"
            id="add-to-cart-button"
          >
            {{ "Add to Offer" }}
          </app-button>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-row px-4 justify-between items-center">
          <div class="flex flex-col space-y-1 justify-start">
            <app-header-text class="!text-base">
              {{ SingleShoplistProduct?.name }}
            </app-header-text>
            <div class="w-full flex flex-row space-x-2 items-center">
              <!-- <app-normal-text> 1.4k Shoppers </app-normal-text>
            <span
              class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50"
            >
            </span> -->
              <div class="flex flex-row items-center space-x-1">
                <app-normal-text class="!text-success-500">
                  Earn
                  {{ mediaData?.affiliate_commission_percentage?.toFixed(2) }}%
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div
          class="w-full flex flex-col space-y-2 px-4"
          v-if="SingleShoplistProduct?.description"
        >
          <app-normal-text
            class="!text-left !text-[#5A5A5A] dark:!text-grey-200"
          >
            {{ SingleShoplistProduct?.description }}
          </app-normal-text>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-1 px-4">
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-col space-y-3 border-b-[1px] border-grey-100 py-3"
          >
            <div class="w-full flex flex-col space-y-2">
              <app-swiper
                :free-mode="false"
                :show-pagination="false"
                :space-between="10"
                :slide-per-view="1"
                :currentSlidePosition="currentSlidePosition"
                custom-class="h-[300px]"
                :swiperClass="''"
                v-model="slidePosition"
                id="swiperContainerProducts"
              >
                <swiper-slide
                  class="w-full h-full"
                  v-for="(image, index) in images"
                  :key="index"
                >
                  <app-image-loader
                    @click="showFullImages = true"
                    :photoUrl="image"
                    class="rounded-[10px] h-[300px] w-full border-[1px] border-gray-200 dark:!border-gray-700 cursor-pointer"
                  />
                </swiper-slide>
              </app-swiper>

              <div
                class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
              >
                <div class="flex flex-row py-2 pr-4">
                  <div
                    class="flex flex-row cursor-pointer pr-2"
                    v-for="(image, index) in images"
                    :key="index"
                    @click="currentSlidePosition = index"
                    :id="`image${index}`"
                  >
                    <app-image-loader
                      :photoUrl="image"
                      :class="`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        currentSlidePosition == index
                          ? '!border-primary-main'
                          : ''
                      }`"
                    />
                  </div>
                </div>
              </div>

              <!-- Attributes -->
              <div
                class="w-full grid grid-cols-2 gap-3 pb-2"
                v-if="productAttributes.length"
              >
                <div
                  class="col-span-1 flex flex-col space-y-[1px]"
                  v-for="(attribute, index) in productAttributes"
                  :key="index"
                >
                  <app-normal-text class="!text-left !font-semibold">
                    {{ attribute.name }}
                  </app-normal-text>
                  <div class="w-full flex flex-row flex-wrap">
                    <div
                      class="pr-2 pt-2 flex flex-col cursor-pointer"
                      v-for="(
                        attributeValue, index
                      ) in attribute.existing_values"
                      :key="index"
                      @click="selectOption(attribute.name, attributeValue)"
                    >
                      <div
                        :class="`px-2 py-[2px] bg-primary-50 dark:!bg-primary-500 rounded-[4px] flex flex-row items-center justify-center space-x-2 ${
                          itemSelected(attribute.name, attributeValue)
                            ? '!border-primary-main !border-[2px] dark:!border-primary-50'
                            : '!border-[2px] !border-transparent'
                        }`"
                      >
                        <app-normal-text>{{ attributeValue }}</app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-row space-x-2">
              <div
                class="flex flex-col items-end w-full justify-between space-y-1"
              >
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.weight }}
                    </app-normal-text>
                    -->

                    <app-badge
                      :color="
                        item.reward_type == 'cashback' ? 'purple' : 'purple'
                      "
                      class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                    >
                      Save ₦{{ numberToAbbrev(item.reward_point) }}
                    </app-badge>

                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                    >
                    </span>

                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                    >
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-col">
                  <!-- Items left in stock -->
                  <div class="w-full flex flex-row pt-3">
                    <app-normal-text
                      :class="` ${
                        (currentVariant?.inventory_quantity || 0) > 5
                          ? '!text-success-main'
                          : '!text-secondary-700'
                      }`"
                      v-if="(currentVariant?.inventory_quantity || 0) > 0"
                    >
                      <span class="!font-bold">{{
                        currentVariant?.inventory_quantity
                      }}</span>
                      left in stock
                    </app-normal-text>
                    <app-normal-text class="!text-error-main" v-else>
                      Out of stock
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Similar products -->
        <div class="w-full flex flex-col space-y-3 px-4" v-if="false">
          <app-header-text class="!text-base !font-semibold">
            Similar products
          </app-header-text>
          <div class="w-full grid grid-cols-2 gap-2">
            <app-product
              v-for="(item, index) in similarProducts"
              :key="index"
              :item="item"
              @click="handleShoplsistClick(item)"
            />
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Image viewer -->
      <Teleport to="#modal" v-if="showFullImages">
        <fixed-container
          class="!z-[9999999999999999] !h-full !w-full !fixed !top-0 !left-0"
          baseColor="bg-black !bg-opacity-50"
          :customClass="'!border-none !h-screen '"
          @click="showFullImages = false"
        >
          <div class="!h-full !w-full flex flex-col justify-between">
            <!-- Top -->
            <div
              class="w-full flex flex-row justify-between pb-2"
              style="
                padding-top: calc(env(safe-area-inset-top) + 16px) !important;
              "
            >
              <span
                class="h-[30px] w-[30px] rounded-full flex flex-row items-center justify-center border-[2px] border-white cursor-pointer"
              >
                <app-icon name="close-white" :customClass="'!h-[10px]'" />
              </span>
            </div>

            <!-- Image content -->
            <div class="w-full flex flex-col !h-[80%]" @click.stop="null">
              <app-swiper
                :free-mode="false"
                :show-pagination="false"
                :space-between="10"
                :slide-per-view="1"
                :currentSlidePosition="currentSlidePosition"
                custom-class="h-full"
                :swiperClass="'h-full'"
                v-model="slidePosition"
                id="swiperContainerProductsFull"
              >
                <swiper-slide
                  class="w-full h-full"
                  v-for="(image, index) in images"
                  :key="index"
                >
                  <app-image-loader
                    :photoUrl="image"
                    class="rounded-[10px] h-full w-full border-[1px] border-gray-200 dark:!border-gray-700"
                  />
                </swiper-slide>
              </app-swiper>
            </div>

            <!-- Bottom -->
            <div class="w-full flex flex-col pt-1" @click.stop="null">
              <div
                class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
              >
                <div class="flex flex-row py-2 pr-4">
                  <div
                    class="flex flex-row cursor-pointer pr-2"
                    v-for="(image, index) in images"
                    :key="index"
                    @click.stop="currentSlidePosition = index"
                    :id="`image${index}`"
                  >
                    <app-image-loader
                      :photoUrl="image"
                      :class="`rounded-[10px] h-[70px] w-[70px] border-[2px] border-gray-200 dark:!border-gray-700 ${
                        currentSlidePosition == index
                          ? '!border-primary-main'
                          : ''
                      }`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fixed-container>
      </Teleport>

      <!-- Bottom section -->
      <fixed-container customClass="!px-0" class="z-50">
        <div
          class="w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black"
        >
          <div class="flex flex-col space-y-[3px]">
            <app-header-text class="!text-base">
              {{ Logic.Common.convertToMoney(totalCost, true, "ngn") }}
            </app-header-text>
            <div
              class="flex flex-row space-x-1 justify-start flex-grow items-center"
            >
              <app-normal-text class="!text-[#898989] dark:!text-grey-200"
                >Earn
                {{ mediaData?.affiliate_commission_percentage?.toFixed(2) }}%
              </app-normal-text>
            </div>
          </div>
          <div
            class="py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer"
            @click="handleAddToCart"
          >
            <app-normal-text class="!font-semibold !text-white">
              Add to Offer
            </app-normal-text>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppBadge,
  AppProduct,
  AppIcon,
  AppSwiper,
} from "@shpt/ui-components";
import {
  getMediaBoxForProduct,
  getUserPlanCashbackCommissionForProduct,
} from "@shpt/ui-components/src/composable";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";
import { scrollToTop } from "@shpt/ui-components/src/composable";
import { SwiperSlide } from "swiper/vue";

type ProductList = {
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  reward_percentage: number;
  reward_point: number;
  cashback: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppBadge,
    AppWrapper,
    AppProduct,
    AppIcon,
    AppSwiper,
    FixedContainer,
    SwiperSlide,
  },
  name: "ProductInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistProduct",
        method: "GetShoplistProduct",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Product Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const SingleShoplistProduct = ref(Logic.Shoplist.SingleShoplistProduct);
    const ManySimilarProducts = ref(Logic.Shoplist.ManySimilarProducts);

    const currentSlidePosition = ref(0);
    const slidePosition = ref(0);
    const showFullImages = ref(false);

    const selectedOptions = reactive<
      {
        attribute_id: string;
        value: string;
      }[]
    >([]);

    const similarProducts = reactive<
      {
        base_image_url: string;
        title: string;
        price: number;
        resale_commission: string;
        cashback: string;
        available_stock: number;
        merchant: {
          name: string;
          profile_image_url: string;
        };
        data: ShoplistProduct;
      }[]
    >([]);

    const fromPreview = ref(false);

    const getRewardPercentage = (item: ProductList) => {
      const rewards = item.data.rewards;
      let currentReward = rewards.find(
        (reward) => reward.quantity == item.qty.toString()
      );

      if (!currentReward) {
        currentReward = rewards.find((reward) => reward.quantity == "-1");
      }

      let rewardPercentage = 0;

      if (currentReward) {
        rewardPercentage = parseFloat(currentReward.percentage);
      }

      let percentageToRemove = 0;

      const userPlanCashbackCommission =
        getUserPlanCashbackCommissionForProduct(item.data);

      if (userPlanCashbackCommission.cashbackCommission > 0) {
        const percentageCommissionToRemove =
          userPlanCashbackCommission.cashbackCommission / 100;

        percentageToRemove = rewardPercentage * percentageCommissionToRemove;
      }

      rewardPercentage = rewardPercentage - percentageToRemove;

      return rewardPercentage;
    };

    const currentVariant = computed(() => {
      if (productAttributes.value.length) {
        //  Variant that match selected options
        const selectedVariant = SingleShoplistProduct.value?.variants?.find(
          (variant) => {
            return variant?.selected_options?.every((option) => {
              return selectedOptions.find(
                (selectedOption) =>
                  selectedOption.attribute_id == option.name &&
                  selectedOption.value == option.value
              );
            });
          }
        );
        return selectedVariant
          ? selectedVariant
          : SingleShoplistProduct.value?.variants
          ? SingleShoplistProduct.value?.variants[0]
          : null;
      } else {
        return SingleShoplistProduct.value?.variants
          ? SingleShoplistProduct.value?.variants[0]
          : null;
      }
    });

    const productAttributes = computed(() => {
      const attributes: {
        id: string;
        name: string;
        value: string;
        existing_values: string[];
      }[] = [];

      SingleShoplistProduct.value?.variants?.forEach((variant) => {
        variant?.selected_options?.forEach((item) => {
          const attributExist = attributes.find(
            (attribute) => attribute.name == item.name
          );
          if (!attributExist) {
            attributes.push({
              id: Logic.Common.makeid(10),
              name: item.name,
              value: "",
              existing_values: [item.value],
            });
          } else {
            const itemIsInExistingValues = attributExist.existing_values.find(
              (singleItem) => singleItem == item.value
            );
            if (!itemIsInExistingValues) {
              attributExist.existing_values.push(item.value);
            }
          }
        });
      });
      return attributes;
    });

    const images = computed(() => {
      const images: any[] = [];

      if (!SingleShoplistProduct.value?.medias?.length) {
        images.push(SingleShoplistProduct.value?.primary_image_url);
      } else {
        const mediaImages = SingleShoplistProduct.value?.medias.map(
          (item) => item.media_url
        );
        images.push(...(mediaImages ? mediaImages : []));
      }

      return images;
    });

    const itemSelected = (attribute_name: string, value: string) => {
      return selectedOptions.find(
        (item) => item.attribute_id == attribute_name && item.value == value
      );
    };

    const selectOption = (attribute_name: string, value: string) => {
      //  Check if item with attribute_name already exists in selectedOptions
      const attributeIsSelected = selectedOptions?.find(
        (item) => item.attribute_id == attribute_name
      );
      if (attributeIsSelected) {
        attributeIsSelected.value = value;
      } else {
        selectedOptions.push({ attribute_id: attribute_name, value });
      }
    };

    const totalReward = computed(() => {
      let total = 0;

      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          if (currentReward.type == "cashback") {
            const points =
              ((item.price * item.qty * rewardPercentage) / 100) * 10;
            total += points;
            item.cashback = rewardPercentage;
          }
          item.reward_type = currentReward.type;
        } else {
          const currentReward = rewards.find(
            (reward) => reward.quantity == "-1"
          );
          if (currentReward) {
            if (currentReward.type == "cashback") {
              const points =
                ((item.price * item.qty * rewardPercentage) / 100) * 10;
              total += points;
              item.cashback = rewardPercentage;
            }
            item.reward_type = currentReward.type;
          }
        }
      });
      return total;
    });

    const totalDiscount = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          item.reward_type = currentReward.type;
          if (currentReward.type == "discount") {
            const discount = item.price * item.qty * (rewardPercentage / 100);
            total += discount;
            item.discount = discount;
          }
        } else {
          const currentReward = rewards.find(
            (reward) => reward.quantity == "-1"
          );
          if (currentReward) {
            item.reward_type = currentReward.type;
            if (currentReward.type == "discount") {
              const discount = item.price * item.qty * (rewardPercentage / 100);
              total += discount;
              item.discount = discount;
            }
          }
        }
      });
      return total;
    });

    const totalCost = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        total += item.price * item.qty - item.discount;
      });
      return total;
    });

    const addedToCart = computed(() => {
      return false;
    });

    const mediaData = computed(() => {
      return SingleShoplistProduct.value
        ? getMediaBoxForProduct(SingleShoplistProduct.value)
        : undefined;
    });

    const productLists = reactive<ProductList[]>([]);

    const createProductList = () => {
      productLists.length = 0;

      if (!SingleShoplistProduct.value) {
        return;
      }

      [SingleShoplistProduct.value].forEach((product) => {
        let discount = 0;
        let reward_percentage = 0;
        let reward_point = 0;
        let cashback = 0;
        let rewardType = "";
        let currentReward = product?.rewards.find(
          (reward) => reward.quantity == "1"
        );

        if (!currentReward) {
          currentReward = product?.rewards.find(
            (reward) => reward.quantity == "-1"
          );
        }

        let finalRewardPercentage = 0;

        if (currentReward) {
          finalRewardPercentage = parseFloat(currentReward.percentage);

          let percentageToRemove = 0;

          const userPlanCashbackCommission =
            getUserPlanCashbackCommissionForProduct(product);

          if (userPlanCashbackCommission.cashbackCommission > 0) {
            const percentageCommissionToRemove =
              userPlanCashbackCommission.cashbackCommission / 100;

            percentageToRemove =
              finalRewardPercentage * percentageCommissionToRemove;
          }

          finalRewardPercentage = finalRewardPercentage - percentageToRemove;
        }

        if (currentReward) {
          rewardType = currentReward.type;
          if (currentReward.type == "discount") {
            discount =
              parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (currentReward.type == "cashback") {
            cashback = finalRewardPercentage;
          }

          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) /
              100) *
            10;

          reward_percentage = finalRewardPercentage;
        } else {
          const baseReward = product.rewards.find(
            (reward) => reward.quantity == "-1"
          );
          rewardType = baseReward?.type || "";
          if (baseReward?.type == "discount") {
            discount =
              parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (baseReward?.type == "cashback") {
            cashback = finalRewardPercentage;
          }

          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) /
              100) *
            10;
          reward_percentage = finalRewardPercentage;
        }

        productLists.push({
          base_image: product.primary_image_url,
          main_category: product.category.name,
          price: parseFloat(product.regular_price),
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: product.stock_quantity,
          reward_type: rewardType,
          discount,
          reward_percentage,
          reward_point,
          cashback,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
        });
      });

      if (Logic.Common.currentBuildType() == "web") {
        // useMeta({
        //   title: `${SingleShoplistProduct.value?.name}`,
        // });
        document.title = `${SingleShoplistProduct?.value?.name} | Shoppoint Retailers`;
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.preview?.toString()) {
          fromPreview.value = true;
        }
      } else {
        fromPreview.value = true;
      }
    };

    const handleAddToCart = () => {
      Logic.Common.showModal({
        show: true,
        title: "Add to Offer",
        type: "add_product_to_shoplists",
        extraData: {
          product_id: SingleShoplistProduct.value?.id,
          fromBusiness: true,
        },
        action: () => {
          console.log("add to offer");
        },
      });
    };

    const setProduct = (product: ShoplistProduct) => {
      const generalReward = product.rewards.filter(
        (item) => item.quantity == "-1"
      );
      return {
        base_image_url: product.primary_image_url,
        title: product.name,
        price: parseFloat(product.regular_price),
        available_stock: product.stock_quantity,
        cashback: generalReward[0]?.percentage,
        merchant: {
          name: product.business?.business_name || "",
          profile_image_url: product.business?.photo_url || "",
        },
        resale_commission: product.affiliate_commission || "",
        data: product,
      };
    };

    const setSimilarProducts = () => {
      similarProducts.length = 0;

      ManySimilarProducts.value?.forEach((product) => {
        similarProducts.push(setProduct(product));
      });
    };

    const handleShoplsistClick = (item: any) => {
      Logic.Common.GoToRoute(`/products/${item.data?.uuid}`);
    };

    watch(ManySimilarProducts, () => {
      setSimilarProducts();
    });

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
    });

    watch(SingleShoplistProduct, () => {
      createProductList();
      scrollToTop();
    });

    onIonViewWillEnter(() => {
      createProductList();
      setSimilarProducts();
      setPageState();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "SingleShoplistProduct",
        SingleShoplistProduct
      );
      Logic.Shoplist.watchProperty("ManySimilarProducts", ManySimilarProducts);
      createProductList();
      setSimilarProducts();
      setPageState();
      // Scroll to top of #main-section after component is mounted
      scrollToTop();
    });

    return {
      Logic,
      productLists,
      SingleShoplistProduct,
      mediaData,
      fromPreview,
      totalReward,
      addedToCart,
      totalCost,
      totalDiscount,
      similarProducts,
      currentSlidePosition,
      slidePosition,
      showFullImages,
      images,
      selectedOptions,
      currentVariant,
      productAttributes,
      handleAddToCart,
      numberToAbbrev,
      handleShoplsistClick,
      itemSelected,
      selectOption,
    };
  },
});
</script>
