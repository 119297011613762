import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pl-4 pb-3" }
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_7 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[300px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_order = _resolveComponent("app-order")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'All Orders' }, {
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(`px-4 py-1  cursor-pointer ${
                  _ctx.selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-800'
                }  rounded-[20px]  flex flex-row items-center justify-center`),
                    onClick: ($event: any) => (_ctx.selectedFilter = item.id)
                  }, [
                    _createVNode(_component_app_normal_text, {
                      class: _normalizeClass(`${
                    _ctx.selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`),
                      "custom-class": 'whitespace-nowrap'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.orders.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_app_empty_state, {
                      title: `No ${
                _ctx.selectedFilter == 'all' ? '' : _ctx.selectedFilter
              } orders here`,
                      subTitle: 'You can check back later'
                    }, null, 8, ["title"])
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.orders, (order, index) => {
                    return (_openBlock(), _createBlock(_component_app_order, {
                      key: index,
                      class: "cursor-pointer",
                      data: order,
                      onClick: ($event: any) => (_ctx.showOrderInfo(order.code, order.data, true))
                    }, null, 8, ["data", "onClick"]))
                  }), 128))
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}