import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 pt-4"
}
const _hoisted_2 = { class: "pr-1" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-row justify-end" }
const _hoisted_5 = { class: "pr-1" }
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 pt-4"
}
const _hoisted_7 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_8 = { class: "flex flex-col space-y-2 items-center justify-center" }
const _hoisted_9 = { class: "w-full flex flex-row items-center justify-center px-3 py-3 space-x-2 border-[1px] border-[#5A189A] rounded-full cursor-pointer" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-3 pt-3" }
const _hoisted_11 = { class: "w-full flex flex-col pt-4 bg-white dark:!bg-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.title,
        goBackHandler: _ctx.goBackHandler
      }, {
        "extra-topbar": _withCtx(() => [
          _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 ' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString("Step " + `(${_ctx.onboardingStep.current + "/" + _ctx.onboardingStep.total})`), 1)
            ]),
            _: 1
          })
        ]),
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`${
            _ctx.onboardingStep.current == _ctx.onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onboardingStep.total, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass(`${
              _ctx.onboardingStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
              }, null, 2))
            }), 128))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_app_form_wrapper, {
            parentRefs: _ctx.parentRefs,
            class: "w-full flex flex-col space-y-2 px-4",
            ref: "formComp"
          }, {
            default: _withCtx(() => [
              (_ctx.onboardingStep.current == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_AppTextField, {
                      "has-title": true,
                      "custom-class": '',
                      padding: "px-3 py-3",
                      placeholder: 'Business Name',
                      "focus-border": 'border-primary-400',
                      rules: [_ctx.FormValidations.RequiredRule],
                      required: "",
                      ref: "business_name",
                      name: "Business name",
                      modelValue: _ctx.formData.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event))
                    }, {
                      title: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Business Name")
                      ])),
                      "inner-prefix": _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, [
                          _createVNode(_component_app_icon, {
                            name: 'shopping-bag',
                            customClass: 'h-[16px]'
                          })
                        ])
                      ]),
                      _: 1
                    }, 8, ["rules", "modelValue"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_app_text_field, {
                        placeholder: 'Enter handle',
                        hasTitle: true,
                        type: 'text',
                        ref: "usernameRef",
                        modelValue: _ctx.username,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
                        name: 'Username',
                        rules: [
                _ctx.FormValidations.RequiredRule,
                _ctx.FormValidations.customValidator(
                  _ctx.username.indexOf(' ') <= 0,
                  'Handle cannot contain white space'
                ),
                _ctx.FormValidations.Alphanumeric,
              ],
                        "update-value": _ctx.username,
                        "content-rule": {
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              }
                      }, {
                        "inner-prefix": _withCtx(() => [
                          _createVNode(_component_app_normal_text, null, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode(" @ ")
                            ])),
                            _: 1
                          })
                        ]),
                        title: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode(" Business Handle ")
                        ])),
                        _: 1
                      }, 8, ["modelValue", "rules", "update-value"]),
                      _createElementVNode("div", _hoisted_4, [
                        (_ctx.resolvingUsername)
                          ? (_openBlock(), _createBlock(_component_app_normal_text, {
                              key: 0,
                              color: 'text-primary-main'
                            }, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode(" Resolving handle... ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
                  !_ctx.resolvingUsername &&
                  _ctx.username != _ctx.AuthUser?.profile?.business?.business_tag &&
                  _ctx.username
                )
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (!_ctx.usernameExist)
                                ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                    key: 0,
                                    color: 'text-success-main'
                                  }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createElementVNode("span", { class: "px-1" }, "Available", -1),
                                      _createTextVNode(" ✅ ")
                                    ])),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_app_normal_text, {
                                    key: 1,
                                    color: 'text-error-main'
                                  }, {
                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                      _createElementVNode("span", { class: "px-1" }, "Not available", -1),
                                      _createTextVNode(" ❌ ")
                                    ])),
                                    _: 1
                                  }))
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createVNode(_component_AppSelect, {
                      "has-title": true,
                      placeholder: 'Select account type',
                      paddings: 'px-3 py-3',
                      options: _ctx.accountType,
                      ref: "account_type",
                      modelValue: _ctx.formData.account_type,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.account_type) = $event))
                    }, {
                      title: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" Account Type ")
                      ])),
                      "inner-prefix": _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, [
                          _createVNode(_component_app_icon, {
                            name: 'grid',
                            customClass: 'h-[16px]'
                          })
                        ])
                      ]),
                      _: 1
                    }, 8, ["options", "modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.onboardingStep.current == 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold text-left" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formData.account_type == "individual"
                  ? "Display Picture"
                  : "Business Logo"), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", {
                          style: _normalizeStyle(`background-image: url(${_ctx.photoUrl});  background-size: cover;
             background-repeat: no-repeat;
             background-position: center;`),
                          class: "h-[85px] w-[85px] rounded-full bg-gray-100"
                        }, null, 4),
                        _createVNode(_component_app_file_attachment, {
                          "is-wrapper": true,
                          modelValue: _ctx.formData.business_logo,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.business_logo) = $event)),
                          "local-file-url": _ctx.photoUrl,
                          "onUpdate:localFileUrl": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.photoUrl) = $event)),
                          accept: `image/png, image/gif, image/jpeg`
                        }, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_app_icon, {
                                name: 'image-purple',
                                customClass: 'h-[15px]'
                              }),
                              _createVNode(_component_app_normal_text, { color: 'text-[#5A189A]' }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode(" Upload ")
                                ])),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "local-file-url"])
                      ])
                    ]),
                    _createVNode(_component_app_text_field, {
                      "has-title": true,
                      "is-textarea": "",
                      "custom-class": '',
                      placeholder: 
              _ctx.formData.account_type == 'individual'
                ? 'Enter Bio'
                : 'About Business'
            ,
                      "focus-border": 'border-primary-400',
                      name: 
              _ctx.formData.account_type == 'individual' ? 'Bio' : 'About Business'
            ,
                      ref: "about_business",
                      modelValue: _ctx.formData.about_business,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.about_business) = $event)),
                      rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.formData.about_business.length <= 250,
                'Maximum of 250 Characters'
              ),
            ],
                      "max-character": 250,
                      required: ""
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formData.account_type == "individual" ? "Bio" : "About Business"), 1)
                      ]),
                      _: 1
                    }, 8, ["placeholder", "name", "modelValue", "rules"]),
                    _createVNode(_component_app_text_field, {
                      "has-title": true,
                      "custom-class": '',
                      padding: "px-3 py-3",
                      placeholder: 'Enter Phone Number',
                      ref: "phone_number",
                      "focus-border": 'border-primary-400',
                      modelValue: _ctx.formData.business_phone_number,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.business_phone_number) = $event)),
                      rules: [_ctx.FormValidations.RequiredRule],
                      name: "Phone number",
                      type: "tel",
                      required: ""
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formData.account_type == "individual"
                  ? "Phone Number"
                  : "Business Phone Number"), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold w-full border-b-[1px] border-gray-200 pb-2" }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode(" Social Media ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_AppTextField, {
                        "has-title": true,
                        "custom-class": '',
                        placeholder: 'username',
                        ref: "facebook",
                        name: "Facebook",
                        "focus-border": 'border-primary-400',
                        modelValue: _ctx.formData.facebook,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.facebook) = $event))
                      }, {
                        title: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode(" Facebook ")
                        ])),
                        "inner-prefix": _withCtx(() => [
                          _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                              _createTextVNode(" facebook.com/ ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_AppTextField, {
                        "has-title": true,
                        "custom-class": '',
                        placeholder: 'username',
                        ref: "instagram",
                        name: "Instagram",
                        "focus-border": 'border-primary-400',
                        modelValue: _ctx.formData.instagram,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.instagram) = $event))
                      }, {
                        title: _withCtx(() => _cache[23] || (_cache[23] = [
                          _createTextVNode(" Instagram ")
                        ])),
                        "inner-prefix": _withCtx(() => [
                          _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode(" instagram.com/ ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_AppTextField, {
                        "has-title": true,
                        "custom-class": '',
                        placeholder: '0000000000',
                        "focus-border": 'border-primary-400',
                        ref: "whatsapp",
                        name: "whatsapp",
                        type: "tel",
                        modelValue: _ctx.formData.whatsapp,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.whatsapp) = $event))
                      }, {
                        title: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode(" Whatsapp ")
                        ])),
                        "inner-prefix": _withCtx(() => [
                          _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                            default: _withCtx(() => _cache[26] || (_cache[26] = [
                              _createTextVNode(" wa.me/ ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_AppTextField, {
                        "has-title": true,
                        "custom-class": '',
                        placeholder: 'https://',
                        ref: "website",
                        name: "website",
                        "focus-border": 'border-primary-400',
                        rules: [_ctx.FormValidations.WebsiteRule],
                        modelValue: _ctx.formData.website,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.website) = $event))
                      }, {
                        title: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode(" Website ")
                        ])),
                        _: 1
                      }, 8, ["rules", "modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "h-[120px]" }, null, -1)),
              _createVNode(_component_fixed_container, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_AppButton, {
                      padding: 'py-3',
                      "bg-color": 'bg-primary-400',
                      "text-color": 'text-white',
                      class: _normalizeClass(`w-full ${_ctx.formItemIsValid ? '' : 'opacity-[50%]'}`),
                      onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.moveForward()), ["prevent"]))
                    }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createTextVNode(" Continue ")
                      ])),
                      _: 1
                    }, 8, ["class"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["parentRefs"])
        ]),
        _: 1
      }, 8, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}