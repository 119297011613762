<template>
  <app-wrapper>
    <dashboard-layout
      :title="`${
        Logic.Auth.GetCurrentBusiness()?.account_type == 'individual'
          ? 'Products'
          : 'Products '
      }`"
      topBarType="plain"
      :pageKey="'merchant-products'"
      :hasAddButton="Logic.Auth.BusinessUserCan('manage_products')"
      :addButtonAction="
        () => {
          showAddProduct();
        }
      "
    >
      <div class="w-full flex flex-col space-y-3 px-4 pt-4">
        <!-- Search and add -->
        <div class="w-full flex flex-row space-x-2 items-center">
          <AppTextField :placeholder="`Search products`" v-model="searchQuery">
            <template v-slot:inner-prefix>
              <AppIcon :custom-class="''" :name="'search'"></AppIcon>
            </template>
            <!-- <template v-slot:inner-suffix>
              <AppIcon :custom-class="''" :name="'filter-icon'"></AppIcon>
            </template> -->
          </AppTextField>
        </div>

        <div class="w-full flex flex-col">
          <app-switch :items="productSwitch" v-model="selectedSwitchProducts" />
        </div>

        <!-- Loading -->
        <div
          class="w-full flex flex-col items-center justify-center h-[400px]"
          v-if="isLoading"
        >
          <svg
            :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>

        <!-- Products -->
        <div class="w-full flex flex-col" v-else>
          <template v-if="products.length > 0">
            <app-virtual-scroller
              :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
              :data="products"
              :pagination="paginatorInfo"
              :fetchMore="fetchMoreProducts"
              :direction="'vertical'"
              ref="productVirtualScrollerRef"
            >
              <template #item-content="{ index, item }">
                <div :key="index" class="col-span-1 flex flex-col">
                  <app-product
                    class="!cursor-pointer"
                    @click="
                      Logic.Auth.BusinessUserCan('manage_products')
                        ? selectProduct(item.data)
                        : null
                    "
                    :data="item"
                    :show-commission="
                      selectedSwitchProducts == 'affiliate_products'
                    "
                  />
                </div>
              </template>
              <template #skeleton-loaders>
                <div class="col-span-1 flex flex-col">
                  <div
                    class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                  ></div>
                </div>
                <div class="col-span-1 flex flex-col">
                  <div
                    class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </template>
          <div
            v-else
            class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
          >
            <app-empty-state
              :title="'No Products Here'"
              :subTitle="'All available products will show up here'"
            >
            </app-empty-state>
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppTextField,
  AppIcon,
  AppVirtualScroller,
  AppProduct,
  AppEmptyState,
  AppSwitch,
  getMediaBoxForProduct,
} from "@shpt/ui-components";
import { ShoplistProduct } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import { PaginatorInfo } from "@shpt/logic/dist/esm/gql/graphql";
import { selectedSwitchProducts } from "@/composables";

export default defineComponent({
  components: {
    AppTextField,
    AppIcon,
    AppProduct,
    AppEmptyState,
    AppWrapper,
    AppVirtualScroller,
    AppSwitch,
  },
  name: "MerchantProductsPage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyShoplistProducts",
        method: "GetShoplistProducts",
        params: [
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
            column: BUSINESS_PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.id}
             AND: {
              column: STATUS
              operator: EQ
              value: "active"
            }
            }
            `,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Merchant Products Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Merchant Products",
    });

    const searchQuery = ref("");

    const ManyShoplistProducts = ref(Logic.Shoplist.ManyShoplistProducts);

    const productVirtualScrollerRef = ref();

    const paginatorInfo = ref<PaginatorInfo | undefined>(undefined);

    const isLoading = ref(false);

    const productSwitch = reactive([
      {
        name: "Active",
        key: "published_products",
      },
      {
        name: "Draft",
        key: "unpublished_products",
      },
      {
        name: "Affiliate",
        key: "affiliate_products",
      },
    ]);

    const products = reactive<
      {
        base_image_url: string;
        title: string;
        price: number;
        resale_commission: string;
        cashback: string;
        available_stock: number;
        merchant: {
          name: string;
          profile_image_url: string;
        };
        data: ShoplistProduct;
      }[]
    >([]);

    const showAddProduct = () => {
      Logic.Common.showModal({
        show: true,
        type: "product_types",
        title: "Add Product(s)",
        action: () => {
          //
        },
      });
    };

    const fetchProducts = async (useLoader = false) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      if (
        selectedSwitchProducts.value == "published_products" ||
        selectedSwitchProducts.value == "unpublished_products"
      ) {
        if (useLoader) {
          isLoading.value = true;
        }

        let status =
          selectedSwitchProducts.value == "published_products"
            ? "active"
            : "draft";

        await Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                      AND: {
                        column: STATUS
                        operator: EQ
                        value: "${status}"
                      }
                    }`,
          false
        );

        isLoading.value = false;
      } else {
        if (useLoader) {
          isLoading.value = true;
        }

        await Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: NEQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                      AND: {
                        column: STATUS
                        operator: EQ
                        value: "active"
                      }
                    }`,
          false,
          "true"
        );

        isLoading.value = false;
      }
    };

    const setProducts = async () => {
      products.length = 0;

      const setProduct = (product: ShoplistProduct) => {
        const mediaBox = getMediaBoxForProduct(product);
        return {
          base_image_url: product.primary_image_url,
          title: product.name,
          price: parseFloat(product.regular_price),
          available_stock: product.stock_quantity,
          cashback: mediaBox?.reward_percentage?.toString() || "",
          merchant: {
            name: product.business?.business_name || "",
            profile_image_url: product.business?.photo_url || "",
          },
          resale_commission:
            mediaBox?.affiliate_commission_percentage?.toString() || "",
          data: product,
        };
      };

      ManyShoplistProducts.value?.data?.forEach((product) => {
        products.push(setProduct(product));
      });
      paginatorInfo.value = ManyShoplistProducts.value?.paginatorInfo;
    };

    const fetchMoreProducts = (nextPage: number) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      let status =
        selectedSwitchProducts.value == "published_products"
          ? "active"
          : "draft";

      if (
        selectedSwitchProducts.value == "published_products" ||
        selectedSwitchProducts.value == "unpublished_products"
      ) {
        return Logic.Shoplist.GetShoplistProducts(
          nextPage,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                      AND: {
                        column: STATUS
                        operator: EQ
                        value: "${status}"
                      }
                    }`,
          true
        )
          .then((response) => {
            if (response) {
              const existingData = JSON.parse(
                JSON.stringify(Logic.Shoplist.ManyShoplistProducts)
              );

              if (existingData.data && response.data) {
                existingData.data = existingData.data.concat(response.data);
                existingData.paginatorInfo = response.paginatorInfo;
              }

              Logic.Shoplist.ManyShoplistProducts = existingData;

              return true;
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        return Logic.Shoplist.GetShoplistProducts(
          nextPage,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: NEQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                      AND: {
                        column: STATUS
                        operator: EQ
                        value: "active"
                      }
                    }`,
          true,
          "true"
        )
          .then((response) => {
            if (response) {
              const existingData = JSON.parse(
                JSON.stringify(Logic.Shoplist.ManyShoplistProducts)
              );

              if (existingData.data && response.data) {
                existingData.data = existingData.data.concat(response.data);
                existingData.paginatorInfo = response.paginatorInfo;
              }

              Logic.Shoplist.ManyShoplistProducts = existingData;

              return true;
            }
          })
          .catch(() => {
            return false;
          });
      }
    };

    const selectProduct = (product: ShoplistProduct) => {
      if (
        selectedSwitchProducts.value == "published_products" ||
        selectedSwitchProducts.value == "unpublished_products"
      ) {
        Logic.Shoplist.SingleShoplistProduct = product;

        Logic.Common.GoToRoute(`/products/add?uuid=${product.uuid}`);
      } else {
        Logic.Common.GoToRoute(`/products/${product.uuid}`);
      }
    };

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        fetchProducts(true);
      }, 400);
    });

    watch(selectedSwitchProducts, () => {
      fetchProducts(true);
    });

    watch(ManyShoplistProducts, () => {
      if (ManyShoplistProducts.value) {
        paginatorInfo.value = ManyShoplistProducts.value.paginatorInfo;
      }
      setProducts();
    });

    onIonViewWillEnter(() => {
      setProducts();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyShoplistProducts",
        ManyShoplistProducts
      );
      setProducts();
    });

    return {
      Logic,
      selectedSwitchProducts,
      productSwitch,
      searchQuery,
      products,
      isLoading,
      paginatorInfo,
      productVirtualScrollerRef,
      showAddProduct,
      selectProduct,
      fetchMoreProducts,
    };
  },
});
</script>
