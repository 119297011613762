import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_3 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_4 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "w-full flex flex-col px-4" }
const _hoisted_7 = { class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-purple-50 dark:bg-gray-900" }
const _hoisted_8 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Top up Ad Wallet' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.hideAmount)
                ? (_openBlock(), _createBlock(_component_app_text_field, {
                    key: 0,
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                parseFloat(_ctx.formData.amount.replace(/,/g, '')) <= 500000,
                'Topup cannot be more than 500,000'
              ),
            ],
                    ref: "amount",
                    name: "Amount",
                    modelValue: _ctx.formData.amount,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.amount) = $event))
                  }, {
                    title: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-400 !font-semibold" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" NGN ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rules", "modelValue"]))
                : (_openBlock(), _createBlock(_component_app_text_field, {
                    key: 1,
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                parseFloat(_ctx.formData.amount.replace(/,/g, '')) <= 500000,
                'Topup cannot be more than 500,000'
              ),
            ],
                    ref: "amount",
                    name: "Amount",
                    modelValue: _ctx.formData.amount,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.amount) = $event))
                  }, {
                    title: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-400 !font-semibold" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" NGN ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rules", "modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commonOptions, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "px-4 py-1 rounded-[20px] bg-grey-50 dark:bg-gray-800 flex items-center justify-center cursor-pointer",
                    key: index,
                    onClick: ($event: any) => (_ctx.setAmount(item.toString()))
                  }, [
                    _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item, false, "ngn")), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_5))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_normal_text, { class: "!flex !flex-row !text-center !space-x-2" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("span", { class: "text-left !text-gray-700 dark:!text-gray-400" }, [
                      _createElementVNode("span", { class: "!font-extrabold !text-black dark:!text-white" }, "Ad Wallet"),
                      _createTextVNode(" funds are non-refundable pre-payments. Only top up with the amount you intend to use for advertising. ")
                    ], -1)
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createVNode(_component_fixed_container, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "text-color": 'text-white',
                  class: "w-full",
                  onClick: _ctx.continueWithPayment
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}